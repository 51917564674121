import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MinorUnitsToCurrencyPipe } from 'app/core/pipes/minor-units-to-currency.pipe';
import { TilledHeadingH4Component } from 'app/shared/tilled-text/tilled-heading/tilled-heading-h4.component';
import { CardChargeFeeTemplate, PricingTemplate } from '../../../../../projects/tilled-api-client/src';
import { TilledParagraphP3Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';

@Component({
  selector: 'debit-pricing',
  templateUrl: './debit-pricing-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TilledParagraphP3Component,
    MatDividerModule,
    MinorUnitsToCurrencyPipe,
    CommonModule,
    TilledHeadingH4Component,
  ],
})
export class DebitPricingCardComponent implements OnInit {
  @Input() showCard?: boolean = true;
  @Input() label?: string = 'PRICING DETAILS';
  @Input() currency?: PricingTemplate.CurrencyEnum = PricingTemplate.CurrencyEnum.USD;
  @Input() feeType?: CardChargeFeeTemplate.TransactionFeeTypeEnum;
  @Input() transactionFee?: number = 0;
  @Input() returnFee?: number = 0;
  @Input() accountMonthlyFee?: number = 0;
  @Input() accountMonthlyMinimumFee?: number = 0;

  constructor() {}

  ngOnInit() {}
}
