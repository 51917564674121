/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CharityDocumentData { 
    /**
     * The ID of the document requested for the 501c3 documentation.
     */
    document_id: string;
    status: CharityDocumentData.StatusEnum;
}
export namespace CharityDocumentData {
    export type StatusEnum = 'submitted' | 'verified';
    export const StatusEnum = {
        SUBMITTED: 'submitted' as StatusEnum,
        VERIFIED: 'verified' as StatusEnum
    };
}


