<div
  class="flex flex-col sm:ml-5"
  [ngClass]="{
    'px-8': windowWidth < 1280,
    '-mr-8': !bypassHalfWidth && windowWidth < 1280,
    'p-6': forConsole,
    'wl-app-card': (isWhiteLabel$ | async)
  }"
>
  <div class="grid grid-cols-1 justify-items-start">
    <div
      class="card-header"
      [ngClass]="{
        'w-1/2': !bypassHalfWidth
      }"
    >
      <tilled-form-heading class="card-title py-2">{{ title }}</tilled-form-heading>
      <tilled-paragraph-p3 class="card-description" *ngIf="description" [secondary]="true">{{
        description
      }}</tilled-paragraph-p3>
    </div>
    <div class="flex w-full flex-col items-start py-6">
      <ng-content></ng-content>
    </div>
    <div
      [attr.style]="
        'width: ' +
        (!continueButtonText.includes('Application')
          ? tilledInputWidth
          : title === 'Welcome'
            ? getElementWidth('welcome-merchant-step [data-test-id=\'welcome-step\']')
            : '100%')
      "
      class="btnGroup flex flex-row space-x-4 pb-5"
      [ngClass]="{
        'w-full': !tilledInputWidth
      }"
    >
      <div *ngIf="backButton" class="flex-1">
        <tilled-button
          class="backButton"
          [styles]="'flex-1 w-full'"
          data-test-id="merchant-app-backBtn"
          bgColor="tilled-neutral-200"
          [whiteText]="false"
          [secondary]="true"
          [rounded]="true"
          buttonText="Back"
          (buttonClick)="onButtonClick('back')"
        ></tilled-button>
      </div>
      <div *ngIf="continueButton" class="flex-1">
        <tilled-button
          [styles]="'flex-1 w-full'"
          class="continueButton"
          data-test-id="merchant-app-saveBtn"
          [bgColor]="!(isWhiteLabel$ | async) ? 'tilled-primary' : 'black'"
          [rounded]="true"
          [disabled]="disableContinueButton"
          [buttonText]="continueButtonText"
          [forceWhite]="true"
          (buttonClick)="onButtonClick('continue')"
          [suffixIcon]="continueButtonIcon"
          [ignoreTextCasing]="true"
        ></tilled-button>
      </div>
    </div>
  </div>
</div>
