/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ModelFile { 
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * Time at which the file should no longer be accessible.
     */
    expires_at?: string;
    /**
     * The filename.
     */
    filename: string;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * The file purpose.
     */
    purpose: ModelFile.PurposeEnum;
    /**
     * The file size.
     */
    size: number;
    /**
     * The user friendly title.
     */
    title?: string;
    /**
     * The file type.
     */
    type: ModelFile.TypeEnum;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
    /**
     * The URL for the file.
     */
    url?: string;
}
export namespace ModelFile {
    export type PurposeEnum = 'logo' | 'icon' | 'terms' | 'report' | 'dispute_evidence' | 'onboarding_documentation' | 'isv_cover_sheet';
    export const PurposeEnum = {
        LOGO: 'logo' as PurposeEnum,
        ICON: 'icon' as PurposeEnum,
        TERMS: 'terms' as PurposeEnum,
        REPORT: 'report' as PurposeEnum,
        DISPUTE_EVIDENCE: 'dispute_evidence' as PurposeEnum,
        ONBOARDING_DOCUMENTATION: 'onboarding_documentation' as PurposeEnum,
        ISV_COVER_SHEET: 'isv_cover_sheet' as PurposeEnum
    };
    export type TypeEnum = 'png' | 'jpg' | 'pdf' | 'csv' | 'tiff' | 'ico';
    export const TypeEnum = {
        PNG: 'png' as TypeEnum,
        JPG: 'jpg' as TypeEnum,
        PDF: 'pdf' as TypeEnum,
        CSV: 'csv' as TypeEnum,
        TIFF: 'tiff' as TypeEnum,
        ICO: 'ico' as TypeEnum
    };
}


