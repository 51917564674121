/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OnboardingAddress } from './onboardingAddress';


export interface PrincipalCreateParams { 
    /**
     * Registered *residential* address for this person
     */
    address: OnboardingAddress;
    /**
     * This person\'s date of birth. Format must be ISO8601 (e.g. \'2000-01-25\'; format \'YYYY-MM-DD\'). Currently the person must be older than 18 years of age.
     */
    date_of_birth: string;
    /**
     * This person\'s email address
     */
    email?: string;
    /**
     * First name of the principal.
     */
    first_name: string;
    /**
     * The unique identifier for this principal. It is not required upon initial submission. The `id_number` property is redacted in any responses, so if the principal needs to be updated then provide this identifier that was generated during the initial request.
     */
    id?: string;
    /**
     * Social Security Number (US) or Social Insurance Number (CA). Optional when business structure is `government`. Expected format: 9-digit string of numbers (e.g. \"111444777\"). Optional in CA.
     */
    id_number?: string;
    /**
     * Indicates whether this person is the applicant for the merchant account. For US merchant accounts (`region = US`) it is required that _exactly_ one of the principals is specified as the applicant.
     */
    is_applicant: boolean;
    /**
     * This indicates whether the person is the Control Prong. Control Prong is an individual with significant responsibility for managing the legal entity customer (e.g., a CEO, CFO, COO, Managing Member, General Partner, President, Vice-President, or Treasurer). For US merchant accounts (`region = US`) it is required that _exactly_ one of the principals is specified as the Control Prong.
     */
    is_control_prong?: boolean;
    /**
     * One principal must sign for all ownership types except government and publicly traded corporation.
     */
    is_signatory: boolean;
    /**
     * Job title of the person
     */
    job_title: PrincipalCreateParams.JobTitleEnum;
    /**
     * This person\'s last name.
     */
    last_name: string;
    /**
     * This person\'s middle name
     */
    middle_name?: string;
    /**
     * Percentage of ownership of the company.
     */
    percent_ownership: number;
    /**
     * This person\'s phone number
     */
    phone: string;
    /**
     * Previous registered *residential* address for this principal. Require for Canadian principals and have lived at their current address for fewer than 3 years.
     */
    previous_address?: OnboardingAddress;
    /**
     * Years at given address. Required for CA merchant accounts (`region = CA`).
     */
    years_at_address?: number;
    /**
     * Years at given previous address. Required for CA merchant accounts (`region = CA`).
     */
    years_at_previous_address?: number;
}
export namespace PrincipalCreateParams {
    export type JobTitleEnum = 'chief_executive_officer' | 'co_owner' | 'controller' | 'director' | 'financial_officer' | 'general_manager' | 'general_partner' | 'limited_partner' | 'manager' | 'member' | 'owner' | 'president' | 'secretary' | 'treasurer' | 'senior_vice_president' | 'vice_president';
    export const JobTitleEnum = {
        CHIEF_EXECUTIVE_OFFICER: 'chief_executive_officer' as JobTitleEnum,
        CO_OWNER: 'co_owner' as JobTitleEnum,
        CONTROLLER: 'controller' as JobTitleEnum,
        DIRECTOR: 'director' as JobTitleEnum,
        FINANCIAL_OFFICER: 'financial_officer' as JobTitleEnum,
        GENERAL_MANAGER: 'general_manager' as JobTitleEnum,
        GENERAL_PARTNER: 'general_partner' as JobTitleEnum,
        LIMITED_PARTNER: 'limited_partner' as JobTitleEnum,
        MANAGER: 'manager' as JobTitleEnum,
        MEMBER: 'member' as JobTitleEnum,
        OWNER: 'owner' as JobTitleEnum,
        PRESIDENT: 'president' as JobTitleEnum,
        SECRETARY: 'secretary' as JobTitleEnum,
        TREASURER: 'treasurer' as JobTitleEnum,
        SENIOR_VICE_PRESIDENT: 'senior_vice_president' as JobTitleEnum,
        VICE_PRESIDENT: 'vice_president' as JobTitleEnum
    };
}


