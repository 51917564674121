import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { PartnerAppService } from 'app/core/services/partner-app.service';
import { environment } from 'environments/environment';
import { Observable, map, take } from 'rxjs';
import {
  AccountCapability,
  AccountDocument,
  AccountInternalMetadata,
  PartnerApplicationResponse,
} from '../../../../../projects/tilled-api-client/src';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class ApplicationStatusGuard {
  private partnerApp: Observable<PartnerApplicationResponse>;
  constructor(
    private authService: AuthService,
    private router: Router,
    private partnerAppService: PartnerAppService,
  ) {}

  public canActivatePartner() {
    // We currently only want to restrict partner access in production and staging for testing.
    // Sandbox should remain unrestricted.
    if (!environment.production && environment.env !== 'staging') {
      return true;
    }

    const account = this.authService.currentAccount;
    // If an existing partner does not have internal metadata set, we want proceed as before.
    if (!account.internal_metadata?.status) {
      return true;
    }

    if (
      account.internal_metadata.status === AccountInternalMetadata.StatusEnum.CERTIFIED ||
      account.internal_metadata.status === AccountInternalMetadata.StatusEnum.LIVE ||
      account.internal_metadata.status === AccountInternalMetadata.StatusEnum.ACTIVE
    ) {
      return true;
    } else {
      this.partnerAppService
        .loadApplication()
        .pipe(take(1))
        .subscribe((application) => {
          if (
            application.status === PartnerApplicationResponse.StatusEnum.NOT_STARTED ||
            application.status === PartnerApplicationResponse.StatusEnum.STARTED ||
            application.status === PartnerApplicationResponse.StatusEnum.SENT_TO_PARTNER
          ) {
            this.router.navigate(['/enroll']);
          } else {
            this.router.navigate(['/enroll/success']);
          }
        });
      return false;
    }
  }

  public canActivateMerchant(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const account = this.authService.currentAccount;
    if (
      account.capabilities.find((cap) =>
        [AccountCapability.StatusEnum.ACTIVE, AccountCapability.StatusEnum.DISABLED].includes(cap.status),
      )
    ) {
      return true;
    } else if (
      account.capabilities.find((cap) =>
        [AccountCapability.StatusEnum.SUBMITTED, AccountCapability.StatusEnum.IN_REVIEW].includes(cap.status),
      ) &&
      account.document_requests?.length > 0 &&
      account.document_requests.find((doc) => doc.status === AccountDocument.StatusEnum.REQUESTED)
    ) {
      this.router.navigate(['/onboarding/documents']);
    } else if (
      account.capabilities.every((cap) =>
        [
          AccountCapability.StatusEnum.SUBMITTED,
          AccountCapability.StatusEnum.REJECTED,
          AccountCapability.StatusEnum.WITHDRAWN,
          AccountCapability.StatusEnum.IN_REVIEW,
        ].includes(cap.status),
      )
    ) {
      // redirect to submitted page.
      const queryParams = route.queryParams;
      this.router.navigate(['/onboarding/submitted'], { queryParams });
    } else {
      // Authorized user that has not yet completed a merchant application
      // Redirect to merch app.
      this.router.navigate(['/onboarding/application']);
    }

    return false;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.account$.pipe(
      map(() => {
        if (!this.authService.isMerchantUser()) {
          return this.canActivatePartner();
        } else {
          return this.canActivateMerchant(route, state);
        }
      }),
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.authService.account$.pipe(
      map(() => {
        if (!this.authService.isMerchantUser()) {
          return this.canActivatePartner();
        } else {
          return this.canActivateMerchant(route, state);
        }
      }),
    );
  }
}
