/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface DocumentDto { 
    /**
     * The ID of the account owning the document.
     */
    account_id: string;
    /**
     * Time at which the object was created.
     */
    created_at: string;
    /**
     * The file ID of an uploaded [File](#tag/Files) for the document, of type `onboarding_documentation`
     * @deprecated
     */
    file_id?: string;
    /**
     * The list of file IDs of uploaded [File(s)](#tag/Files) for the document, of type `onboarding_documentation`
     */
    file_ids?: Array<string>;
    /**
     * Unique identifier for the object.
     */
    id: string;
    /**
     * Set of [key-value pairs](#section/Metadata) that you can attach to an object. This can be useful for storing additional information about the object in a structured format. Individual keys can be unset by posting an empty value (i.e. `\'\'` or `null`) to them. All keys can be unset by posting an empty value (i.e. `{}` or `null`) to `metadata`.
     */
    metadata?: { [key: string]: string; };
    /**
     * The reason for rejection of the document, if applicable
     */
    rejected_reason?: DocumentDto.RejectedReasonEnum;
    /**
     * Additional notes for the request of a document, offering extra context
     */
    request_notes?: string;
    /**
     * Time at which the document request needs to be responded to.
     */
    respond_by: string;
    /**
     * The status of the document
     */
    status: DocumentDto.StatusEnum;
    /**
     * Time at which the object was submitted.
     */
    submitted_at: string;
    /**
     * The subtype of the document
     */
    subtype: DocumentDto.SubtypeEnum;
    /**
     * The type of the document
     */
    type: DocumentDto.TypeEnum;
    /**
     * Time at which the object was last updated.
     */
    updated_at: string;
    /**
     * Written response to a document that has been requested, alternative to a [File](#tag/Files)
     */
    written_response?: string;
}
export namespace DocumentDto {
    export type RejectedReasonEnum = 'blurry' | 'corrupt' | 'invalid' | 'scanned' | 'incomplete' | 'damaged' | 'not_needed';
    export const RejectedReasonEnum = {
        BLURRY: 'blurry' as RejectedReasonEnum,
        CORRUPT: 'corrupt' as RejectedReasonEnum,
        INVALID: 'invalid' as RejectedReasonEnum,
        SCANNED: 'scanned' as RejectedReasonEnum,
        INCOMPLETE: 'incomplete' as RejectedReasonEnum,
        DAMAGED: 'damaged' as RejectedReasonEnum,
        NOT_NEEDED: 'not_needed' as RejectedReasonEnum
    };
    export type StatusEnum = 'requested' | 'submitted' | 'rejected' | 'verified';
    export const StatusEnum = {
        REQUESTED: 'requested' as StatusEnum,
        SUBMITTED: 'submitted' as StatusEnum,
        REJECTED: 'rejected' as StatusEnum,
        VERIFIED: 'verified' as StatusEnum
    };
    export type SubtypeEnum = 'bank_letter_voided_check' | 'ein_letter' | 'articles_of_incorp' | 'business_license' | 'utility_bill' | 'processing_statement' | 'financial_statement' | '501c3' | 'tax_return' | 'invoice' | 'dl_passport' | 'dl_back' | 'bank_statement' | 'signed_merchant_agreement' | 'isv_cover_sheet' | 'business_model' | 'pricing_model' | 'website' | 'shopping_cart' | 'other';
    export const SubtypeEnum = {
        BANK_LETTER_VOIDED_CHECK: 'bank_letter_voided_check' as SubtypeEnum,
        EIN_LETTER: 'ein_letter' as SubtypeEnum,
        ARTICLES_OF_INCORP: 'articles_of_incorp' as SubtypeEnum,
        BUSINESS_LICENSE: 'business_license' as SubtypeEnum,
        UTILITY_BILL: 'utility_bill' as SubtypeEnum,
        PROCESSING_STATEMENT: 'processing_statement' as SubtypeEnum,
        FINANCIAL_STATEMENT: 'financial_statement' as SubtypeEnum,
        _501C3: '501c3' as SubtypeEnum,
        TAX_RETURN: 'tax_return' as SubtypeEnum,
        INVOICE: 'invoice' as SubtypeEnum,
        DL_PASSPORT: 'dl_passport' as SubtypeEnum,
        DL_BACK: 'dl_back' as SubtypeEnum,
        BANK_STATEMENT: 'bank_statement' as SubtypeEnum,
        SIGNED_MERCHANT_AGREEMENT: 'signed_merchant_agreement' as SubtypeEnum,
        ISV_COVER_SHEET: 'isv_cover_sheet' as SubtypeEnum,
        BUSINESS_MODEL: 'business_model' as SubtypeEnum,
        PRICING_MODEL: 'pricing_model' as SubtypeEnum,
        WEBSITE: 'website' as SubtypeEnum,
        SHOPPING_CART: 'shopping_cart' as SubtypeEnum,
        OTHER: 'other' as SubtypeEnum
    };
    export type TypeEnum = 'file' | 'written';
    export const TypeEnum = {
        FILE: 'file' as TypeEnum,
        WRITTEN: 'written' as TypeEnum
    };
}


