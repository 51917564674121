import { BusinessLegalEntity } from '../../../../projects/tilled-api-client/src/model/businessLegalEntity';
import { OnboardingLegalEntity } from '../../../../projects/tilled-api-client/src/model/onboardingLegalEntity';
import { PrincipalCreateParams } from '../../../../projects/tilled-api-client/src/model/principalCreateParams';

export class SelectorTypes {
  static mccCodesMap = new Map<string, string>([
    ['0742 - Veterinary', 'VET'],
    ['0780 - Landscaping', 'LANDSCAPING'],
    ['1799 - Trade Contractor', 'CONTRACTOR'],
    ['4121 - Taxi/Limo', 'TAXI'],
    ['4816 - Web Hosting', 'WEB_HOSTING'],
    ['4900 - Utilities', 'UTILITY'],
    ['5044 - Photo/FILM', 'PHOTO_FILM'],
    ['5399 - Misc General Merchandise', 'MISC_MERCH'],
    ['5411 - Food/Grocery', 'FOOD'],
    ['5499 - Misc Food Stores', 'MISC_FOOD_STORES'],
    ['5811 - Catering', 'CATERING'],
    ['5812 - Restaurant/Bar', 'RESTO'],
    ['5965 - Direct Marketing Retail (MOTO)', 'RETAIL'],
    ['5970 - Art/Photo/Film', 'ART'],
    ['6513 - Real Estate', 'REAL_ESTATE'],
    ['7230 - Barber & Beauty Shop', 'BEAUTY'],
    ['7299 - Services', 'MISC_SERV'],
    ['7349 - Cleaning Services', 'CLEANING'],
    ['7372 - Web Development/Design', 'WEB_DEV'],
    ['7379 - Computer Services', 'PC'],
    ['7392 - Consultant', 'CONSULTANT'],
    ['7699 - Repair Services', 'REPAIR'],
    ['7922 - Music/Entertainment', 'MUSIC'],
    ['7999 - Recreation Services', 'RECREATION'],
    ['8021 - Dentistry', 'DENTIST'],
    ['8050 - Medical Practitioner', 'MEDICAL_PRACT'],
    ['8099 - Health Services', 'MEDICAL_SERV'],
    ['8111 - Legal Services', 'LEGAL'],
    ['8398 - Charity', 'CHARITY'],
    ['8699 - Membership Org.', 'MEMBERSHIP'],
    ['8931 - Accounting', 'ACCT'],
    ['8999 - Professional Services', 'PROF_SERV'],
    ['Other', 'OTHER'],
  ]);

  static statesMap = new Map<string, string>([
    ['AL', 'Alabama'],
    ['AK', 'Alaska'],
    ['AS', 'American Samoa'],
    ['AZ', 'Arizona'],
    ['AR', 'Arkansas'],
    ['CA', 'California'],
    ['CO', 'Colorado'],
    ['CT', 'Connecticut'],
    ['DE', 'Delaware'],
    ['DC', 'District Of Columbia'],
    ['FM', 'Federated States Of Micronesia'],
    ['FL', 'Florida'],
    ['GA', 'Georgia'],
    ['GU', 'Guam'],
    ['HI', 'Hawaii'],
    ['ID', 'Idaho'],
    ['IL', 'Illinois'],
    ['IN', 'Indiana'],
    ['IA', 'Iowa'],
    ['KS', 'Kansas'],
    ['KY', 'Kentucky'],
    ['LA', 'Louisiana'],
    ['ME', 'Maine'],
    ['MH', 'Marshall Islands'],
    ['MD', 'Maryland'],
    ['MA', 'Massachusetts'],
    ['MI', 'Michigan'],
    ['MN', 'Minnesota'],
    ['MS', 'Mississippi'],
    ['MO', 'Missouri'],
    ['MT', 'Montana'],
    ['NE', 'Nebraska'],
    ['NV', 'Nevada'],
    ['NH', 'New Hampshire'],
    ['NJ', 'New Jersey'],
    ['NM', 'New Mexico'],
    ['NY', 'New York'],
    ['NC', 'North Carolina'],
    ['ND', 'North Dakota'],
    ['MP', 'Northern Mariana Islands'],
    ['OH', 'Ohio'],
    ['OK', 'Oklahoma'],
    ['OR', 'Oregon'],
    ['PW', 'Palau'],
    ['PA', 'Pennsylvania'],
    ['PR', 'Puerto Rico'],
    ['RI', 'Rhode Island'],
    ['SC', 'South Carolina'],
    ['SD', 'South Dakota'],
    ['TN', 'Tennessee'],
    ['TX', 'Texas'],
    ['UT', 'Utah'],
    ['VT', 'Vermont'],
    ['VI', 'Virgin Islands'],
    ['VA', 'Virginia'],
    ['WA', 'Washington'],
    ['WV', 'West Virginia'],
    ['WI', 'Wisconsin'],
    ['WY', 'Wyoming'],
  ]);
  static provinceMap = new Map<string, string>([
    ['AB', 'Alberta'],
    ['BC', 'British Columbia'],
    ['MB', 'Manitoba'],
    ['NB', 'New Brunswick'],
    ['NL', 'Newfoundland and Labrador'],
    ['NS', 'Nova Scotia'],
    ['NU', 'Nunavut'],
    ['NT', 'Northwest Territories'],
    ['ON', 'Ontario'],
    ['PE', 'Prince Edward Island'],
    ['QC', 'Quebec'],
    ['SK', 'Saskatchewan'],
    ['YT', 'Yukon'],
  ]);
  static stateAndProvinceMap = new Map([...this.statesMap].concat([...this.provinceMap]));

  static CountryToCode = new Map<string, string>([
    ['United States', 'US'],
    ['Canada', 'CA'],
    ['Afghanistan', 'AF'],
    ['Albania', 'AL'],
    ['Algeria', 'DZ'],
    ['American Samoa', 'AS'],
    ['Andorra', 'AD'],
    ['Angola', 'AO'],
    ['Anguilla', 'AI'],
    ['Antarctica', 'AQ'],
    ['Antigua and Barbuda', 'AG'],
    ['Argentina', 'AR'],
    ['Armenia', 'AM'],
    ['Aruba', 'AW'],
    ['Australia', 'AU'],
    ['Austria', 'AT'],
    ['Azerbaijan', 'AZ'],
    ['Åland Islands', 'AX'],
    ['Bahamas', 'BS'],
    ['Bahrain', 'BH'],
    ['Bangladesh', 'BD'],
    ['Barbados', 'BB'],
    ['Belarus', 'BY'],
    ['Belgium', 'BE'],
    ['Belize', 'BZ'],
    ['Benin', 'BJ'],
    ['Bermuda', 'BM'],
    ['Bhutan', 'BT'],
    ['Bolivia, Plurinational State of', 'BO'],
    ['Bonaire, Sint Eustatius and Saba', 'BQ'],
    ['Bosnia and Herzegovina', 'BA'],
    ['Botswana', 'BW'],
    ['Bouvet Island', 'BV'],
    ['Brazil', 'BR'],
    ['British Indian Ocean Territory', 'IO'],
    ['Brunei Darussalam', 'BN'],
    ['Bulgaria', 'BG'],
    ['Burkina Faso', 'BF'],
    ['Burundi', 'BI'],
    ['Cambodia', 'KH'],
    ['Cameroon', 'CM'],
    ['Cape Verde', 'CV'],
    ['Cayman Islands', 'KY'],
    ['Central African Republic', 'CF'],
    ['Chad', 'TD'],
    ['Chile', 'CL'],
    ['China', 'CN'],
    ['Christmas Island', 'CX'],
    ['Cocos (Keeling) Islands', 'CC'],
    ['Colombia', 'CO'],
    ['Comoros', 'KM'],
    ['Congo', 'CG'],
    ['Congo, the Democratic Republic of the', 'CD'],
    ['Cook Islands', 'CK'],
    ['Costa Rica', 'CR'],
    ['Croatia', 'HR'],
    ['Cuba', 'CU'],
    ['Curaçao', 'CW'],
    ['Cyprus', 'CY'],
    ['Czech Republic', 'CZ'],
    ["Côte d'Ivoire", 'CI'],
    ['Denmark', 'DK'],
    ['Djibouti', 'DJ'],
    ['Dominica', 'DM'],
    ['Dominican Republic', 'DO'],
    ['Ecuador', 'EC'],
    ['Egypt', 'EG'],
    ['El Salvador', 'SV'],
    ['Equatorial Guinea', 'GQ'],
    ['Eritrea', 'ER'],
    ['Estonia', 'EE'],
    ['Ethiopia', 'ET'],
    ['Falkland Islands (Malvinas)', 'FK'],
    ['Faroe Islands', 'FO'],
    ['Fiji', 'FJ'],
    ['Finland', 'FI'],
    ['France', 'FR'],
    ['French Guiana', 'GF'],
    ['French Polynesia', 'PF'],
    ['French Southern Territories', 'TF'],
    ['Gabon', 'GA'],
    ['Gambia', 'GM'],
    ['Georgia', 'GE'],
    ['Germany', 'DE'],
    ['Ghana', 'GH'],
    ['Gibraltar', 'GI'],
    ['Greece', 'GR'],
    ['Greenland', 'GL'],
    ['Grenada', 'GD'],
    ['Guadeloupe', 'GP'],
    ['Guam', 'GU'],
    ['Guatemala', 'GT'],
    ['Guernsey', 'GG'],
    ['Guinea', 'GN'],
    ['Guinea-Bissau', 'GW'],
    ['Guyana', 'GY'],
    ['Haiti', 'HT'],
    ['Heard Island and McDonald Islands', 'HM'],
    ['Holy See (Vatican City State)', 'VA'],
    ['Honduras', 'HN'],
    ['Hong Kong', 'HK'],
    ['Hungary', 'HU'],
    ['Iceland', 'IS'],
    ['India', 'IN'],
    ['Indonesia', 'ID'],
    ['Iran, Islamic Republic of', 'IR'],
    ['Iraq', 'IQ'],
    ['Ireland', 'IE'],
    ['Isle of Man', 'IM'],
    ['Israel', 'IL'],
    ['Italy', 'IT'],
    ['Jamaica', 'JM'],
    ['Japan', 'JP'],
    ['Jersey', 'JE'],
    ['Jordan', 'JO'],
    ['Kazakhstan', 'KZ'],
    ['Kenya', 'KE'],
    ['Kiribati', 'KI'],
    ["Korea, Democratic People's Republic of", 'KP'],
    ['Korea, Republic of', 'KR'],
    ['Kuwait', 'KW'],
    ['Kyrgyzstan', 'KG'],
    ["Lao People's Democratic Republic", 'LA'],
    ['Latvia', 'LV'],
    ['Lebanon', 'LB'],
    ['Lesotho', 'LS'],
    ['Liberia', 'LR'],
    ['Libya', 'LY'],
    ['Liechtenstein', 'LI'],
    ['Lithuania', 'LT'],
    ['Luxembourg', 'LU'],
    ['Macao', 'MO'],
    ['Macedonia, the former Yugoslav Republic of', 'MK'],
    ['Madagascar', 'MG'],
    ['Malawi', 'MW'],
    ['Malaysia', 'MY'],
    ['Maldives', 'MV'],
    ['Mali', 'ML'],
    ['Malta', 'MT'],
    ['Marshall Islands', 'MH'],
    ['Martinique', 'MQ'],
    ['Mauritania', 'MR'],
    ['Mauritius', 'MU'],
    ['Mayotte', 'YT'],
    ['Mexico', 'MX'],
    ['Micronesia, Federated States of', 'FM'],
    ['Moldova, Republic of', 'MD'],
    ['Monaco', 'MC'],
    ['Mongolia', 'MN'],
    ['Montenegro', 'ME'],
    ['Montserrat', 'MS'],
    ['Morocco', 'MA'],
    ['Mozambique', 'MZ'],
    ['Myanmar', 'MM'],
    ['Namibia', 'NA'],
    ['Nauru', 'NR'],
    ['Nepal', 'NP'],
    ['Netherlands', 'NL'],
    ['New Caledonia', 'NC'],
    ['New Zealand', 'NZ'],
    ['Nicaragua', 'NI'],
    ['Niger', 'NE'],
    ['Nigeria', 'NG'],
    ['Niue', 'NU'],
    ['Norfolk Island', 'NF'],
    ['Northern Mariana Islands', 'MP'],
    ['Norway', 'NO'],
    ['Oman', 'OM'],
    ['Pakistan', 'PK'],
    ['Palau', 'PW'],
    ['Palestine, State of', 'PS'],
    ['Panama', 'PA'],
    ['Papua New Guinea', 'PG'],
    ['Paraguay', 'PY'],
    ['Peru', 'PE'],
    ['Philippines', 'PH'],
    ['Pitcairn', 'PN'],
    ['Poland', 'PL'],
    ['Portugal', 'PT'],
    ['Puerto Rico', 'PR'],
    ['Qatar', 'QA'],
    ['Romania', 'RO'],
    ['Russian Federation', 'RU'],
    ['Rwanda', 'RW'],
    ['Réunion', 'RE'],
    ['Saint Barthélemy', 'BL'],
    ['Saint Helena, Ascension and Tristan da Cunha', 'SH'],
    ['Saint Kitts and Nevis', 'KN'],
    ['Saint Lucia', 'LC'],
    ['Saint Martin (French part)', 'MF'],
    ['Saint Pierre and Miquelon', 'PM'],
    ['Saint Vincent and the Grenadines', 'VC'],
    ['Samoa', 'WS'],
    ['San Marino', 'SM'],
    ['Sao Tome and Principe', 'ST'],
    ['Saudi Arabia', 'SA'],
    ['Senegal', 'SN'],
    ['Serbia', 'RS'],
    ['Seychelles', 'SC'],
    ['Sierra Leone', 'SL'],
    ['Singapore', 'SG'],
    ['Sint Maarten (Dutch part)', 'SX'],
    ['Slovakia', 'SK'],
    ['Slovenia', 'SI'],
    ['Solomon Islands', 'SB'],
    ['Somalia', 'SO'],
    ['South Africa', 'ZA'],
    ['South Georgia and the South Sandwich Islands', 'GS'],
    ['South Sudan', 'SS'],
    ['Spain', 'ES'],
    ['Sri Lanka', 'LK'],
    ['Sudan', 'SD'],
    ['Suriname', 'SR'],
    ['Svalbard and Jan Mayen', 'SJ'],
    ['Swaziland', 'SZ'],
    ['Sweden', 'SE'],
    ['Switzerland', 'CH'],
    ['Syrian Arab Republic', 'SY'],
    ['Taiwan, Province of China', 'TW'],
    ['Tajikistan', 'TJ'],
    ['Tanzania, United Republic of', 'TZ'],
    ['Thailand', 'TH'],
    ['Timor-Leste', 'TL'],
    ['Togo', 'TG'],
    ['Tokelau', 'TK'],
    ['Tonga', 'TO'],
    ['Trinidad and Tobago', 'TT'],
    ['Tunisia', 'TN'],
    ['Turkey', 'TR'],
    ['Turkmenistan', 'TM'],
    ['Turks and Caicos Islands', 'TC'],
    ['Tuvalu', 'TV'],
    ['Uganda', 'UG'],
    ['Ukraine', 'UA'],
    ['United Arab Emirates', 'AE'],
    ['United Kingdom', 'GB'],
    ['United States Minor Outlying Islands', 'UM'],
    ['Uruguay', 'UY'],
    ['Uzbekistan', 'UZ'],
    ['Vanuatu', 'VU'],
    ['Venezuela, Bolivarian Republic of', 'VE'],
    ['Viet Nam', 'VN'],
    ['Virgin Islands, British', 'VG'],
    ['Virgin Islands, U.S.', 'VI'],
    ['Wallis and Futuna', 'WF'],
    ['Western Sahara', 'EH'],
    ['Yemen', 'YE'],
    ['Zambia', 'ZM'],
    ['Zimbabwe', 'ZW'],
  ]);

  static CodeToCountry = new Map<string, string>(
    Array.from(SelectorTypes.CountryToCode.entries()).map(([key, value]) => [value, key]),
  );
  // Only allow US and Canada for now
  static countryPhoneCodes = new Array<{ label: string; value: string }>(
    { label: 'US', value: '+1 ' },
    { label: 'CA', value: '+1 ' },
    // { label: 'AD', value: '+376 ' },
    // { label: 'AE', value: '+971 ' },
    // { label: 'AF', value: '+93 ' },
    // { label: 'AG', value: '+1-268 ' },
    // { label: 'AI', value: '+1-264 ' },
    // { label: 'AL', value: '+355 ' },
    // { label: 'AM', value: '+374 ' },
    // { label: 'AO', value: '+244 ' },
    // { label: 'AR', value: '+54 ' },
    // { label: 'AS', value: '+1-684 ' },
    // { label: 'AT', value: '+43 ' },
    // { label: 'AU', value: '+61 ' },
    // { label: 'AW', value: '+297 ' },
    // { label: 'AX', value: '+358-18 ' },
    // { label: 'AZ', value: '+994 ' },
    // { label: 'BA', value: '+387 ' },
    // { label: 'BB', value: '+1-246 ' },
    // { label: 'BD', value: '+880 ' },
    // { label: 'BE', value: '+32 ' },
    // { label: 'BF', value: '+226 ' },
    // { label: 'BG', value: '+359 ' },
    // { label: 'BH', value: '+973 ' },
    // { label: 'BI', value: '+257 ' },
    // { label: 'BJ', value: '+229 ' },
    // { label: 'BL', value: '+590 ' },
    // { label: 'BM', value: '+1-441 ' },
    // { label: 'BN', value: '+673 ' },
    // { label: 'BO', value: '+591 ' },
    // { label: 'BQ', value: '+599 ' },
    // { label: 'BR', value: '+55 ' },
    // { label: 'BS', value: '+1-242 ' },
    // { label: 'BT', value: '+975 ' },
    // { label: 'BW', value: '+267 ' },
    // { label: 'BY', value: '+375 ' },
    // { label: 'BZ', value: '+501 ' },
    // { label: 'CC', value: '+61 ' },
    // { label: 'CD', value: '+243 ' },
    // { label: 'CF', value: '+236 ' },
    // { label: 'CG', value: '+242 ' },
    // { label: 'CH', value: '+41 ' },
    // { label: 'CI', value: '+225 ' },
    // { label: 'CK', value: '+682 ' },
    // { label: 'CL', value: '+56 ' },
    // { label: 'CM', value: '+237 ' },
    // { label: 'CN', value: '+86 ' },
    // { label: 'CO', value: '+57 ' },
    // { label: 'CR', value: '+506 ' },
    // { label: 'CU', value: '+53 ' },
    // { label: 'CV', value: '+238 ' },
    // { label: 'CW', value: '+599 ' },
    // { label: 'CX', value: '+61 ' },
    // { label: 'CY', value: '+357 ' },
    // { label: 'CZ', value: '+420 ' },
    // { label: 'DE', value: '+49 ' },
    // { label: 'DJ', value: '+253 ' },
    // { label: 'DK', value: '+45 ' },
    // { label: 'DM', value: '+1-767 ' },
    // { label: 'DO', value: '+1-809 ' },
    // { label: 'DZ', value: '+213 ' },
    // { label: 'EC', value: '+593 ' },
    // { label: 'EE', value: '+372 ' },
    // { label: 'EG', value: '+20 ' },
    // { label: 'EH', value: '+212 ' },
    // { label: 'ER', value: '+291 ' },
    // { label: 'ES', value: '+34 ' },
    // { label: 'ET', value: '+251 ' },
    // { label: 'FI', value: '+358 ' },
    // { label: 'FJ', value: '+679 ' },
    // { label: 'FK', value: '+500 ' },
    // { label: 'FM', value: '+691 ' },
    // { label: 'FO', value: '+298 ' },
    // { label: 'FR', value: '+33 ' },
    // { label: 'GA', value: '+241 ' },
    // { label: 'GB', value: '+44 ' },
    // { label: 'GD', value: '+1-473 ' },
    // { label: 'GE', value: '+995 ' },
    // { label: 'GF', value: '+594 ' },
    // { label: 'GG', value: '+44-1481 ' },
    // { label: 'GH', value: '+233 ' },
    // { label: 'GI', value: '+350 ' },
    // { label: 'GL', value: '+299 ' },
    // { label: 'GM', value: '+220 ' },
    // { label: 'GN', value: '+224 ' },
    // { label: 'GP', value: '+590 ' },
    // { label: 'GQ', value: '+240 ' },
    // { label: 'GR', value: '+30 ' },
    // { label: 'GT', value: '+502 ' },
    // { label: 'GU', value: '+1-671 ' },
    // { label: 'GW', value: '+245 ' },
    // { label: 'GY', value: '+592 ' },
    // { label: 'HK', value: '+852 ' },
    // { label: 'HM', value: '+  ' },
    // { label: 'HN', value: '+504 ' },
    // { label: 'HR', value: '+385 ' },
    // { label: 'HT', value: '+509 ' },
    // { label: 'HU', value: '+36 ' },
    // { label: 'ID', value: '+62 ' },
    // { label: 'IE', value: '+353 ' },
    // { label: 'IL', value: '+972 ' },
    // { label: 'IM', value: '+44-1624 ' },
    // { label: 'IN', value: '+91 ' },
    // { label: 'IO', value: '+246 ' },
    // { label: 'IQ', value: '+964 ' },
    // { label: 'IR', value: '+98 ' },
    // { label: 'IS', value: '+354 ' },
    // { label: 'IT', value: '+39 ' },
    // { label: 'JE', value: '+44-1534 ' },
    // { label: 'JM', value: '+1-876 ' },
    // { label: 'JO', value: '+962 ' },
    // { label: 'JP', value: '+81 ' },
    // { label: 'KE', value: '+254 ' },
    // { label: 'KG', value: '+996 ' },
    // { label: 'KH', value: '+855 ' },
    // { label: 'KI', value: '+686 ' },
    // { label: 'KM', value: '+269 ' },
    // { label: 'KN', value: '+1-869 ' },
    // { label: 'KP', value: '+850 ' },
    // { label: 'KR', value: '+82 ' },
    // { label: 'KW', value: '+965 ' },
    // { label: 'KY', value: '+1-345 ' },
    // { label: 'KZ', value: '+7 ' },
    // { label: 'LA', value: '+856 ' },
    // { label: 'LB', value: '+961 ' },
    // { label: 'LC', value: '+1-758 ' },
    // { label: 'LI', value: '+423 ' },
    // { label: 'LK', value: '+94 ' },
    // { label: 'LR', value: '+231 ' },
    // { label: 'LS', value: '+266 ' },
    // { label: 'LT', value: '+370 ' },
    // { label: 'LU', value: '+352 ' },
    // { label: 'LV', value: '+371 ' },
    // { label: 'LY', value: '+218 ' },
    // { label: 'MA', value: '+212 ' },
    // { label: 'MC', value: '+377 ' },
    // { label: 'MD', value: '+373 ' },
    // { label: 'ME', value: '+382 ' },
    // { label: 'MF', value: '+590 ' },
    // { label: 'MG', value: '+261 ' },
    // { label: 'MH', value: '+692 ' },
    // { label: 'MK', value: '+389 ' },
    // { label: 'ML', value: '+223 ' },
    // { label: 'MM', value: '+95 ' },
    // { label: 'MN', value: '+976 ' },
    // { label: 'MO', value: '+853 ' },
    // { label: 'MP', value: '+1-670 ' },
    // { label: 'MQ', value: '+596 ' },
    // { label: 'MR', value: '+222 ' },
    // { label: 'MS', value: '+1-664 ' },
    // { label: 'MT', value: '+356 ' },
    // { label: 'MU', value: '+230 ' },
    // { label: 'MV', value: '+960 ' },
    // { label: 'MW', value: '+265 ' },
    // { label: 'MX', value: '+52 ' },
    // { label: 'MY', value: '+60 ' },
    // { label: 'MZ', value: '+258 ' },
    // { label: 'NA', value: '+264 ' },
    // { label: 'NC', value: '+687 ' },
    // { label: 'NE', value: '+227 ' },
    // { label: 'NF', value: '+672 ' },
    // { label: 'NG', value: '+234 ' },
    // { label: 'NI', value: '+505 ' },
    // { label: 'NL', value: '+31 ' },
    // { label: 'NO', value: '+47 ' },
    // { label: 'NP', value: '+977 ' },
    // { label: 'NR', value: '+674 ' },
    // { label: 'NU', value: '+683 ' },
    // { label: 'NZ', value: '+64 ' },
    // { label: 'OM', value: '+968 ' },
    // { label: 'PA', value: '+507 ' },
    // { label: 'PE', value: '+51 ' },
    // { label: 'PF', value: '+689 ' },
    // { label: 'PG', value: '+675 ' },
    // { label: 'PH', value: '+63 ' },
    // { label: 'PK', value: '+92 ' },
    // { label: 'PL', value: '+48 ' },
    // { label: 'PM', value: '+508 ' },
    // { label: 'PN', value: '+870 ' },
    // { label: 'PR', value: '+1-787 ' },
    // { label: 'PS', value: '+970 ' },
    // { label: 'PT', value: '+351 ' },
    // { label: 'PW', value: '+680 ' },
    // { label: 'PY', value: '+595 ' },
    // { label: 'QA', value: '+974 ' },
    // { label: 'RE', value: '+262 ' },
    // { label: 'RO', value: '+40 ' },
    // { label: 'RS', value: '+381 ' },
    // { label: 'RU', value: '+7 ' },
    // { label: 'RW', value: '+250 ' },
    // { label: 'SA', value: '+966 ' },
    // { label: 'SB', value: '+677 ' },
    // { label: 'SC', value: '+248 ' },
    // { label: 'SD', value: '+249 ' },
    // { label: 'SE', value: '+46 ' },
    // { label: 'SG', value: '+65 ' },
    // { label: 'SH', value: '+290 ' },
    // { label: 'SI', value: '+386 ' },
    // { label: 'SJ', value: '+47 ' },
    // { label: 'SK', value: '+421 ' },
    // { label: 'SL', value: '+232 ' },
    // { label: 'SM', value: '+378 ' },
    // { label: 'SN', value: '+221 ' },
    // { label: 'SO', value: '+252 ' },
    // { label: 'SR', value: '+597 ' },
    // { label: 'SS', value: '+211 ' },
    // { label: 'ST', value: '+239 ' },
    // { label: 'SV', value: '+503 ' },
    // { label: 'SX', value: '+599 ' },
    // { label: 'SY', value: '+963 ' },
    // { label: 'SZ', value: '+268 ' },
    // { label: 'TC', value: '+1-649 ' },
    // { label: 'TD', value: '+235 ' },
    // { label: 'TG', value: '+228 ' },
    // { label: 'TH', value: '+66 ' },
    // { label: 'TJ', value: '+992 ' },
    // { label: 'TK', value: '+690 ' },
    // { label: 'TL', value: '+670 ' },
    // { label: 'TM', value: '+993 ' },
    // { label: 'TN', value: '+216 ' },
    // { label: 'TO', value: '+676 ' },
    // { label: 'TR', value: '+90 ' },
    // { label: 'TT', value: '+1-868 ' },
    // { label: 'TV', value: '+688 ' },
    // { label: 'TW', value: '+886 ' },
    // { label: 'TZ', value: '+255 ' },
    // { label: 'UA', value: '+380 ' },
    // { label: 'UG', value: '+256 ' },
    // { label: 'UY', value: '+598 ' },
    // { label: 'UZ', value: '+998 ' },
    // { label: 'VA', value: '+379 ' },
    // { label: 'VC', value: '+1-784 ' },
    // { label: 'VE', value: '+58 ' },
    // { label: 'VG', value: '+1-284 ' },
    // { label: 'VI', value: '+1-340 ' },
    // { label: 'VN', value: '+84 ' },
    // { label: 'VU', value: '+678 ' },
    // { label: 'WF', value: '+681 ' },
    // { label: 'WS', value: '+685 ' },
    // { label: 'YE', value: '+967 ' },
    // { label: 'YT', value: '+262 ' },
    // { label: 'ZA', value: '+27 ' },
    // { label: 'ZM', value: '+260 ' },
    // { label: 'ZW', value: '+263 ' },
  );

  static CountryToPhoneCode = new Map<string, string>(
    Array.from(SelectorTypes.countryPhoneCodes).map(({ label, value }) => [label, value]),
  );

  static YearlyVolumeRangeTypes = [
    {
      value: BusinessLegalEntity.YearlyVolumeRangeEnum.LOW,
      desc: 'Less than 50k',
    },
    {
      value: BusinessLegalEntity.YearlyVolumeRangeEnum.MEDIUM,
      desc: '50k–100k',
    },
    {
      value: BusinessLegalEntity.YearlyVolumeRangeEnum.HIGH,
      desc: '100k–250k',
    },
    {
      value: BusinessLegalEntity.YearlyVolumeRangeEnum.VERY_HIGH,
      desc: '250k or more',
    },
  ];

  static AnnualRevenueTypes = [
    {
      value: BusinessLegalEntity.AnnualRevenueEnum.LOW,
      desc: '$0 - 100,000',
    },
    {
      value: BusinessLegalEntity.AnnualRevenueEnum.MEDIUM,
      desc: '$100,001 - 250,000',
    },
    {
      value: BusinessLegalEntity.AnnualRevenueEnum.HIGH,
      desc: '$250,001 - 400,000',
    },
    {
      value: BusinessLegalEntity.AnnualRevenueEnum.VERY_HIGH,
      desc: '$400,001 - 550,000',
    },
    {
      value: BusinessLegalEntity.AnnualRevenueEnum.EXTREMELY_HIGH,
      desc: '$550,000+',
    },
  ];

  static businessEntityTypes = [
    {
      name: OnboardingLegalEntity.StructureEnum.CHARITY,
      desc: 'Charity',
      shortDesc: BusinessLegalEntity.TypeEnum.CHARITY,
    },
    {
      name: OnboardingLegalEntity.StructureEnum.COMMUNITY_INTEREST_COMPANY,
      desc: 'Community Interest Company',
      shortDesc: BusinessLegalEntity.TypeEnum.CIC,
    },
    {
      name: OnboardingLegalEntity.StructureEnum.CORPORATION,
      desc: 'Corporation',
      shortDesc: BusinessLegalEntity.TypeEnum.CORP,
    },
    {
      name: OnboardingLegalEntity.StructureEnum.LIMITED,
      desc: 'Limited',
      shortDesc: BusinessLegalEntity.TypeEnum.LTD,
    },
    {
      name: OnboardingLegalEntity.StructureEnum.LIMITED_LIABILITY_COMPANY,
      desc: 'Limited Liability Company',
      shortDesc: BusinessLegalEntity.TypeEnum.LLC,
    },
    {
      name: OnboardingLegalEntity.StructureEnum.LIMITED_LIABILITY_PARTNERSHIP,
      desc: 'Limited Liability Partnership',
      shortDesc: BusinessLegalEntity.TypeEnum.LLP,
    },
    {
      name: OnboardingLegalEntity.StructureEnum.NON_PROFIT,
      desc: 'Non-Profit',
      shortDesc: BusinessLegalEntity.TypeEnum.NPCORP,
    },
    {
      name: OnboardingLegalEntity.StructureEnum.PARTNERSHIP,
      desc: 'Partnership',
      shortDesc: BusinessLegalEntity.TypeEnum.PARTNERSHIP,
    },
    {
      name: OnboardingLegalEntity.StructureEnum.PUBLIC_LIMITED_COMPANY,
      desc: 'Public Limited Company',
      shortDesc: BusinessLegalEntity.TypeEnum.PLC,
    },
    {
      name: OnboardingLegalEntity.StructureEnum.GOVERNMENT,
      desc: 'Public Sector/Governmental',
      shortDesc: BusinessLegalEntity.TypeEnum.GOV,
    },
    {
      name: OnboardingLegalEntity.StructureEnum.SOLE_PROPRIETORSHIP,
      desc: 'Sole Proprietorship/Sole Trader',
      shortDesc: BusinessLegalEntity.TypeEnum.SOLEPROP,
    },
    {
      name: OnboardingLegalEntity.StructureEnum.TRUST,
      desc: 'Trust',
      shortDesc: BusinessLegalEntity.TypeEnum.TRUST,
    },
  ];

  public states = [
    'Alabama',
    'Alaska',
    'Arizona',
    'Arkansas',
    'California',
    'Colorado',
    'Connecticut',
    'Delaware',
    'District Of Columbia',
    'Florida',
    'Georgia',
    'Hawaii',
    'Idaho',
    'Illinois',
    'Indiana',
    'Iowa',
    'Kansas',
    'Kentucky',
    'Louisiana',
    'Maine',
    'Maryland',
    'Massachusetts',
    'Michigan',
    'Minnesota',
    'Mississippi',
    'Missouri',
    'Montana',
    'Nebraska',
    'Nevada',
    'New Hampshire',
    'New Jersey',
    'New Mexico',
    'New York',
    'North Carolina',
    'North Dakota',
    'Ohio',
    'Oklahoma',
    'Oregon',
    'Pennsylvania',
    'Rhode Island',
    'South Carolina',
    'South Dakota',
    'Tennessee',
    'Texas',
    'Utah',
    'Vermont',
    'Virginia',
    'Washington',
    'West Virginia',
    'Wisconsin',
    'Wyoming',
    'Alberta',
    'British Columbia',
    'Manitoba',
    'New Brunswick',
    'Newfoundland',
    'Northwest Territories',
    'Nova Scotia',
    'Nunavut',
    'Ontario',
    'Prince Edward Island',
    'Quebec',
    'Saskatchewan',
    'Yukon',
  ];

  public mccCodes = [
    '0742 - Veterinary',
    '0780 - Landscaping',
    '1799 - Trade Contractor',
    '4121 - Taxi/Limo',
    '4816 - Web Hosting',
    '4900 - Utilities',
    '5044 - Photo/FILM',
    '5399 - Misc General Merchandise',
    '5411 - Food/Grocery',
    '5499 - Misc Food Stores',
    '5811 - Catering',
    '5812 - Restaurant/Bar',
    '5965 - Direct Marketing Retail (MOTO)',
    '5970 - Art/Photo/Film',
    '6513 - Real Estate',
    '7230 - Barber & Beauty Shop',
    '7299 - Services',
    '7349 - Cleaning Services',
    '7372 - Web Development/Design',
    '7379 - Computer Services',
    '7392 - Consultant',
    '7699 - Repair Services',
    '7922 - Music/Entertainment',
    '7999 - Recreation Services',
    '8021 - Dentistry',
    '8050 - Medical Practitioner',
    '8099 - Health Services',
    '8111 - Legal Services',
    '8398 - Charity',
    '8699 - Membership Org.',
    '8931 - Accounting',
    '8999 - Professional Services',
    'Other',
  ];

  static getCountryFromState(twoLetterState: string): string {
    if (this.statesMap.has(twoLetterState)) {
      return 'US';
    } else if (this.provinceMap.has(twoLetterState)) {
      return 'CA';
    } else {
      return null;
    }
  }

  static jobTitleArray = [
    {
      name: PrincipalCreateParams.JobTitleEnum.CHIEF_EXECUTIVE_OFFICER,
      desc: 'CEO',
    },
    {
      name: PrincipalCreateParams.JobTitleEnum.CONTROLLER,
      desc: 'Controller',
    },
    {
      name: PrincipalCreateParams.JobTitleEnum.CO_OWNER,
      desc: 'Co-owner',
    },
    {
      name: PrincipalCreateParams.JobTitleEnum.DIRECTOR,
      desc: 'Director',
    },
    {
      name: PrincipalCreateParams.JobTitleEnum.FINANCIAL_OFFICER,
      desc: 'Financial Officer',
    },
    {
      name: PrincipalCreateParams.JobTitleEnum.GENERAL_MANAGER,
      desc: 'General Manager',
    },
    {
      name: PrincipalCreateParams.JobTitleEnum.GENERAL_PARTNER,
      desc: 'General Partner',
    },
    {
      name: PrincipalCreateParams.JobTitleEnum.LIMITED_PARTNER,
      desc: 'Limited Partner',
    },
    {
      name: PrincipalCreateParams.JobTitleEnum.MANAGER,
      desc: 'Manager',
    },
    {
      name: PrincipalCreateParams.JobTitleEnum.MEMBER,
      desc: 'Member',
    },
    {
      name: PrincipalCreateParams.JobTitleEnum.OWNER,
      desc: 'Owner',
    },
    {
      name: PrincipalCreateParams.JobTitleEnum.PRESIDENT,
      desc: 'President',
    },
    {
      name: PrincipalCreateParams.JobTitleEnum.SECRETARY,
      desc: 'Secretary',
    },
    {
      name: PrincipalCreateParams.JobTitleEnum.SENIOR_VICE_PRESIDENT,
      desc: 'Senior Vice President',
    },
    {
      name: PrincipalCreateParams.JobTitleEnum.TREASURER,
      desc: 'Treasurer',
    },
    {
      name: PrincipalCreateParams.JobTitleEnum.VICE_PRESIDENT,
      desc: 'Vice President',
    },
  ];

  static daysPriorToShipmentArray = [
    {
      name: 0,
      desc: 'Immediately / Billed in Arrears',
    },
    {
      name: 2,
      desc: '1 - 2 days',
    },
    {
      name: 30,
      desc: '3 - 30 days',
    },
    {
      name: 60,
      desc: '31 - 60 days',
    },
    {
      name: 90,
      desc: '61 - 90 days',
    },
    {
      name: 91,
      desc: '90 or more days',
    },
  ];
}
