/* eslint-disable @typescript-eslint/naming-convention */
import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { fuseAnimations } from '@fuse/animations';
import { FuseAlertService } from '@fuse/components/alert';
import {
  Charge,
  InternalCreateRefundRequestParams,
  InternalService,
  PaymentIntent,
  PaymentMethod,
  PaymentMethodCreateParams,
} from '@tilled-api-client';
import { ComponentBase } from 'app/core/componentBase';
import { MinorUnitsToCurrencyPipe } from 'app/core/pipes/minor-units-to-currency.pipe';
import { NumericToMinorUnitsPipe } from 'app/core/pipes/numeric-to-minor-units.pipe';
import { AuthService } from 'app/core/services/auth.service';
import { BrandingService } from 'app/core/services/branding.service';
import { RefundsAppService } from 'app/core/services/refunds.app.service';
import { FormCardComponent } from 'app/shared/cards/form-cards/form-card.component';
import { TilledSelectComponent } from 'app/shared/tilled-select/tilled-select.component';
import { Observable, Subject, takeUntil } from 'rxjs';
import { FuseAlertComponent } from '../../../../@fuse/components/alert/alert.component';
import { environment } from '../../../../environments/environment';
import { TilledButtonComponent } from '../../../shared/buttons/tilled-button.component';
import { TilledInputComponent } from '../../../shared/form-fields/tilled-input/tilled-input.component';
import { TilledHeadingH2Component } from '../../../shared/tilled-text/tilled-heading/tilled-heading-h2.component';
import { TilledLabelL1Component } from '../../../shared/tilled-text/tilled-label/tilled-label-l1.component';
import { TilledParagraphP3Component } from '../../../shared/tilled-text/tilled-paragraph/tilled-paragraph-p3.component';
import { TilledParagraphP4Component } from '../../../shared/tilled-text/tilled-paragraph/tilled-paragraph-p4.component';

// TODO: For some reason the compiler fails even though VSCode can find it
// import { default as TilledJs, Form as TilledJsForm, Options, PaymentMethodType } from '@tilled-js';

// This declaration is required for TilledJs unless we get the above to work.
declare let Tilled: any; // from /assets/js/tilled.js
declare let Payments: any; // from /assets/js/payments.js
@Component({
  selector: 'app-refund-form-dialog',
  templateUrl: './refund-form-dialog.component.html',
  styleUrls: ['./refund-form-dialog.component.scss'],
  animations: fuseAnimations,
  standalone: true,
  imports: [
    MatIconModule,
    TilledHeadingH2Component,
    TilledParagraphP3Component,
    FormsModule,
    ReactiveFormsModule,
    TilledInputComponent,
    TilledLabelL1Component,
    TilledSelectComponent,
    MatSlideToggleModule,
    TilledParagraphP4Component,
    FuseAlertComponent,
    MatFormFieldModule,
    MatInputModule,
    TilledButtonComponent,
    MinorUnitsToCurrencyPipe,
    CommonModule,
    FormCardComponent,
  ],
})
export class RefundFormDialogComponent extends ComponentBase implements OnInit {
  public refundForm: FormGroup;
  public maxRefund: number;
  public platformFeeRefund: boolean;
  public payment: PaymentIntent;
  public successfulCharge: Charge;
  public isMerchant: boolean;

  public refundable = true;
  public requireNewPaymentMethod = false;

  public alertType: string;
  public alertTitle: string;
  public alertMessage: string;
  public alertDismissible: boolean;

  public activelyRefunding = false;
  private isWhiteLabel: boolean;

  // Start TilledJs Related
  public tilledJsCard: any; // TilledJs;
  public tilledJsCardForm: any; // TilledJsForm;
  public showCardNumberError = false;
  public showExpirationError = false;
  public showCvvError = false;
  // End TilledJs Related

  private accountId: string;

  private _displayAlert$ = new Subject<boolean>();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  public displayAlert$ = this._displayAlert$.asObservable();

  private refundError$: Observable<string>;
  private refundCreated$: Observable<boolean>;

  constructor(
    public matDialogRef: MatDialogRef<RefundFormDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    private _authService: AuthService,
    private _refundsAppService: RefundsAppService,
    private _numericToMinorPipe: NumericToMinorUnitsPipe,
    private _fuseAlertService: FuseAlertService,
    private _internalService: InternalService,
    private _brandingService: BrandingService,
  ) {
    super();
    // Determine if this is a merchant or partner logged in
    this._authService.isMerchantAccount$.pipe(takeUntil(this._unsubscribeAll)).subscribe((isMerchant) => {
      this.isMerchant = isMerchant;
    });

    this.accountId = this._data.accountId;
    this.payment = this._data.payment;
    this.successfulCharge = this.payment.charges.find((c) => c.status === 'succeeded');

    // Handles cases where a tip exists.
    this.maxRefund =
      Math.max(this.payment.amount, this.payment.amount_received) - this.successfulCharge.amount_refunded;
    this.platformFeeRefund = false;

    this.refundError$ = this._refundsAppService.refundCreatedError$;
    this.refundError$.subscribe((message) => {
      this.alertType = 'warn';
      this.alertTitle = 'Refund Attempt Failed';
      this.alertMessage = message;
      this.alertDismissible = true;
      this._displayAlert$.next(true);
      this._fuseAlertService.show('refundDialogAlertBox');
      this.activelyRefunding = false;
    });

    this.refundCreated$ = this._refundsAppService.refundCreated$;
    this.refundCreated$.subscribe((success) => {
      if (success) {
        this.closeDialog(this.refundForm);
      }
    });
    this._brandingService.isWhiteLabel$.subscribe((isWhiteLabel) => {
      this.isWhiteLabel = isWhiteLabel;
      this.loadTilledJs();
    });

    this._internalService
      .internalGetPaymentIntentRefundability({ paymentIntentId: this.payment.id, tilledAccount: this.accountId })
      .subscribe({
        next: async (refundability) => {
          if (refundability) {
            // TODO: If not refundable, we should warn and close the dialog
            // This refundability check is more about whether the underlying payment providers supports refunding
            // at this time.
            this.refundable = refundability.refundable;

            const shouldRequireNewPaymentMethod =
              refundability.requires_new_payment_method && refundability.supports_new_payment_method;

            if (this.refundable && shouldRequireNewPaymentMethod) {
              this.enableRequireNewPaymentMethod();
            }
          }
        },
        error: (err) => {
          // Supress this error. We won't show the payment method form by default.
        },
      });
  }

  async ngOnInit(): Promise<void> {
    this.refundForm = this._formBuilder.group({
      payment_intent_id: new FormControl<string | null>(this.payment.id, [Validators.required]),
      amount: new FormControl<number | null>(null, [
        Validators.required,
        Validators.max(this.maxRefund / 100),
        Validators.min(0.01),
      ]),
      reason: new FormControl<string | null>(null, []),
      refund_platform_fee: new FormControl<boolean | null>(this.platformFeeRefund, []),
    });
  }

  public togglePlatformFeeRefund(): void {
    this.platformFeeRefund = !this.platformFeeRefund;
    this.alertType = 'warning';
    this.alertTitle = 'Platform fee is refunded to the customer';
    this.alertMessage = 'Because the customer incurred the platform fee, they will receive the platform fee refund.';
    this.alertDismissible = false;
    this._displayAlert$.next(this.platformFeeRefund);
  }

  public submitRefundClicked(): void {
    this.refundForm.get('refund_platform_fee').patchValue(this.platformFeeRefund);

    const refundDto = this.refundForm.getRawValue();
    refundDto.amount = this._numericToMinorPipe.transform(refundDto.amount);

    const refundRequestParams: InternalCreateRefundRequestParams = {
      tilledAccount: this.accountId,
      internalRefundCreateParams: {
        amount: refundDto.amount,
        payment_intent_id: refundDto.payment_intent_id,
        reason: refundDto.reason,
        refund_platform_fee: refundDto.refund_platform_fee,
      },
    };

    if (this.requireNewPaymentMethod) {
      this.activelyRefunding = true;
      this.createPaymentMethod().then(
        async (paymentMethod) => {
          refundRequestParams.internalRefundCreateParams.payment_method_id = paymentMethod.id;
          this._refundsAppService.createRefund(refundRequestParams);
        },
        (err) => {
          let message: string = err;
          if (err === 'Unauthorized: jwt expired') {
            message = 'Please close this payment form and try again.';
          }

          this.alertType = 'warn';
          this.alertTitle = 'Creating Payment Method Failed';
          this.alertMessage = message;
          this.alertDismissible = true;
          this._displayAlert$.next(true);
          this._fuseAlertService.show('refundDialogAlertBox');
          this.activelyRefunding = false;
        },
      );
    } else {
      this.activelyRefunding = true;
      this._refundsAppService.createRefund(refundRequestParams);
    }
  }

  public closeDialog(dialogResult?: any): void {
    if (this.tilledJsCardForm) {
      this.tilledJsCardForm.teardown();
    }

    this.matDialogRef.close(dialogResult);
  }

  private async enableRequireNewPaymentMethod(): Promise<void> {
    // We need to wait until Tilled.js has loaded
    if (this.isTilledJsLoaded()) {
      // Add the required controls before setting the public property.
      this.addCardControls();
      // Required to set property *first* so the DOM loads the fields
      // TODO: Make fields invisible instead of CommonModule...
      this.requireNewPaymentMethod = true;
      await this.buildTilledJsCardForm();
    } else {
      // FIXME: Figure out how to either get an onload alert or something.
      setTimeout(async () => {
        if (this.isTilledJsLoaded()) {
          // Add the required controls before setting the public property.
          this.addCardControls();
          // Required to set property *first* so the DOM loads the fields
          // TODO: Make fields invisible instead of CommonModule...
          this.requireNewPaymentMethod = true;
          await this.buildTilledJsCardForm();
        }
      }, 500);
    }
  }

  private addCardControls(): void {
    this.refundForm.addControl('cardNumber', new FormControl<string | null>(null, [Validators.required]));
    this.refundForm.addControl('cardExpiration', new FormControl<string | null>(null, [Validators.required]));
  }

  // START OF TILLED JS / CREDIT CARD FORM
  // TODO: Move into shared component
  private loadTilledJs(): void {
    var script = document.createElement('script');
    const hostname = window.location.hostname;
    if (
      window.location.hostname.includes('staging-app.tilled.com') ||
      window.location.hostname.includes('staging-paymentsonline.io') ||
      window.location.hostname.includes('amplifyapp.com')
    ) {
      if (this.isWhiteLabel) {
        script.src = 'https://js.staging-paymentsonline.io/v2';
      } else {
        script.src = 'https://staging-js.tilled.com/v2';
      }
    } else if (window.location.hostname.includes('localhost') || hostname.includes('ngrok')) {
      if (this.isWhiteLabel) {
        script.src = './assets/js/payments.js';
      } else {
        script.src = './assets/js/tilled.js';
      }
    } else {
      if (this.isWhiteLabel) {
        script.src = 'https://js.paymentsonline.io/v2';
      } else {
        script.src = 'https://js.tilled.com/v2';
      }
    }
    document.head.appendChild(script);
  }

  private isTilledJsLoaded(): boolean {
    const script = document.getElementById('tilledjs');
    return script && script.getAttribute('data-loaded') === 'true';
  }

  private async buildTilledJsCardForm(): Promise<void> {
    const publishableKey = AuthService.getAccessToken();

    if (this.isWhiteLabel) {
      this.tilledJsCard = new Payments(publishableKey, this.payment?.account_id, {
        endpoint: environment.whiteLabelApi + '/v1',
        sandbox: !environment.production,
        log_level: 0,
      });
    } else {
      this.tilledJsCard = new Tilled(publishableKey, this.payment?.account_id, {
        endpoint: environment.api + '/v1',
        sandbox: !environment.production,
        log_level: 0,
      });
    }

    this.tilledJsCardForm = await this.tilledJsCard.form({
      payment_method_type: 'card', // PaymentMethodType.CARD,
    });

    const fieldOptions = {
      styles: {
        base: {
          fontFamily: 'Inter var, sans-serif',
          color: '#1B253B', //fuse-accent (text-tilled-primary)
          fontWeight: '400',
          fontSize: '15px',
        },
      },
    };

    const cardNumberField = this.tilledJsCardForm
      .createField('cardNumber', fieldOptions)
      .inject('#card-number-element');
    const cardExpiryField = this.tilledJsCardForm
      .createField('cardExpiry', { ...fieldOptions, placeholder: '' })
      .inject('#card-expiration-element');
    const cardCvvField = this.tilledJsCardForm.createField('cardCvv', fieldOptions).inject('#card-cvv-element');

    cardNumberField.on('change', (change) => {
      const cardBrand = change.brand;
      const cardSuffix: any = document.getElementById('card-suffix');

      switch (cardBrand) {
        case 'amex':
          cardSuffix.innerHTML = '<img class="p-8" src="assets/images/card-brands/amex.tif.svg" />';
          break;
        case 'mastercard':
          cardSuffix.innerHTML = '<img class="p-8" src="assets/images/card-brands/mastercard.tif.svg" />';
          break;
        case 'visa':
          cardSuffix.innerHTML = '<img src="assets/images/card-brands/visa.tif.svg" />';
          break;
        case 'discover':
          cardSuffix.innerHTML = '<img src="assets/images/card-brands/discover.tif.svg" />';
          break;
        default:
          cardSuffix.innerHTML =
            '<mat-icon role="img" class="mat-icon notranslate material-icons mat-icon-no-color" aria-hidden="true" data-mat-icon-type="font">credit_card</mat-icon>';
      }
      if (cardNumberField.valid) {
        cardNumberField.element.classList.remove('success');
        cardNumberField.element.classList.remove('error');
        this.showCardNumberError = false;
        cardNumberField.element.classList.add('success');
      }
    });

    cardNumberField.on('focus', () => {
      cardNumberField.element.classList.add('focus');
    });

    cardNumberField.on('blur', () => {
      cardNumberField.element.classList.remove('success');
      cardNumberField.element.classList.remove('error');
      if (cardNumberField.valid) {
        this.showCardNumberError = false;
        cardNumberField.element.classList.add('success');
      } else {
        this.showCardNumberError = true;
        cardNumberField.element.classList.add('error');
      }
      cardNumberField.element.classList.remove('focus');
    });

    cardExpiryField.on('change', (change) => {
      if (cardExpiryField.valid) {
        cardExpiryField.element.classList.remove('success');
        cardExpiryField.element.classList.remove('error');
        this.showExpirationError = false;
        cardExpiryField.element.classList.add('success');
      }
    });

    cardExpiryField.on('focus', () => {
      cardExpiryField.element.classList.add('focus');
    });

    cardExpiryField.on('blur', () => {
      cardExpiryField.element.classList.remove('success');
      cardExpiryField.element.classList.remove('error');
      if (cardExpiryField.valid) {
        this.showExpirationError = false;
        cardExpiryField.element.classList.add('success');
      } else {
        this.showExpirationError = true;
        cardExpiryField.element.classList.add('error');
      }
      cardExpiryField.element.classList.remove('focus');
    });

    cardCvvField.on('change', (change) => {
      if (cardCvvField.valid) {
        cardCvvField.element.classList.remove('success');
        cardCvvField.element.classList.remove('error');
        this.showCvvError = false;
        cardCvvField.element.classList.add('success');
      }
    });

    cardCvvField.on('focus', () => {
      cardCvvField.element.classList.add('focus');
    });

    cardCvvField.on('blur', () => {
      cardCvvField.element.classList.remove('success');
      cardCvvField.element.classList.remove('error');
      if (cardCvvField.valid) {
        this.showCvvError = false;
        cardCvvField.element.classList.add('success');
      } else {
        this.showCvvError = true;
        cardCvvField.element.classList.add('error');
      }
      cardCvvField.element.classList.remove('focus');
    });

    await this.tilledJsCardForm.build();
  }

  private createPaymentMethod(): Promise<PaymentMethod> {
    return this.tilledJsCard.createPaymentMethod({
      type: PaymentMethodCreateParams.TypeEnum.CARD,
      billing_details: {
        address: {
          // street: this.cardPaymentForm.get('street1').value,
          // street2: this.cardPaymentForm.get('street2').value,
          // country: this.cardPaymentForm.get('country').value,
          // state: this.cardPaymentForm.get('state').value,
          // city: this.cardPaymentForm.get('city').value,
          zip: this.refundForm.get('postalCode').value,
        },
        name: this.refundForm.get('cardholderName').value,
      },
    }) as Promise<PaymentMethod>;
  }
  // END OF TILLED JS / CREDIT CARD FORM
}
