import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponseBase,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { AuthService } from 'app/core/services/auth.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { LoginDto } from '../../../../projects/tilled-api-client/src';
import { environment } from '../../../environments/environment';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  // We do not want to perform a refresh on these routes
  private readonly routesToIgnore = [
    'auth/login',
    'auth/refresh',
    'auth/logout',
    // TODO: fix isTokenExpired bug in auth service and can maybe remove this
    'auth-links',
  ];

  constructor(private _injector: Injector) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isTilledApi = req.url.includes(environment.api);
    const shouldIgnoreRoute = !this.routesToIgnore.every((route) => !req.url.includes(route));
    const requestContainsAuth = req.headers.get('Authorization') != null; // Let unauthenticated requests go

    const shouldInterceptRequest = isTilledApi && requestContainsAuth && !shouldIgnoreRoute;

    if (shouldInterceptRequest) {
      const tokenExpired = AuthService.isTokenExpired();

      if (tokenExpired) {
        // If the token is expired, we want to pre-emptively refresh it without waiting for a 401
        return this.refreshAccessTokenAndRetryRequest(req, next);
      } else {
        // We'll attempt the request and IF it fails with a 401
        // then we will attempt to refresh the access token and retry
        return next.handle(req).pipe(
          catchError((error) => {
            // TODO: Catch only "expired" token attempt (message = 'jwt expired', perhaps).
            // Right now, *any* 401 error will assume that a refresh is needed.
            // We know for sure that a 'This request requires the following scope(s):' is an example
            // of a 401 error that is not due to expired tokens so we'll exclude that
            if (
              error instanceof HttpErrorResponse &&
              error.status === 401 &&
              !shouldIgnoreRoute &&
              !error.error?.message?.includes('scope')
            ) {
              return this.refreshAccessTokenAndRetryRequest(req, next);
            }
            return throwError(() => error);
          }),
        );
      }
    } else {
      return next.handle(req);
    }
  }

  private refreshAccessTokenAndRetryRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      const refreshTokenExpired = AuthService.isRefreshTokenExpired();
      const authService = this._injector.get(AuthService);

      if (!refreshTokenExpired) {
        this.isRefreshing = true;
        // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
        this.refreshTokenSubject.next(null);

        return authService.refreshAccessToken().pipe(
          switchMap((response: LoginDto) => {
            // For some reason, THIS BLOCK OF CODE, is NOT being called
            // if you open up a NEW tab with an expired access token...
            // The first load will work, but then subsequent attempts
            // will obviously have an issue because this.isRefreshing === true.
            this.isRefreshing = false;
            this.refreshTokenSubject.next(response.token);

            return next.handle(this.addAuthorizationHeader(req, response.token));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            if (err instanceof HttpResponseBase && err.status === 401) {
              authService.logout(true);
            } else {
              return throwError(() => err);
            }
          }),
        );
      } else {
        // If we try to refresh without a valid refresh token, we need to go ahead and logout
        authService.logout(true);
      }
    } else {
      // If we are actively refreshing, we will wait until refreshTokenSubject has a non-null value
      // When the new token is ready and we can retry the request again
      // This is what ensures that when *multiple* requests fail with a 401,
      // the *subsequent* requests get retried. The first failed request is retried above.
      return this.refreshTokenSubject.pipe(
        filter((accessToken) => accessToken !== null),
        take(1),
        switchMap((accessToken) => next.handle(this.addAuthorizationHeader(req, accessToken))),
      );
    }
  }

  private addAuthorizationHeader(request: HttpRequest<any>, accessToken: string): HttpRequest<any> {
    return request.clone({
      headers: request.headers.set('Authorization', 'Bearer ' + accessToken),
    });
  }
}
