import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import {
  AccountDocument,
  AttributesEsignature,
  CreateUserInvitationRequestParams,
  InternalAccount,
  InternalAccountCapability,
  InternalService,
} from '@tilled-api-client';
import { HOMEPAGE_ROUTE } from 'app/core/constants';
import { AppUser } from 'app/core/data/auth-types';
import { AuthService } from 'app/core/services/auth.service';
import { Observable, Subject, takeUntil } from 'rxjs';
import { MultiAccountSelectorComponent } from '../../../shared/multi-account-selector/multi-account-selector.component';

import { CommonModule } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { BrandingService } from 'app/core/services/branding.service';
import { MerchantAppService } from 'app/core/services/merchant-app.service';
import { UsersAppService } from 'app/core/services/users.app.service';
import { MerchantAppInvitationDialogComponent } from 'app/shared/merchant-app-invitation/merchant-app-invitation-dialog.component';
import { FuseLoadingBarComponent } from '../../../../@fuse/components/loading-bar/loading-bar.component';
import { TilledAlertComponent } from '../../../shared/tilled-alert/tilled-alert.component';
import { TilledParagraphP3Component } from '../../../shared/tilled-text/tilled-paragraph/tilled-paragraph-p3.component';
import { UserComponent } from '../../common/user/user.component';

@Component({
  selector: 'onboarding-layout',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FuseLoadingBarComponent,
    TilledAlertComponent,
    UserComponent,
    MultiAccountSelectorComponent,
    RouterOutlet,
    CommonModule,
    TilledParagraphP3Component,
    MatIconModule,
    MatDividerModule,
    MatButtonModule,
    MatMenuModule,
  ],
})
export class OnboardingLayoutComponent implements OnInit, OnDestroy {
  public isMultiAccount = false;
  public dialogRef: any;
  public account: InternalAccount;
  public isWhiteLabel$: Observable<boolean>;
  public logoUrl$: Observable<string>;
  public merchantSupportCenterUrl: string = 'https://paymentsonline.zendesk.com/hc/en-us';
  public windowWidth: any;
  public user: AppUser;
  public drawerOpened$: Observable<boolean>;

  private fromSigning = false;
  private hasTsysProvider = false;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  /**
   * Constructor
   */
  constructor(
    private _authService: AuthService,
    private _router: Router,
    private _matDialog: MatDialog,
    private _usersAppService: UsersAppService,
    private _brandingService: BrandingService,
    private _internalService: InternalService,
    private _merchantAppService: MerchantAppService,
    private _route: ActivatedRoute,
  ) {
    this.logoUrl$ = this._brandingService.logoUrl$;
    this.drawerOpened$ = this._merchantAppService.drawerOpen$;
    this.isWhiteLabel$ = this._brandingService.isWhiteLabel$;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.windowWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.windowWidth = window.innerWidth;

    this._route.queryParams.subscribe((params) => {
      const event = params['event'];
      if (event === 'signing_complete') {
        this.fromSigning = true;
      }
    });

    // Subscribe to the user service
    this._authService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: AppUser) => {
      this.isMultiAccount = user?.account_roles?.length > 1;
      this.user = user;
    });
    // Check if a merchant support center url has been set, if so, use it in the help center hyperlink
    this._authService.account$.pipe(takeUntil(this._unsubscribeAll)).subscribe((account: InternalAccount) => {
      this.account = account;
      this.handleAccountUpdate(account);
      this._internalService.internalGetAccountSettingsBranding({ tilledAccount: account.id }).subscribe({
        next: (asb) => {
          if (asb && asb.is_white_label && account.type === InternalAccount.TypeEnum.MERCHANT) {
            if (asb.merchant_support_center_url) {
              this.merchantSupportCenterUrl = asb.merchant_support_center_url;
            }
          }
        },
      });
      this._merchantAppService.hasTsysProvider$.pipe(takeUntil(this._unsubscribeAll)).subscribe((hasTsys) => {
        this.hasTsysProvider = hasTsys;

        if (
          this.hasTsysProvider &&
          account.capabilities?.filter(
            (cap) =>
              cap.status === InternalAccountCapability.StatusEnum.STARTED &&
              cap.attributes?.esignature?.status === AttributesEsignature.StatusEnum.SENT,
          )?.length > 0
        ) {
          this.handleAccountUpdate(account);
        }
      });
      this._merchantAppService.updateProviderData();
    });
  }
  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  public stepperToggle(): void {
    this._merchantAppService.toggleDrawer();
  }

  inviteUser(): void {
    this.dialogRef = this._matDialog.open(MerchantAppInvitationDialogComponent, {
      autoFocus: false,
      data: {
        accountId: AuthService.getCurrentAccountId(),
        title: 'Invite a collaborator',
        legalName: this.account.business_profile.legal_name,
        showCopyUrlButton: false,
        submitButtonText: 'Send Invitation',
        showLinkToUsers: false,
      },
    });

    this.dialogRef.afterClosed().subscribe((response: FormGroup) => {
      if (!response) {
        return;
      }

      const params: CreateUserInvitationRequestParams = {
        tilledAccount: this.account.id,
        userInvitationCreateParams: {
          email: response.getRawValue().email,
          role: response.getRawValue().role,
        },
      };

      this._usersAppService.sendUserInvitation(params);
    });
  }

  private handleAccountUpdate(account: InternalAccount): void {
    const currentUrl = this._router.url;

    let urlToNavigateTo: string;

    if (
      account.capabilities?.find((cap) =>
        [InternalAccountCapability.StatusEnum.ACTIVE, InternalAccountCapability.StatusEnum.DISABLED].includes(
          cap.status,
        ),
      )
    ) {
      if (
        account.capabilities.find(
          (cap) =>
            (cap.provider_type === InternalAccountCapability.ProviderTypeEnum.TSYS ||
              cap.provider_type === InternalAccountCapability.ProviderTypeEnum.VALOR) &&
            cap.status === InternalAccountCapability.StatusEnum.STARTED &&
            cap.attributes?.esignature?.status === AttributesEsignature.StatusEnum.SENT,
        ) &&
        currentUrl.includes('onboarding/sign')
      ) {
        urlToNavigateTo = currentUrl;
      } else {
        urlToNavigateTo = HOMEPAGE_ROUTE;
      }
    } else if (
      account.capabilities?.find((cap) =>
        [InternalAccountCapability.StatusEnum.SUBMITTED, InternalAccountCapability.StatusEnum.IN_REVIEW].includes(
          cap.status,
        ),
      ) &&
      account.document_requests?.length > 0 &&
      account.document_requests.find((doc) => doc.status === AccountDocument.StatusEnum.REQUESTED)
    ) {
      urlToNavigateTo = '/onboarding/documents';
    } else if (
      account.capabilities?.every((cap) =>
        [
          InternalAccountCapability.StatusEnum.SUBMITTED,
          InternalAccountCapability.StatusEnum.REJECTED,
          InternalAccountCapability.StatusEnum.WITHDRAWN,
          InternalAccountCapability.StatusEnum.IN_REVIEW,
        ].includes(cap.status),
      )
    ) {
      // redirect to submitted page.
      urlToNavigateTo = '/onboarding/submitted';
    } else {
      // Authorized user that has not yet completed a merchant application
      // Redirect to merch app.

      if (
        account.capabilities?.filter(
          (cap) =>
            cap.status === InternalAccountCapability.StatusEnum.STARTED &&
            cap.attributes?.esignature?.status === AttributesEsignature.StatusEnum.SENT,
        )?.length > 0 &&
        this.hasTsysProvider &&
        !this.fromSigning
      ) {
        urlToNavigateTo = '/onboarding/sign';
      } else {
        const queryParams = this._route.snapshot.queryParams;
        if (queryParams?.step) {
          urlToNavigateTo = `/onboarding/application?step=${queryParams.step}`;
        } else {
          urlToNavigateTo = '/onboarding/application';
        }
      }
    }

    if (currentUrl !== urlToNavigateTo) {
      this._router.navigate([urlToNavigateTo]);
    }
  }
}
