<div class="grid w-full grid-cols-1 gap-6 md:grid-cols-2">
  <!-- Company Overview -->
  <div class="col-span-1 row-span-1">
    <fuse-card class="tilled-card-styling h-fit" #fuseCard data-test-id="company-overview-card">
      <div class="w-full">
        <div class="w-full">
          <tilled-heading-h4>Company Overview</tilled-heading-h4>
        </div>
        <mat-divider class="card-title-divider"></mat-divider>
        <ng-container>
          <div class="w-full">
            <tilled-card-row labelText="Company Name" [valueText]="account.name"></tilled-card-row>
            <tilled-card-row labelText="Company Email" [valueText]="account.email"></tilled-card-row>
            <tilled-card-row
              labelText="Company Phone"
              [valueText]="account.business_profile?.phone | phoneNumber: 'US'"
            ></tilled-card-row>
            <tilled-card-row labelText="Website" [valueText]="account.business_profile?.website"></tilled-card-row>
            <tilled-card-row
              labelText="Business Structure"
              [valueText]="account.business_profile?.structure"
            ></tilled-card-row>
            <tilled-card-row labelText="Account ID" [valueText]="account.id" [useTitlecase]="false"></tilled-card-row>
            <div *ngIf="account.business_profile?.address">
              <mat-divider class="mb-4 mt-3 w-full"></mat-divider>
              <tilled-card-row
                labelText="Street"
                [valueText]="account.business_profile.address.street"
              ></tilled-card-row>
              <tilled-card-row
                labelText="Street 2"
                [valueText]="account.business_profile.address.street2"
              ></tilled-card-row>
              <tilled-card-row labelText="City" [valueText]="account.business_profile.address.city"></tilled-card-row>
              <tilled-card-row labelText="State" [valueText]="account.business_profile.address.state"></tilled-card-row>
              <tilled-card-row
                labelText="Zip"
                [valueText]="account.business_profile.address.postal_code"
              ></tilled-card-row>
            </div>
          </div>
        </ng-container>
      </div>
    </fuse-card>
  </div>

  <!-- Business Representatives -->
  <div class="col-span-1 row-span-2" *ngIf="account.business_profile?.representatives?.length > 0">
    <fuse-card class="tilled-card-styling col-span-1 h-fit" #fuseCard data-test-id="business-representatives-card">
      <div class="w-full">
        <div class="w-full">
          <tilled-heading-h4>Business Representatives</tilled-heading-h4>
        </div>
        <mat-divider class="card-title-divider"></mat-divider>
        <ng-container *ngFor="let businessRep of account.business_profile?.representatives; let i = index">
          <div class="w-full">
            <mat-divider class="mb-3 mt-3 w-full" *ngIf="i > 0"></mat-divider>

            <tilled-card-row labelText="Name" [valueText]="getRepName(businessRep)"></tilled-card-row>
            <tilled-card-row labelText="Job Title" [valueText]="businessRep.job_title"></tilled-card-row>
            <tilled-card-row labelText="Phone" [valueText]="businessRep.phone | phoneNumber: 'US'"></tilled-card-row>
            <tilled-card-row labelText="Email" [valueText]="businessRep.email"></tilled-card-row>
            <tilled-card-row
              labelText="Control Prong"
              [valueText]="businessRep.is_control_prong ? 'Yes' : 'No'"
            ></tilled-card-row>
            <tilled-card-row
              labelText="Applicant"
              [valueText]="businessRep.is_applicant ? 'Yes' : 'No'"
            ></tilled-card-row>
          </div>
        </ng-container>
      </div>
    </fuse-card>
  </div>

  <!-- Processing Capabilities -->
  <div class="col-span-1 row-span-2">
    <fuse-card class="tilled-card-styling h-fit" #fuseCard data-test-id="processing-capabilities-card">
      <div class="w-full">
        <div class="w-full">
          <tilled-heading-h4>Processing Capabilities</tilled-heading-h4>
        </div>
        <mat-divider class="card-title-divider"></mat-divider>
        <ng-container>
          <div class="w-full">
            <tilled-card-row
              labelText="Card Processing"
              [valueText]="(account | capabilityStatus: 'card') === 'active' ? 'Enabled' : 'Disabled'"
            ></tilled-card-row>
            <tilled-card-row
              labelText="Bank-to-bank Processing"
              [valueText]="(account | capabilityStatus: 'debit') === 'active' ? 'Enabled' : 'Disabled'"
            ></tilled-card-row>
          </div>
        </ng-container>
      </div>
    </fuse-card>
  </div>
</div>
