import { CdkScrollable } from '@angular/cdk/scrolling';
import { CommonModule, KeyValue } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatStepper, MatStepperModule } from '@angular/material/stepper';
import { fuseAnimations } from '@fuse/animations';
import { PaymentsTypes } from 'app/core/data/payments-types';
import { SelectorTypes } from 'app/core/data/selector-types';
import { MinorUnitsToCurrencyPipe } from 'app/core/pipes/minor-units-to-currency.pipe';
import { NumericToMinorUnitsPipe } from 'app/core/pipes/numeric-to-minor-units.pipe';
import { AccountAppService } from 'app/core/services/account.app.service';
import { AuthService } from 'app/core/services/auth.service';
import { BrandingService } from 'app/core/services/branding.service';
import { OutboundTransfersAppService } from 'app/core/services/outbound-transfers.app.service';
import { TilledSelectComponent } from 'app/shared/tilled-select/tilled-select.component';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';
import {
  Account,
  AccountCapability,
  CreateOutboundTransferRequestParams,
  LineItem,
  PaymentMethod,
  PaymentMethodCreateParams,
} from '../../../../../projects/tilled-api-client/src';
import { FormCardComponent } from '../../cards/form-cards/form-card.component';
import { TilledInputComponent } from '../../form-fields/tilled-input/tilled-input.component';
import { TilledHeadingH2Component } from '../../tilled-text/tilled-heading/tilled-heading-h2.component';
import { TilledLabelL1Component } from '../../tilled-text/tilled-label/tilled-label-l1.component';
import { TilledParagraphP3Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';

declare let Tilled: any; // from /assets/js/tilled.js
declare let Payments: any; // from /assets/js/payments.js
@Component({
  selector: 'app-send-outbound-transfer',
  templateUrl: './send-outbound-transfer-dialog.component.html',
  styleUrls: ['./send-outbound-transfer-dialog.component.scss'],
  animations: fuseAnimations,
  standalone: true,
  imports: [
    MatStepperModule,
    CdkScrollable,
    FormCardComponent,
    FormsModule,
    ReactiveFormsModule,
    TilledHeadingH2Component,
    TilledParagraphP3Component,
    MatFormFieldModule,
    TilledLabelL1Component,
    MatSelectModule,
    MatOptionModule,
    TilledInputComponent,
    TilledSelectComponent,
    MinorUnitsToCurrencyPipe,
    CommonModule,
  ],
})
export class SendOutboundTransferDialogComponent implements OnInit {
  @ViewChild('stepper') stepper: MatStepper;
  private accountId: string;
  public isMerchant: boolean;
  public account$: Observable<Account>;
  public merchantAccount: Account;
  public newOutboundTransferForm: FormGroup;
  public availablePaymentMethods: Map<string, string>;
  public availableCurrencies: Map<string, string>;
  public bankTransferForm: FormGroup;
  public eftTransferForm: FormGroup;
  public selectedPaymentType: PaymentMethod.TypeEnum;
  public pageTitle: string;
  public selectedPaymentMethod: string;
  public selectedCurrency: string;
  public enablePaymentMethod: boolean = false;
  public enableCurrency: boolean = false;

  public lineItems: LineItem[];

  public countryCodeMap: { label: string; value: string }[];
  public stateCodeMap: { label: string; value: string }[];

  private tilledBank: any;
  public tilledBankForm: any;
  public showAccountNumberError = false;
  public showRoutingNumberError = false;
  private bankFormBuilt = false;

  private createError$: Observable<string>;
  private _alertDialogError$ = new Subject<boolean>();
  public alertDialogError$ = this._alertDialogError$.asObservable();
  public errorTitle: string;
  public errorMessage: string;

  private _sendingTransfer$ = new Subject<boolean>();
  public sendingTransfer$ = this._sendingTransfer$.asObservable();
  private outboundTransferCreated$: Observable<boolean>;
  private isWhiteLabel: boolean;

  constructor(
    public dialogRef: MatDialogRef<SendOutboundTransferDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private _data: any,
    private _formBuilder: FormBuilder,
    private _accountAppService: AccountAppService,
    private _numericToMinorPipe: NumericToMinorUnitsPipe,
    private _outboundTransferService: OutboundTransfersAppService,
    private _brandingService: BrandingService,
  ) {
    this._brandingService.isWhiteLabel$.subscribe((isWhiteLabel) => {
      this.isWhiteLabel = isWhiteLabel;
      this.loadTilledJs();
    });
  }

  ngOnInit(): void {
    this.accountId = this._data?.accountId ?? null;

    // only a merchant can send an outbound transfer through UI
    this.account$ = this._accountAppService.connectedAccount$;
    this._accountAppService.getConnectedAccountById(this.accountId);
    this.account$.subscribe({
      next: (result) => {
        this.merchantAccountChanged(result);
      },
      error: (err) => {},
    });

    this.newOutboundTransferForm = this._formBuilder.group({
      paymentMethod: new FormControl<string | null>(null, [Validators.required]),
      currency: new FormControl<string | null>(null, [Validators.required]),
      amount: new FormControl<number | null>(null, [Validators.required, Validators.min(0.01)]),
    });

    this.bankTransferForm = this._formBuilder.group({
      accountholderName: new FormControl<string | null>(null, [Validators.required]),
      accountType: new FormControl<string | null>(null, [Validators.required]),
      street1: new FormControl<string | null>(null, [Validators.required]),
      street2: new FormControl<string | null>(null),
      country: new FormControl<string | null>(null, [Validators.required]),
      state: new FormControl<string | null>(null, [Validators.required]),
      city: new FormControl<string | null>(null, [Validators.required]),
      postalCode: new FormControl<string | null>(null, [Validators.required]),
    });

    this.countryCodeMap = Array.from(SelectorTypes.CountryToCode).map(([label, value]) => ({ label, value }));
    this.stateCodeMap = Array.from(SelectorTypes.stateAndProvinceMap).map(([value, label]) => ({ value, label }));

    this.createError$ = this._outboundTransferService.createError$;
    this.createError$.subscribe((message) => {
      this.errorTitle = 'Outbound Transfer Failed';
      this.errorTitle = message;
      this._alertDialogError$.next(true);
      this._sendingTransfer$.next(false);
    });

    this.outboundTransferCreated$ = this._outboundTransferService.outboundTransferCreated$;
    this.outboundTransferCreated$.subscribe((success) => {
      this.closeDialog(success);
    });
  }

  loadTilledJs(): void {
    var script = document.createElement('script');
    const hostname = window.location.hostname;
    if (
      window.location.hostname.includes('staging-app.tilled.com') ||
      window.location.hostname.includes('staging-paymentsonline.io') ||
      window.location.hostname.includes('amplifyapp.com')
    ) {
      if (this.isWhiteLabel) {
        script.src = 'https://js.staging-paymentsonline.io/v2';
      } else {
        script.src = 'https://staging-js.tilled.com/v2';
      }
    } else if (window.location.hostname.includes('localhost') || hostname.includes('ngrok')) {
      if (this.isWhiteLabel) {
        script.src = './assets/js/payments.js';
      } else {
        script.src = './assets/js/tilled.js';
      }
    } else {
      if (this.isWhiteLabel) {
        script.src = 'https://js.paymentsonline.io/v2';
      } else {
        script.src = 'https://js.tilled.com/v2';
      }
    }
    document.head.appendChild(script);
  }

  merchantAccountChanged(account: Account): void {
    if (!account?.id) {
      return;
    }

    this.merchantAccount = account;

    this.availablePaymentMethods = new Map<string, string>();
    this.availableCurrencies = new Map<string, string>();
    for (let capability of this.merchantAccount.capabilities) {
      if (
        capability.status === AccountCapability.StatusEnum.ACTIVE &&
        capability?.product_code?.payment_method_type !== PaymentMethod.TypeEnum.CARD
      ) {
        this.availablePaymentMethods.set(
          capability?.product_code?.payment_method_type,
          PaymentsTypes.PaymentMethodDisplayText.get(capability?.product_code?.payment_method_type),
        );
      }
      if (capability.status === AccountCapability.StatusEnum.ACTIVE) {
        this.availableCurrencies.set(
          capability?.product_code?.currency,
          PaymentsTypes.CurrencyDisplayText.get(capability?.product_code?.currency),
        );
      }
    }

    if (this.availablePaymentMethods.size === 1) {
      this.selectedPaymentMethod = Array.from(this.availablePaymentMethods.keys())[0];
      if (this.newOutboundTransferForm) {
        this.newOutboundTransferForm.get('paymentMethod').setValue(this.selectedPaymentMethod);
      }
      this.enablePaymentMethod = false;
    } else {
      this.selectedPaymentMethod = null;
      this.enablePaymentMethod = true;
    }

    if (this.availableCurrencies.size === 1) {
      this.selectedCurrency = Array.from(this.availableCurrencies.keys())[0];
      if (this.newOutboundTransferForm) {
        this.newOutboundTransferForm.get('currency').setValue(this.selectedCurrency);
      }
      this.enableCurrency = false;
    } else {
      this.selectedCurrency = null;
      this.enableCurrency = true;
    }
  }

  public async closeDialog(value?: any): Promise<void> {
    if (this.tilledBankForm) {
      await this.tilledBankForm.teardown();
    }

    this.dialogRef.close(value);
  }

  public async nextPage(): Promise<void> {
    this.selectedPaymentType = this.newOutboundTransferForm.get('paymentMethod').value;

    if (this.selectedPaymentType == PaymentMethod.TypeEnum.EFT_DEBIT) {
      this.bankTransferForm.get('accountType').clearValidators();
      this.bankTransferForm.get('accountType').updateValueAndValidity();
    }
    this.pageTitle = 'Send Outbound Transfer Payment for ';

    this.stepper.next();

    if (
      !this.bankFormBuilt &&
      (this.selectedPaymentType == PaymentMethod.TypeEnum.ACH_DEBIT ||
        this.selectedPaymentType == PaymentMethod.TypeEnum.EFT_DEBIT)
    ) {
      this.bankFormBuilt = true;
      await this.buildTilledBankForm();
    }
  }

  public prevPage(): void {
    this.selectedPaymentType = null;
    this.stepper.previous();
  }

  private async buildTilledBankForm(): Promise<void> {
    const publishableKey = AuthService.getAccessToken();
    if (this.isWhiteLabel) {
      this.tilledBank = new Payments(publishableKey, this.merchantAccount.id, {
        endpoint: environment.whiteLabelApi + '/v1',
        sandbox: !environment.production,
        log_level: 0,
      });
    } else {
      this.tilledBank = new Tilled(publishableKey, this.merchantAccount.id, {
        endpoint: environment.api + '/v1',
        sandbox: !environment.production,
        log_level: 0,
      });
    }

    this.tilledBankForm = await this.tilledBank.form({
      payment_method_type: this.selectedPaymentType,
    });

    const fieldOptions = {
      styles: {
        base: {
          fontFamily: 'Inter var, sans-serif',
          color: '#1B253B', //fuse-accent (text-tilled-primary)
          fontWeight: '400',
          fontSize: '15px',
        },
      },
    };

    this.tilledBankForm
      .createField('bankAccountNumber', { ...fieldOptions, placeholder: '1234567890' })
      .inject('#bank-account-number-element');
    this.tilledBankForm
      .createField('bankRoutingNumber', { ...fieldOptions, placeholder: '011103093' })
      .inject('#bank-routing-number-element');

    this.tilledBankForm.fields.bankAccountNumber.on('change', (change) => {
      if (this.tilledBankForm.fields.bankAccountNumber.valid) {
        this.tilledBankForm.fields.bankAccountNumber.element.classList.remove('success');
        this.tilledBankForm.fields.bankAccountNumber.element.classList.remove('error');
        this.showAccountNumberError = false;
        this.tilledBankForm.fields.bankAccountNumber.element.classList.add('success');
      }
    });

    this.tilledBankForm.fields.bankAccountNumber.on('focus', () => {
      this.tilledBankForm.fields.bankAccountNumber.element.classList.add('focus');
    });

    this.tilledBankForm.fields.bankAccountNumber.on('blur', () => {
      this.tilledBankForm.fields.bankAccountNumber.element.classList.remove('success');
      this.tilledBankForm.fields.bankAccountNumber.element.classList.remove('error');
      if (this.tilledBankForm.fields.bankAccountNumber.valid) {
        this.showAccountNumberError = false;
        this.tilledBankForm.fields.bankAccountNumber.element.classList.add('success');
      } else {
        this.showAccountNumberError = true;
        this.tilledBankForm.fields.bankAccountNumber.element.classList.add('error');
      }
      this.tilledBankForm.fields.bankAccountNumber.element.classList.remove('focus');
    });

    this.tilledBankForm.fields.bankRoutingNumber.on('change', (change) => {
      if (this.tilledBankForm.fields.bankRoutingNumber.valid) {
        this.tilledBankForm.fields.bankRoutingNumber.element.classList.remove('success');
        this.tilledBankForm.fields.bankRoutingNumber.element.classList.remove('error');
        this.showRoutingNumberError = false;
        this.tilledBankForm.fields.bankRoutingNumber.element.classList.add('success');
      }
    });

    this.tilledBankForm.fields.bankRoutingNumber.on('focus', () => {
      this.tilledBankForm.fields.bankRoutingNumber.element.classList.add('focus');
    });

    this.tilledBankForm.fields.bankRoutingNumber.on('blur', () => {
      this.tilledBankForm.fields.bankRoutingNumber.element.classList.remove('success');
      this.tilledBankForm.fields.bankRoutingNumber.element.classList.remove('error');
      if (this.tilledBankForm.fields.bankRoutingNumber.valid) {
        this.showRoutingNumberError = false;
        this.tilledBankForm.fields.bankRoutingNumber.element.classList.add('success');
      } else {
        this.showRoutingNumberError = true;
        this.tilledBankForm.fields.bankRoutingNumber.element.classList.add('error');
      }
      this.tilledBankForm.fields.bankRoutingNumber.element.classList.remove('focus');
    });

    await this.tilledBankForm.build();
  }

  public async sentOutboundTransfer(): Promise<void> {
    this._sendingTransfer$.next(true);
    await this.tilledBank
      .createPaymentMethod({
        type:
          this.selectedPaymentType === PaymentMethod.TypeEnum.ACH_DEBIT
            ? PaymentMethodCreateParams.TypeEnum.ACH_DEBIT
            : PaymentMethodCreateParams.TypeEnum.EFT_DEBIT,
        ach_debit: {
          account_type:
            this.selectedPaymentType === PaymentMethod.TypeEnum.ACH_DEBIT
              ? this.bankTransferForm.get('accountType').value
              : null,
          account_holder_name: this.bankTransferForm.get('accountholderName').value,
        },
        billing_details: {
          address: {
            street: this.bankTransferForm.get('street1').value,
            street2: this.bankTransferForm.get('street2').value,
            country: this.bankTransferForm.get('country').value,
            state: this.bankTransferForm.get('state').value,
            city: this.bankTransferForm.get('city').value,
            zip: this.bankTransferForm.get('postalCode').value,
          },
          name: this.bankTransferForm.get('accountholderName').value,
        },
      })
      .then(
        async (paymentMethod) => {
          await this.createOutboundTransfer(paymentMethod);
        },
        (err) => {
          // An error with the request (>400 status code)
          this.errorTitle = 'Creating Payment Method Failed';
          if (err === 'Unauthorized: jwt expired') {
            this.errorMessage = 'Please close this outbound transfer form and try again.';
          } else if (err.toString().includes('billing_details.address.state')) {
            if (this.bankTransferForm.get('country').value == 'US') {
              this.errorMessage = 'Please select a valid state';
            } else {
              this.errorMessage = 'Please select a valid province';
            }
          } else {
            this.errorMessage = err;
          }
          this._alertDialogError$.next(true);
          this._sendingTransfer$.next(false);
        },
      );
  }

  private createOutboundTransfer(paymentMethod: PaymentMethod): void {
    let createParams: CreateOutboundTransferRequestParams = {
      tilledAccount: this.merchantAccount.id,
      outboundTransferCreateParams: {
        amount: this._numericToMinorPipe.transform(this.newOutboundTransferForm.get('amount').value),
        currency: this.newOutboundTransferForm.get('currency').value,
        destination_payment_method_id: paymentMethod.id,
      },
    };

    this._outboundTransferService.createOutboundTransfer(createParams);
  }

  public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
    return 0;
  };
}
