import { AsyncPipe, NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { FuseCardComponent } from '@fuse/components/card';
import {
  InternalListPayoutsRequestParams,
  InternalPayout,
  InternalReportRun,
  ReportAdditionalFilter,
  ReportRunRequestParameters,
} from '@tilled-api-client';
import { ComponentBase } from 'app/core/componentBase';
import { DEFAULT_PAGE_LIMIT, PAYOUTS_ROUTE } from 'app/core/constants';
import { DateFormatPipe } from 'app/core/pipes/date-format.pipe';
import { PayoutStatusPipe } from 'app/core/pipes/payout-status.pipe';
import { AuthService } from 'app/core/services/auth.service';
import { FileDownloadService } from 'app/core/services/file-download.service';
import { PayoutsAppService } from 'app/core/services/payouts.app.service';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TilledChipConfig } from '../tilled-chip/tilled-chip.component';
import { Column } from '../tilled-table/decorators/column';
import { TilledTableComponent } from '../tilled-table/tilled-table.component';

@Component({
  selector: 'app-payout-list',
  templateUrl: './payout-list.component.html',
  styleUrls: ['./payout-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [TilledTableComponent, AsyncPipe, MatIcon, MatTooltip, FuseCardComponent, NgClass],
})
export class PayoutListComponent extends ComponentBase implements OnInit, OnChanges {
  @Input() accountId: string = null;
  @Input() query: string = null;
  @Input() queryMerchantId: string = null;
  @Input() queryAmount: string = null;
  @Input() queryStatus: InternalPayout.StatusEnum = null;
  @Input() queryPayoutId: string = null;
  @Input() paidAtGte: string = null;
  @Input() paidAtLte: string = null;
  @Input() pageIndex: number = 0;
  @Input() pageSize: number = DEFAULT_PAGE_LIMIT;
  @Input() includeConnectedAccounts: boolean = false;
  @Input() merchantDetails: boolean = false;
  @Input() updateQueryCallback: (
    q?: string,
    startDate?: string,
    endDate?: string,
    queryMerchantId?: string,
    page?: number,
    size?: number,
  ) => void;

  public payouts$: Observable<InternalPayout[]>;
  public payoutsViewModel$: Observable<PayoutViewModel[]>;
  public payoutsCount$: Observable<number>;
  public viewModels: PayoutViewModel[] = [];

  public isLoading = true;
  public sortInfo = null;
  public hideColumnKeys: PayoutViewModelKeys[] = [];
  public isMerchant: boolean = false;
  public reportDownloadEnabled = false;
  public reportDownloadTooltip = 'Filter by date to enable 1-click download.';
  public noData = false;

  constructor(
    private _authService: AuthService,
    private _payoutsAppService: PayoutsAppService,
    private _dateFormatPipe: DateFormatPipe,
    private _router: Router,
    private _payoutStatusPipe: PayoutStatusPipe,
    private _fileDownloadService: FileDownloadService,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.payouts$ = this._payoutsAppService.payouts$;
    this.payoutsCount$ = this._payoutsAppService.payoutsCount$;

    this.payoutsViewModel$ = this.payouts$.pipe(map((payouts) => this.getViewModelsFromPayouts(payouts)));
    this._authService.isMerchantAccount$.pipe(takeUntil(this._unsubscribeAll)).subscribe((isMerchant) => {
      this.isMerchant = isMerchant;
      if (isMerchant && this.hideColumnKeys.length === 0) {
        this.hideColumnKeys = ['merchant'];
      }
    });
    this.setTableType();
    this.getPayouts(this.pageSize, this.pageIndex);
  }

  ngOnChanges(changes: SimpleChanges): void {
    let firstChange = true;

    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        firstChange = firstChange && changes[propName].isFirstChange();
      }
    }

    if (!firstChange) {
      this.setTableType();
      this.getPayouts(this.pageSize, this.pageIndex);
    }
  }

  rowClickedCallback = (data: PayoutViewModel): void => {
    this._router.navigate([`${PAYOUTS_ROUTE}/${data.account_id}/${PAYOUTS_ROUTE}/${data.id}`]);
  };

  getPayouts = (size: number, index: number): void => {
    const params: InternalListPayoutsRequestParams = {
      tilledAccount: this.queryMerchantId || this.accountId || AuthService.getCurrentAccountId(),
      include: 'transaction_count',
      includeConnectedAccounts: this.includeConnectedAccounts,
      paidAtGte: this.paidAtGte,
      paidAtLte: this.paidAtLte,
      q: this.queryPayoutId, // using q to search amount
      offset: size * index,
      limit: size,
      sort: 'paid_at:desc',
      status: this.queryStatus,
    };
    this._payoutsAppService.getAllPayouts(params);
    if (this.paidAtGte && this.paidAtLte) {
      this.reportDownloadEnabled = true;
      this.reportDownloadTooltip = 'Download Payouts csv with the applied filters';
    } else {
      this.reportDownloadEnabled = false;
      this.reportDownloadTooltip = 'Filter by date to enable 1-click download.';
    }
  };

  setTableType(): void {
    if (this.merchantDetails) {
      this.hideColumnKeys = ['merchant'];
    }
  }

  getViewModelsFromPayouts(payouts: InternalPayout[]): PayoutViewModel[] {
    const viewModels: PayoutViewModel[] = [];
    if (!payouts || payouts.length === 0) {
      const temp: PayoutViewModel = new PayoutViewModel();
      viewModels.push(temp);
      return viewModels;
    }
    for (const payout of payouts) {
      const temp: PayoutViewModel = new PayoutViewModel();
      temp.id = payout.id;
      temp.payout_id = payout.id;
      temp.currency = payout.currency;
      temp.net_settlement = payout.amount;
      temp.transaction_count = payout.transaction_count.toString();
      temp.status_chip_config = this._payoutStatusPipe.transform(payout);
      temp.paid_at = this._dateFormatPipe.transform(payout.paid_at);
      temp.account_id = payout.account_id;
      temp.merchant = payout.account_name;
      viewModels.push(temp);
    }

    return viewModels;
  }

  downloadPayoutReport(): void {
    let startDate, endDate: any;
    startDate = new Date(this.paidAtGte);
    endDate = new Date(this.paidAtLte);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 59);
    const currentTimeZone = new window.Intl.DateTimeFormat().resolvedOptions()
      .timeZone as ReportRunRequestParameters.TimeZoneEnum;
    this._fileDownloadService.create({
      accountId: this.accountId,
      reportRunParams: {
        type: InternalReportRun.TypeEnum.PAYOUTS_SUMMARY_2,
        parameters: {
          start_at: startDate,
          end_at: endDate,
          time_zone: currentTimeZone,
          account_id: this.queryMerchantId,
          additional_filters: this.query
            ? [
                {
                  key: ReportAdditionalFilter.KeyEnum.SEARCH_FILTER,
                  value: this.query ? this.query : null,
                },
              ]
            : null,
        },
      },
    });
  }
}

type PayoutViewModelKeys = keyof PayoutViewModel;

export class PayoutViewModel {
  id: string;
  account_id: string;

  @Column({
    order: 0,
    name: 'Payout Date',
    styling: 'min-width:190px;',
    dateTooltip: true,
  })
  paid_at: string;

  @Column({
    order: 1,
    name: 'Merchant',
    styling: 'padding-right: 2ch; width: 20ch; max-width: 20ch;',
  })
  merchant: string;

  @Column({
    order: 2,
    name: 'Payout ID',
    styling: 'min-width:210px;',
  })
  payout_id: string;

  // non display
  currency: InternalPayout.CurrencyEnum;

  @Column({
    order: 3,
    name: 'Status',
    isChip: true,
    chipConfig: 'status_chip_config',
    styling: 'min-width:100px;',
  })
  status: string;
  status_chip_config: TilledChipConfig;

  @Column({
    order: 4,
    name: 'Transaction Count',
    styling: 'min-width:100px;',
  })
  transaction_count: string;

  @Column({
    order: 5,
    name: 'Net Payout',
    isCurrency: true,
    styling: 'min-width:100px;',
  })
  net_settlement: number;

  @Column({
    order: 6,
    isAction: true,
  })
  action: string;
}
