<div class="hide-scrollbars max-h-[80vh] overflow-scroll p-6 {{ outterStyles }}">
  <div class="p-4 {{ styles }}">
    <div *ngIf="exitButton" class="mat-icon-container absolute right-5 top-5">
      <mat-icon
        data-test-id="form-card-exit-button"
        style="cursor: pointer"
        (click)="onButtonClick('exit')"
        svgIcon="mat_outline:close"
        class="text-tilled-primary"
      ></mat-icon>
    </div>
    <ng-container *ngIf="stepTitlesArray">
      <div class="mr-4 flex justify-around">
        <!-- We could probably use a custom directive here if we need -->
        <!-- an indeterminate amount of steps. This is simpler though -->
        <stepper-header
          [text1]="stepTitlesArray[0]"
          [text2]="stepTitlesArray[1]"
          [text3]="stepTitlesArray[2]"
          [text4]="stepTitlesArray[3]"
          [text5]="stepTitlesArray[4]"
          [stepNumber]="stepNumber"
          class="flex-shrink-0"
        ></stepper-header>
      </div>
    </ng-container>
    <!-- Logo -->
    <div *ngIf="logoUrl$ | async" class="w-36">
      <img class="logo" src="{{ logoUrl$ | async }}" />
    </div>
    <div *ngIf="titleText || secondaryText" [attr.data-test-id]="testIds?.headerTestId">
      <div *ngIf="titleText" class="my-2 flex flex-wrap justify-start" [attr.data-test-id]="testIds?.titleText">
        <tilled-form-heading>{{ titleText }}</tilled-form-heading>
      </div>
      <div *ngIf="secondaryText" class="mb-4 flex flex-wrap justify-start whitespace-pre-wrap">{{ secondaryText }}</div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="flex flex-col justify-center px-4 pb-4">
    <div *ngIf="displayAlert" class="flex max-w-100 justify-center self-center pb-4 pt-2">
      <fuse-alert
        [type]="'warn'"
        [appearance]="'outline'"
        [dismissible]="true"
        [dismissed]="false"
        [name]="'formCardAlertBox'"
      >
        <span fuseAlertTitle>{{ alertTitle }}</span>
        {{ alertMessage }}
      </fuse-alert>
    </div>
    <div *ngIf="!centerButton && !leftButton" class="flex-1">
      <div class="flex justify-end gap-x-3.5 pt-4" [ngClass]="maxWidth">
        <div *ngIf="backButton" class="w-full">
          <tilled-button
            [styles]="'flex-1 w-full'"
            (buttonClick)="onButtonClick('back')"
            [buttonText]="backButtonText"
            rounded="true"
            textColor="tilled-primary"
            bgColor="transparent"
            [whiteText]="false"
            [outlineColor]="backButtonBorder"
            [attr.data-test-id]="testIds?.backButton"
          ></tilled-button>
        </div>
        <div *ngIf="nextButton" class="w-full">
          <tilled-button
            [styles]="'flex-1 w-full'"
            (buttonClick)="onButtonClick('next')"
            [buttonText]="nextButtonText"
            rounded="true"
            [disabled]="nextDisabled"
            textColor="white"
            [whiteText]="true"
            bgColor="tilled-primary"
            [attr.data-test-id]="testIds?.nextButton"
          ></tilled-button>
        </div>
        <div *ngIf="submitButton" class="w-full">
          <tilled-button
            [styles]="'flex-1 w-full'"
            (buttonClick)="onButtonClick('submit')"
            [disabled]="submitDisabled"
            [buttonText]="submitText"
            textColor="white"
            [whiteText]="true"
            [bgColor]="submitButtonBg"
            [forceWhite]="merchantApp && !submitDisabled"
            rounded="true"
            [attr.data-test-id]="testIds?.submitButton"
            [ignoreTextCasing]="!capitalizeSubmitText"
          ></tilled-button>
        </div>
      </div>
    </div>
    <div *ngIf="!centerButton && leftButton" class="flex w-full justify-between gap-x-3.5 pt-4" [ngClass]="maxWidth">
      <div class="w-full">
        <tilled-button
          [styles]="'flex-1 w-full'"
          [buttonText]="leftButtonText"
          (buttonClick)="onButtonClick('left')"
          rounded="true"
          [attr.data-test-id]="testIds?.leftButton"
          bgColor="accent-500"
        ></tilled-button>
      </div>
      <div>
        <div *ngIf="backButton">
          <tilled-button
            [styles]="'flex-1 w-full'"
            (buttonClick)="onButtonClick('back')"
            [buttonText]="backButtonText"
            rounded="true"
            textColor="tilled-primary"
            bgColor="transparent"
            [whiteText]="false"
            [outlineColor]="backButtonBorder"
            [attr.data-test-id]="testIds?.backButton"
          ></tilled-button>
        </div>
        <div *ngIf="nextButton">
          <tilled-button
            [styles]="'flex-1 w-full'"
            (buttonClick)="onButtonClick('next')"
            [buttonText]="nextButtonText"
            rounded="true"
            [disabled]="nextDisabled"
            textColor="white"
            [whiteText]="true"
            bgColor="tilled-primary"
            [attr.data-test-id]="testIds?.nextButton"
          ></tilled-button>
        </div>

        <div *ngIf="submitButton">
          <tilled-button
            type="submit"
            [styles]="'flex-1 w-full'"
            (buttonClick)="onButtonClick('submit')"
            [disabled]="submitDisabled"
            [buttonText]="submitText"
            textColor="white"
            [whiteText]="true"
            [bgColor]="submitButtonBg"
            [forceWhite]="merchantApp && !submitDisabled"
            rounded="true"
            [attr.data-test-id]="testIds?.submitButton"
            [ignoreTextCasing]="!capitalizeSubmitText"
          ></tilled-button>
        </div>
      </div>
      <div *ngIf="centerButton" class="flex w-full justify-center">
        <tilled-button
          [styles]="'flex-1 w-full'"
          [buttonText]="centerButton"
          (buttonClick)="onButtonClick('center')"
          rounded="true"
          [attr.data-test-id]="testIds?.centerButton"
        ></tilled-button>
      </div>
    </div>
  </div>
</div>
