import { BooleanInput } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Router } from '@angular/router';
import { AppUser } from 'app/core/data/auth-types';
import { AuthService } from 'app/core/services/auth.service';
import { Subject, takeUntil } from 'rxjs';
import { LogoLoadedDirective } from '../../../shared/logo-loaded.directive';
import { TilledParagraphP3Component } from '../../../shared/tilled-text/tilled-paragraph/tilled-paragraph-p3.component';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user',
  standalone: true,
  imports: [
    MatButtonModule,
    MatMenuModule,
    LogoLoadedDirective,
    MatIconModule,
    MatDividerModule,
    CommonModule,
    TilledParagraphP3Component,
  ],
})
export class UserComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() showAvatar: boolean = true;
  @Input() whiteLabel: boolean = false;
  user: AppUser;
  onboarding: boolean = false;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  /**
   * Constructor
   */
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _authService: AuthService,
  ) {
    // check if current route contains `onboarding`
    if (this._router.url.includes('/onboarding')) {
      this.onboarding = true;
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to user changes
    this._authService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: AppUser) => {
      this.user = user;

      // Mark for check
      this._changeDetectorRef.markForCheck();
    });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the user status
   *
   * @param status
   */
  updateUserStatus(status: string): void {
    // Return if user is not available
    if (!this.user) {
      return;
    }

    // Update the user
    // this._userService.update({
    //     ...this.user,
    //     status
    // }).subscribe();
  }

  /**
   * Sign out
   */
  public signOut(): void {
    this._router.navigate(['/sign-out']);
  }

  public resetPassword(): void {
    this._router.navigate(['/forgot-password']);
  }
}
