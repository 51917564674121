import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInput, MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ComponentBase } from 'app/core/componentBase';
import { FilterService } from 'app/shared/tilled-filter/filter-service.component';
import { debounceTime, startWith } from 'rxjs';

@Component({
  selector: 'tilled-search',
  templateUrl: './tilled-search.component.html',
  styleUrls: ['./tilled-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatButtonModule,
    CommonModule,
    MatInput,
  ],
})
export class TilledSearchComponent extends ComponentBase implements OnInit {
  @Input() filterName: string;
  @Input() controlName: FormControl;
  @Input() placeholder?: string;
  @Input() errorMessage?: string;
  @Input() toolTip?: string;
  @Input() mask?: string;
  @Input() thousandSeparator?: string = '';
  @Input() styles?: string = '';
  @Input() icon?: string = 'heroicons_solid:search';
  @Input() textPrefix?: string;
  @Input() displayClear?: boolean = true;

  public showClear: boolean;
  constructor(private filterService: FilterService) {
    super();
    this.filterService.clearSelectedSearchValueEmitter.subscribe((name: string) => {
      if (this.filterName === name) {
        this.clearSearchText();
      }
    });
  }

  ngOnInit() {
    this.controlName.valueChanges.pipe(startWith(this.controlName.value)).subscribe((q) => {
      if (q && q.length > 0 && this.displayClear) {
        this.showClear = true;
      }
    });
    this.controlName.valueChanges.pipe(debounceTime(600)).subscribe((q) => {
      this.controlName.patchValue(q?.trim());
    });
  }

  clearSearchValue() {
    this.filterService.clearFilterButton(this.filterName);
  }

  clearSearchText() {
    this.showClear = false;
    this.controlName.patchValue(null);
  }
}
