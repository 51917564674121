import { Pipe, PipeTransform } from '@angular/core';

type TransactionFeeTypeEnum = 'flat_rate' | 'interchange';
const TransactionFeeTypeEnum = {
  FLAT_RATE: 'flat_rate' as TransactionFeeTypeEnum,
  INTERCHANGE: 'interchange' as TransactionFeeTypeEnum,
};

/**
 * Transforms api transaction fee type for display
 */
@Pipe({
  name: 'transactionFeeType',
  standalone: true,
})
export class TransactionFeeTypePipe implements PipeTransform {
  /**
   *
   * @param transactionFeeType
   * @returns
   */
  transform(transactionFeeType: TransactionFeeTypeEnum): string {
    if (transactionFeeType === TransactionFeeTypeEnum.FLAT_RATE) {
      return 'Flat-rate';
    } else if (transactionFeeType === TransactionFeeTypeEnum.INTERCHANGE) {
      return 'Interchange';
    }
    return '';
  }
}
