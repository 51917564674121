import { Injectable } from '@angular/core';
import { map } from 'rxjs';
import {
  InternalService,
  Markup,
  PricingTemplate,
  PricingTemplatesService,
} from '../../../../projects/tilled-api-client/src';
import { CardPricingTemplateDto, DebitPricingTemplateDto } from '../models/pricing-templates';

// TODO: Refactor service in line with patterns for RxJS described in the readme
// AlertService probably can't be added until this is done
@Injectable({
  providedIn: 'root',
})
export class PricingTemplateAppService {
  //use 100 for page limit due to number of pricing templates per partner
  pricingTemplateServicePageLimit = 100;

  constructor(
    private _pricingTemplateService: PricingTemplatesService,
    private _internalService: InternalService,
  ) {}

  public getPricingTemplates(accountId) {
    return this._pricingTemplateService
      .listPricingTemplates({
        tilledAccount: accountId,
        limit: this.pricingTemplateServicePageLimit,
        offset: 0,
      })
      .pipe(map((res) => res.items));
  }

  public internalUpdatePricingTemplate(pricingTemplateId: string, accountId: string, nickname: string) {
    return this._internalService
      .internalUpdatePricingTemplate({
        tilledAccount: accountId,
        id: pricingTemplateId,
        internalPricingTemplateUpdateParams: {
          name: nickname,
        },
      })
      .pipe((res) => res);
  }

  public internalGetAssociatedMerchantAccounts(pricingTemplateId: string, accountId: string) {
    return this._internalService
      .internalListPricingTemplateAccounts({
        tilledAccount: accountId,
        id: pricingTemplateId,
      })
      .pipe((res) => res);
  }
  public internalGetPricingTemplateById(pricingTemplateId: string, accountId: string) {
    return this._internalService
      .internalGetPricingTemplate({
        tilledAccount: accountId,
        id: pricingTemplateId,
      })
      .pipe(
        map((res) => {
          if (res.card || res.card_present) {
            return this.ingestCardPricingTemplates([res])[0];
          } else if (res.ach_debit || res.eft_debit) {
            return this.ingestDebitPricingTemplates([res])[0];
          }
        }),
      );
  }

  public internalArchivePricingTemplate(pricingTemplateId: string, accountId: string) {
    return this._internalService
      .internalArchivePricingTemplate({ id: pricingTemplateId, tilledAccount: accountId })
      .pipe(
        map((res) => {
          if (res.card) {
            return this.ingestCardPricingTemplates([res])[0];
          } else if (res.ach_debit || res.eft_debit) {
            return this.ingestDebitPricingTemplates([res])[0];
          }
        }),
      );
  }

  public internalGetCardPricingTemplates(
    accountId: string,
    merchantAccountId?: string,
    includePendingTemplates?: boolean,
  ) {
    return this._internalService
      .internalListPricingTemplates({
        tilledAccount: accountId,
        merchantAccountId,
        status: includePendingTemplates
          ? [PricingTemplate.StatusEnum.ACTIVE, PricingTemplate.StatusEnum.PENDING]
          : [PricingTemplate.StatusEnum.ACTIVE],
        type: PricingTemplate.PaymentMethodTypeEnum.CARD,
        limit: this.pricingTemplateServicePageLimit,
      })
      .pipe(map((res) => this.ingestCardPricingTemplates(res.items)));
  }

  public internalGetCardPresentPricingTemplates(
    accountId: string,
    merchantAccountId?: string,
    includePendingTemplates?: boolean,
  ) {
    return this._internalService
      .internalListPricingTemplates({
        tilledAccount: accountId,
        merchantAccountId,
        status: includePendingTemplates
          ? [PricingTemplate.StatusEnum.ACTIVE, PricingTemplate.StatusEnum.PENDING]
          : [PricingTemplate.StatusEnum.ACTIVE],
        type: PricingTemplate.PaymentMethodTypeEnum.CARD_PRESENT,
        limit: this.pricingTemplateServicePageLimit,
      })
      .pipe(map((res) => this.ingestCardPricingTemplates(res.items)));
  }

  public getAchDebitPricingTemplates(accountId: string, merchantAccountId?: string, includePendingTemplates?: boolean) {
    return this._internalService
      .internalListPricingTemplates({
        tilledAccount: accountId,
        merchantAccountId,
        status: includePendingTemplates
          ? [PricingTemplate.StatusEnum.ACTIVE, PricingTemplate.StatusEnum.PENDING]
          : [PricingTemplate.StatusEnum.ACTIVE],
        type: PricingTemplate.PaymentMethodTypeEnum.ACH_DEBIT,
        limit: this.pricingTemplateServicePageLimit,
      })
      .pipe(map((res) => this.ingestDebitPricingTemplates(res.items)));
  }

  public internalGetEftDebitPricingTemplates(
    accountId: string,
    merchantAccountId?: string,
    includePendingTemplates?: boolean,
  ) {
    return this._internalService
      .internalListPricingTemplates({
        tilledAccount: accountId,
        merchantAccountId,
        status: includePendingTemplates
          ? [PricingTemplate.StatusEnum.ACTIVE, PricingTemplate.StatusEnum.PENDING]
          : [PricingTemplate.StatusEnum.ACTIVE],
        type: PricingTemplate.PaymentMethodTypeEnum.EFT_DEBIT,
        limit: this.pricingTemplateServicePageLimit,
      })
      .pipe(map((res) => this.ingestDebitPricingTemplates(res.items)));
  }

  private ingestCardPricingTemplates(pricingTemplates: PricingTemplate[]): CardPricingTemplateDto[] {
    return pricingTemplates.map((pricingTemplate) => {
      const commonCardPricing = pricingTemplate.card ?? pricingTemplate.card_present;
      return {
        accountId: pricingTemplate.account_id,
        id: pricingTemplate.id,
        paymentMethodType: pricingTemplate.payment_method_type,
        currency: pricingTemplate.currency,
        nickname: pricingTemplate.name,
        discountFeeType: commonCardPricing?.transaction_fee_type,
        visaDiscountFee:
          commonCardPricing?.markups?.find((markup) => {
            return markup.card_type === Markup.CardTypeEnum.VISA;
          })?.rate || 0,
        amexDiscountFee:
          commonCardPricing?.markups?.find((markup) => {
            return markup.card_type === Markup.CardTypeEnum.AMEX;
          })?.rate || 0,
        transactionFee: commonCardPricing?.transaction_fee,
        chargebackFee: commonCardPricing?.chargeback_fee,
        retrievalFee: commonCardPricing?.retrieval_fee,
        reversalFee: commonCardPricing?.reversal_fee,
        cardUpdateFee: pricingTemplate.card?.card_update_fee,
        bankAccountChangeFee: commonCardPricing?.bank_account_change_fee,
        monthlyTerminalFee: pricingTemplate.card_present?.monthly_terminal_fee,
        // pass_through_fees is undocumented but will be present when applicable
        /* @ts-ignore */
        passThroughFees: commonCardPricing.pass_through_fees,
        accountFee: pricingTemplate.account_monthly_fee,
        minimumFee: pricingTemplate.account_monthly_minimum_fee,
        status: pricingTemplate.status,
        createdAt: pricingTemplate.created_at,
      };
    });
  }

  private ingestDebitPricingTemplates(pricingTemplates: PricingTemplate[]): DebitPricingTemplateDto[] {
    return pricingTemplates.map((pricingTemplate) => {
      const debitTemplate =
        pricingTemplate.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.ACH_DEBIT
          ? pricingTemplate.ach_debit
          : pricingTemplate.eft_debit;
      return {
        accountId: pricingTemplate.account_id,
        id: pricingTemplate.id,
        paymentMethodType: pricingTemplate.payment_method_type,
        currency: pricingTemplate.currency,
        nickname: pricingTemplate.name,
        feeType: debitTemplate.transaction_fee_type,
        transactionFee: debitTemplate.transaction_fee,
        returnFee: debitTemplate.return_fee,
        accountFee: pricingTemplate.account_monthly_fee,
        minimumFee: pricingTemplate.account_monthly_minimum_fee,
        status: pricingTemplate.status,
        createdAt: pricingTemplate.created_at,
      };
    });
  }
}
