import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ComponentBase } from 'app/core/componentBase';
import { BrandingService } from 'app/core/services/branding.service';
import { TilledHeadingH4Component } from 'app/shared/tilled-text';
import { Observable } from 'rxjs';

@Component({
  selector: 'docusign-loading',
  templateUrl: './docusign-loading.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TilledHeadingH4Component],
})
export class DocusignLoadingComponent extends ComponentBase implements OnInit, OnDestroy {
  public isWhiteLabel$: Observable<boolean>;
  public wlLoadingLogo: string;
  public tilledLoadingLogo: string;

  constructor(private _brandingService: BrandingService) {
    super();
    this.isWhiteLabel$ = this._brandingService.isWhiteLabel$;
    this.wlLoadingLogo = '/assets/images/docusign_wl.png';
    this.tilledLoadingLogo = '/assets/images/docusign_default.png';
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {}
}
