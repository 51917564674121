import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AsyncPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { ComponentBase } from 'app/core/componentBase';
import { SelectorTypes } from 'app/core/data/selector-types';
import { MerchantAppService } from 'app/core/services/merchant-app.service';
import { _compareTwoStrings } from 'app/shared/utils/compare-two-strings';
import { isEmail } from 'app/shared/validators/email.validator';
import { isPhoneNumber } from 'app/shared/validators/phone.validator';
import { isValidUrl } from 'app/shared/validators/url.validator';
import { cloneDeep } from 'lodash';
import { Observable, Subscription, takeUntil } from 'rxjs';
import { OnboardingApplication } from '../../../../../projects/tilled-api-client/src';
import { MerchantAppCardComponent } from '../../cards/merchant-application/merchant-app-card/merchant-app-card.component';
import { TilledInputComponent } from '../../form-fields/tilled-input/tilled-input.component';

@Component({
  selector: 'business-contact-step',
  templateUrl: './business-contact-step.component.html',
  styleUrls: ['./business-contact-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MerchantAppCardComponent, FormsModule, ReactiveFormsModule, TilledInputComponent, AsyncPipe],
})
export class BusinessContactStepComponent extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  @Input() forConsole: boolean = false;
  @Input() disabled$: Observable<boolean> = null;
  @Input() saveApp$: Observable<string> = null;
  @Input() checkUnsavedApp$: Observable<string> = null;
  @Input() resetApp$: Observable<boolean> = null;
  @Input() stepNumber: number;
  @Output() markAppUnsaved: EventEmitter<boolean> = new EventEmitter<boolean>();
  public businessContactForm: FormGroup;
  public merchantApp: OnboardingApplication;
  public selectorTypes: SelectorTypes = new SelectorTypes();
  public phoneCodeMap: { label: string; value: string }[] = Array.from(SelectorTypes.CountryToPhoneCode).map(
    ([label, value]) => ({ label, value }),
  );
  public selectedPhoneCode: string;
  public supportPhone: string = '';
  private subscriptions: Subscription[] = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _merchantAppService: MerchantAppService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.businessContactForm = this._formBuilder.group({
      phone: new FormControl<string | null>(this.merchantApp?.legal_entity?.support_phone || null, [
        isPhoneNumber('US'),
      ]),
      phoneCode: new FormControl<string | null>(this.selectedPhoneCode || null),
      email: new FormControl<string | null>(this.merchantApp?.legal_entity?.support_email || null, [isEmail()]),
      website: new FormControl<string | null>(this.merchantApp?.legal_entity?.website || null, [
        Validators.pattern(/(?:\w+\.)+\w+(?:\/\S*)?/i),
        isValidUrl({ require_protocol: false }),
      ]),
    });

    this._merchantAppService.merchantApplicationResponse$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((application) => {
        this.merchantApp = cloneDeep(application);
        this.resetApplication();
      });

    if (this.disabled$) {
      this.subscriptions.push(
        this.disabled$.subscribe((isDisabled) => {
          if (isDisabled) {
            this.businessContactForm.disable();
          } else {
            this.businessContactForm.enable();
          }
        }),
      );
    }

    if (this.forConsole) {
      if (this.saveApp$) {
        this.subscriptions.push(
          this.saveApp$.subscribe((save) => {
            if (save) {
              this.onContinueClicked(save);
            }
          }),
        );
      }
      if (this.checkUnsavedApp$) {
        this.subscriptions.push(
          this.checkUnsavedApp$.subscribe((check) => {
            if (check) {
              this.markAppUnsaved.emit(this.isAppUnsaved());
            }
          }),
        );
      }
      if (this.resetApp$) {
        this.subscriptions.push(
          this.resetApp$.subscribe((reset) => {
            if (reset) {
              this.resetApplication();
            }
          }),
        );
      }
    }
    this.selectedPhoneCode = this.selectedPhoneCode ? this.selectedPhoneCode : this.phoneCodeMap[0].value;
  }

  ngAfterViewInit(): void {
    this.scrollToTop();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onPhoneCodeChanged(event: MatSelectChange): void {
    this.selectedPhoneCode = event.value;
  }

  onContinueClicked(accountId?: string): void {
    this.businessContactForm.markAllAsTouched();
    if (this.businessContactForm.invalid) {
      setTimeout(() => {
        this.scrollToError();
      }, 0);
      return;
    }
    // // this is so that the decimal value is excluded from the step number, since this is the last sub-step.
    // let increment = round(this.stepNumber + 1, 1).toFixed(0);

    // TODO: ADD CHECK FOR MULTIPLE OWNERS AND THEN IF NOT ROUND TO WHOLE NUMBER, ELSE USE THE SUB-INCREMENT
    // Needed to increment by 0.1 to avoid issues with the step number being a float.
    let subIncrement = (this.stepNumber + 0.1).toFixed(1);

    // ngx-mask sets certain empty values (phone numbers at least) to empty string, where api expects null
    // eslint-disable-next-line guard-for-in
    for (const field in this.businessContactForm.controls) {
      const control = this.businessContactForm.get(field);
      if (control.value === '') {
        control.setValue(null);
      }
    }
    // if the phone number is not prefixed with a country code, prefix it with the selected country code (US by default)
    if (this.businessContactForm.value.phone && !this.businessContactForm.value.phone.startsWith('+')) {
      this.businessContactForm.patchValue({
        phone: `${this.selectedPhoneCode}${this.businessContactForm.value.phone}`,
      });
    }
    this.merchantApp.legal_entity.support_phone = this.businessContactForm.value.phone;
    this.merchantApp.legal_entity.support_email = this.businessContactForm.value.email;
    // prefix the website with `https://` if it doesn't already have it (New URL fields shows a `https://` prefix)
    if (
      this.businessContactForm.value.website &&
      !this.businessContactForm.value.website.startsWith('https://' || 'http://')
    ) {
      this.businessContactForm.patchValue({
        website: `https://${this.businessContactForm.value.website}`,
      });
    }
    this.merchantApp.legal_entity.website = this.businessContactForm.value.website;

    this._merchantAppService.updateMerchantApplication(this.merchantApp, parseFloat(subIncrement), accountId);
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.mat-form-field-invalid');
    this.scrollTo(firstElementWithError);
  }

  scrollToTop(): void {
    const element = document.querySelector('.top-of-form');
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'end' });
    }
  }

  private isAppUnsaved(): boolean {
    return !(
      _compareTwoStrings(this.merchantApp.legal_entity?.support_phone, this.businessContactForm.value.phone) &&
      _compareTwoStrings(this.merchantApp.legal_entity?.support_email, this.businessContactForm.value.email) &&
      _compareTwoStrings(this.merchantApp.legal_entity?.website, this.businessContactForm.value.website)
    );
  }

  private resetApplication(): void {
    const ble = this.merchantApp?.legal_entity;

    this.businessContactForm.controls['phone'].setValue(ble?.support_phone);
    this.businessContactForm.controls['email'].setValue(ble?.support_email);
    this.businessContactForm.controls['website'].setValue(ble?.website);
  }
}
