import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlContainer, FormControl, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FilterService } from 'app/shared/tilled-filter/filter-service.component';
import { SentenceCasePipe } from '../../core/pipes/sentence-case.pipe';
import { TilledButtonComponent } from '../buttons/tilled-button.component';

@Component({
  selector: 'tilled-select',
  templateUrl: './tilled-select.component.html',
  styleUrls: ['./tilled-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatOptionModule,
    TilledButtonComponent,
    MatIconModule,
    MatTooltipModule,
    MatButtonModule,
    SentenceCasePipe,
    CommonModule,
  ],
})
export class TilledSelectComponent implements OnInit {
  @ViewChild('tilledSelect') select!: MatSelect;
  @Input() filterName: string;
  @Input() multiple? = true;
  @Input() options: { value: string; label: string }[] = [];
  @Input() controlName?: FormControl;
  @Input() groupName?: string;
  @Input() appearance?: 'fill' | 'outline' = 'fill';
  @Input() toolTip: string = null;
  @Input() placeholder?: string = '';
  @Input() sentenceCasePlaceholder?: boolean = true;
  @Input() templateRef?: TemplateRef<any>;
  @Input() classOverride?: string;
  @Input() matSuffix?: boolean;
  @Input() matPrefix?: boolean;
  @Input() errors?: boolean = false;
  @Input() hint?: string = null;
  @Input() errorMessage?: string;
  @Input() readonly?: boolean;
  @Input() suffixReadonly?: boolean;
  @Input() disabledOptions?: { value: string; label: string }[] = [];
  @Input() merchantApp?: boolean;
  @Output() closed? = new EventEmitter<void>();
  @Output() selectionChange? = new EventEmitter<void>();
  @Output() opened? = new EventEmitter<void>();
  @Output() panelOpen = new EventEmitter<void>();

  public windowWidth: any;
  public isOpened: boolean = false;

  constructor(private filterService: FilterService) {
    this.filterService.clearSelectedSelectValuesEmitter.subscribe((name: string) => {
      if (this.filterName === name) {
        this.clearSelection();
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.windowWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.windowWidth = window.innerWidth;
    if (this.merchantApp) {
      this.merchantApp = document.querySelector('app-account-details') ? false : true;
    }
  }

  clearSelection(): void {
    this.controlName.reset([]);
  }

  closeDropdown(): void {
    this.select.close();
    this.isOpened = false;
  }

  openDropdown(): void {
    this.select.open();
    this.isOpened = true;
  }
}
