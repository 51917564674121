import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, Data, RouterOutlet } from '@angular/router';
import {
  FuseNavigationItem,
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseConfigService } from '@fuse/services/config';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { InitialData } from 'app/app.types';
import { AppUser } from 'app/core/data/auth-types';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { AlertService } from 'app/core/services/alert.service';
import { AuthService } from 'app/core/services/auth.service';
import { BrandingService } from 'app/core/services/branding.service';
import { DisputesAppService } from 'app/core/services/disputes.app.service';
import { Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { Account, AccountCapability, InternalAccount } from '../../../../../../projects/tilled-api-client/src';
import { FuseLoadingBarComponent } from '../../../../../@fuse/components/loading-bar/loading-bar.component';
import { FileDownloadComponent } from '../../../../shared/file-download/file-download.component';
import { LogoLoadedDirective } from '../../../../shared/logo-loaded.directive';
import { MultiAccountSelectorComponent } from '../../../../shared/multi-account-selector/multi-account-selector.component';
import { TilledAlertComponent } from '../../../../shared/tilled-alert/tilled-alert.component';
import { HelpCenterComponent } from '../../../common/help-center/help-center.component';
import { NotificationsComponent } from '../../../common/notifications/notifications.component';
import { UserComponent } from '../../../common/user/user.component';

const DISPUTE_BADGE_REFRESH_MILLIS = 1000 * 60 * 5; // 5 mins

@Component({
  selector: 'futuristic-layout',
  templateUrl: './futuristic.component.html',
  styleUrls: ['./futuristic.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FuseLoadingBarComponent,
    TilledAlertComponent,
    FileDownloadComponent,
    FuseVerticalNavigationComponent,
    LogoLoadedDirective,
    MatButtonModule,
    MatIconModule,
    MultiAccountSelectorComponent,
    NotificationsComponent,
    UserComponent,
    RouterOutlet,
    HelpCenterComponent,
    CommonModule,
  ],
})
export class FuturisticLayoutComponent implements OnInit, OnDestroy {
  public navItems: FuseNavigationItem[];
  public isScreenSmall: boolean;
  public data: InitialData;
  public user: AppUser;
  public logoUrl$: Observable<string>;
  public darkLogoUrl$: Observable<string>;
  public companyName$: Observable<string>;
  public accountName$: Observable<string>;
  public copyrightName$: Observable<string>;
  public isWhiteLabel$: Observable<boolean>;
  public useDarkLogo$: Observable<boolean>;
  public whiteText: boolean = true;
  public account: InternalAccount;
  public companyLogo: string;

  public alert$: Observable<TilledAlert>;
  public disputeBadgeRefreshIntervalId: NodeJS.Timeout;
  private disputeAlertCount: number = 0;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  /**
   * Constructor
   */
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _fuseConfigService: FuseConfigService,
    private _authService: AuthService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    private _brandingService: BrandingService,
    private _alertService: AlertService,
    private _disputesService: DisputesAppService,
  ) {
    this.logoUrl$ = this._brandingService.logoUrl$;
    this.darkLogoUrl$ = this._brandingService.darkLogoUrl$;
    this.isWhiteLabel$ = this._brandingService.isWhiteLabel$;
    this.useDarkLogo$ = this._brandingService.useDarkLogo$;
    this.companyName$ = this._brandingService.companyName$;
    this.accountName$ = this._brandingService.accountName$;
    this._brandingService.primaryColor$.subscribe((color) => {
      if (color) {
        this.whiteText = this._brandingService.determineWhiteTextColorFromBgColor(color);
      }
    });
    this.alert$ = this._alertService.newAlert$;
    this._brandingService.isWhiteLabel$.subscribe((isWhiteLabel) => {
      if (isWhiteLabel) {
        this._brandingService.darkLogoUrl$.subscribe((darkLogoUrl: string) => {
          this.companyLogo = darkLogoUrl;
        });
      } else {
        this.companyLogo = '/assets/images/logos/tilled.svg';
      }
    });
    this.copyrightName$ = this.companyName$.pipe(
      switchMap((companyName) => {
        if (companyName === 'Tilled') {
          return this.accountName$;
        }
        return this.companyName$;
      }),
    );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to the resolved route data
    this._activatedRoute.data.pipe(takeUntil(this._unsubscribeAll)).subscribe((data: Data) => {
      this.data = data.initialData;
    });

    // Subscribe to the user service
    this._authService.user$.pipe(takeUntil(this._unsubscribeAll)).subscribe((user: AppUser) => {
      this.user = user;
    });

    this._authService.account$.pipe(takeUntil(this._unsubscribeAll)).subscribe((account: InternalAccount) => {
      // In case we switch from an Active merchant to an InProgress merchant, we need to reload
      // so that the ApplicationStatusGuard will get triggered to redirect to the appropriate
      // /onboarding route.
      if (
        account.type === 'merchant' &&
        !account.capabilities.find((cap) =>
          [AccountCapability.StatusEnum.ACTIVE, AccountCapability.StatusEnum.DISABLED].includes(cap.status),
        )
      ) {
        window.location.reload();
        return;
      }

      this.account = account;
      this.onAccountChange();
    });

    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('md');
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    clearInterval(this.disputeBadgeRefreshIntervalId);
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }

  private onAccountChange(): void {
    if (!this.account) {
      return;
    }

    this.data.navigation.futuristic.forEach((parentNavItem) => {
      // If this is a merchant and the menu item is for partners only
      // then remove the nav item
      if (this.account.type === Account.TypeEnum.MERCHANT) {
        this.data.navigation.futuristic = this.data.navigation.futuristic.filter((item) => !item.isPartnerScope);
        // If there are child nav items for partners only, remove them as well
        if (parentNavItem.children) {
          parentNavItem.children.forEach(() => {
            parentNavItem.children = parentNavItem.children.filter((item) => !item.isPartnerScope);
          });
        }
      }

      if (this.account.type === Account.TypeEnum.PARTNER) {
        // If this is a partner and the menu item is for merchants only
        // then remove the nav item
        this.data.navigation.futuristic = this.data.navigation.futuristic.filter((item) => !item.isMerchantScope);
        // If there are child nav items for merchants only, remove them as well
        if (parentNavItem.children) {
          parentNavItem.children.forEach(() => {
            parentNavItem.children = parentNavItem.children.filter((item) => !item.isMerchantScope);
          });
        }
      }

      // Set the nav items for this user/account
      this.navItems = this.data.navigation.futuristic;
    });

    // If dispute nav item present, configure badge updates
    const disputeItemIndex = this.navItems.findIndex((n) => n.id === 'disputes');
    if (disputeItemIndex >= 0) {
      this._disputesService.disputesAlertCount$.pipe(takeUntil(this._unsubscribeAll)).subscribe((count: number) => {
        if (count !== this.disputeAlertCount) {
          this.disputeAlertCount = count;
          clearInterval(this.disputeBadgeRefreshIntervalId);
          if (count === 0) {
            this.navItems[disputeItemIndex] = {
              ...this.navItems[disputeItemIndex],
              badge: {},
            };
          } else {
            this.navItems[disputeItemIndex] = {
              ...this.navItems[disputeItemIndex],
              badge: {
                title: `${count > 99 ? '99+' : count}`,
                classes: 'w-6 h-6 bg-primary text-tilled-primary rounded-full',
              },
            };
            this.disputeBadgeRefreshIntervalId = setInterval(
              () => this._disputesService.getDisputesAlertCount(this.account.id),
              DISPUTE_BADGE_REFRESH_MILLIS,
            );
          }
          this.navItems = [...this.navItems];
        }
      });
      this._disputesService.getDisputesAlertCount(this.account.id);
    }
  }
}
