import { Component, ViewEncapsulation } from '@angular/core';
import { BrandingService } from 'app/core/services/branding.service';
import { TilledButtonTextGenericComponent } from './tilled-button-generic.component';

@Component({
  selector: 'tilled-button-b2',
  templateUrl: '../tilled-text-generic.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
})
export class TilledButtonB2Component extends TilledButtonTextGenericComponent {
  public styles: string;
  constructor(_brandingService: BrandingService) {
    super(_brandingService);
    this.internalStyles = 'text-button2 flex items-center';
  }
}
