import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { CardPricingTemplateDto } from 'app/core/models/pricing-templates';
import { MinorUnitsToCurrencyPipe } from 'app/core/pipes/minor-units-to-currency.pipe';
import { TemplateStatusChipConfigPipe } from 'app/core/pipes/template-status-chip-config.pipe';
import { TransactionFeeTypePipe } from 'app/core/pipes/transaction-fee-type-pipe';
import { EditablePricingTemplateNicknameComponent } from 'app/shared/pricing-templates/editable-pricing-template-nickname/editable-pricing-template-nickname.component';
import { TilledChipComponent } from 'app/shared/tilled-chip/tilled-chip.component';
import {
  TilledHeadingH4Component,
  TilledLabelL1Component,
  TilledParagraphP3Component,
  TilledParagraphP4Component,
} from 'app/shared/tilled-text';
import { GenericPricingTemplateListItem } from '../generic-pricing-template-list-item';
@Component({
  selector: 'card-present-pricing-template-list-item',
  templateUrl: './card-present-pricing-template-list-item.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    TilledHeadingH4Component,
    EditablePricingTemplateNicknameComponent,
    TilledChipComponent,
    TilledParagraphP4Component,
    TilledParagraphP3Component,
    TilledLabelL1Component,
    MinorUnitsToCurrencyPipe,
    TransactionFeeTypePipe,
    TemplateStatusChipConfigPipe,
    CommonModule,
  ],
  providers: [],
})
export class CardPresentPricingTemplateListItemComponent extends GenericPricingTemplateListItem implements OnInit {
  @Input() detailView: boolean = false;
  @Input() canClick: boolean = true;
  @Input() canEdit: boolean = false;
  @Input() template: CardPricingTemplateDto;
  @Output() cardClick: EventEmitter<number> = new EventEmitter<number>();

  constructor(private router: Router) {
    super(router);
  }

  ngOnInit() {}

  onButtonClick() {}
}
