/* eslint-disable @typescript-eslint/member-ordering */
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { Router } from '@angular/router';
import { FuseAlertService } from '@fuse/components/alert';
import { ComponentBase } from 'app/core/componentBase';
import {
  ACH_DEBIT_TERMS_LINK_NAME,
  CARD_TERMS_LINK_NAME,
  PORTAL_TERMS_LINK_NAME,
  PRIVACY_POLICY_LINK_NAME,
} from 'app/core/constants';
import { MerchantApplicationStepType } from 'app/core/data/onboarding-types';
import { ApplicationStep, IsStepComplete } from 'app/core/models/application-step';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { AccountAppService } from 'app/core/services/account.app.service';
import { AlertService } from 'app/core/services/alert.service';
import { AuthService } from 'app/core/services/auth.service';
import { MerchantAppService } from 'app/core/services/merchant-app.service';
import { UsersAppService } from 'app/core/services/users.app.service';
import { TilledButtonComponent } from 'app/shared/buttons/tilled-button.component';
import { CardPresentPricingCardComponent } from 'app/shared/cards/card-present-pricing-card/card-present-pricing-card.component';
import {
  CardPresentPricingTemplateViewModel,
  CardPricingTemplateViewModel,
  DebitPricingTemplateViewModel,
} from 'app/shared/connected-account/connected-account-dialog/connected-account-dialog.component';
import { DocusignLoadingComponent } from 'app/shared/merchant-app-steps/docusign-loading/docusign-loading.component';
import { TilledParagraphP3Component } from 'app/shared/tilled-text';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { BehaviorSubject, Observable, Subject, Subscription, distinctUntilChanged, takeUntil } from 'rxjs';
import {
  InternalAccount,
  InternalService,
  OnboardingApplication,
  PAArticlesOfIncorporation,
  PatriotActDetails,
  PricingTemplate,
  PrincipalCreateParams,
  SigningUrl,
  User,
  UserInvitation,
} from '../../../../../projects/tilled-api-client/src';
import { FuseAlertComponent } from '../../../../@fuse/components/alert/alert.component';
import { CardPricingCardComponent } from '../../cards/card-pricing-card/card-pricing-card.component';
import { DebitPricingCardComponent } from '../../cards/debit-pricing-card/debit-pricing-card.component';
import { MerchantAppAlertComponent } from '../../cards/merchant-application/merchant-app-alert/merchant-app-alert.component';
import { MerchantAppCardComponent } from '../../cards/merchant-application/merchant-app-card/merchant-app-card.component';
import { TilledHeadingH4Component } from '../../tilled-text/tilled-heading/tilled-heading-h4.component';
import { TilledParagraphP1Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p1.component';

@Component({
  selector: 'submit-application-step',
  templateUrl: './submit-application-step.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MerchantAppCardComponent,
    TilledHeadingH4Component,
    MerchantAppAlertComponent,
    CardPricingCardComponent,
    CardPresentPricingCardComponent,
    DebitPricingCardComponent,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    TilledParagraphP1Component,
    TilledParagraphP3Component,
    TilledButtonComponent,
    MatFormFieldModule,
    FuseAlertComponent,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    DocusignLoadingComponent,
  ],
})
export class SubmitApplicationStepComponent extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() forConsole: boolean = false;
  @Input() disabled$: Observable<boolean> = null;
  @Input() merchantAccountId$: Observable<string> = null;
  @Input() stepNumber: number;
  @Input() allSteps: ApplicationStep[];
  @Output() inviteApplicant: EventEmitter<{ applicantInvite: boolean; emailExists: boolean; applicant: any }> =
    new EventEmitter<{
      applicantInvite: boolean;
      emailExists: boolean;
      applicant: any;
    }>();

  public submitApplicationForm: FormGroup;
  public merchantApp: OnboardingApplication;
  public cardPricingTemplate: CardPricingTemplateViewModel;
  public cardPresentPricingTemplate: CardPresentPricingTemplateViewModel;
  public debitPricingTemplate: DebitPricingTemplateViewModel;
  public hasMultiple: boolean;
  private _cardPricingTemplate$ = new BehaviorSubject<CardPricingTemplateViewModel>(null);
  public cardPricingTemplate$ = this._cardPricingTemplate$.asObservable();
  private _cardPresentPricingTemplate$ = new BehaviorSubject<CardPresentPricingTemplateViewModel>(null);
  public cardPresentPricingTemplate$ = this._cardPresentPricingTemplate$.asObservable();
  private _debitPricingTemplate$ = new BehaviorSubject<DebitPricingTemplateViewModel>(null);
  public debitPricingTemplate$ = this._debitPricingTemplate$.asObservable();
  public disableButton: boolean;

  public verifyYourBusinessComplete: IsStepComplete;
  public businessTypeComplete: IsStepComplete;
  public businessDetailsComplete: IsStepComplete;
  public businessContactComplete: IsStepComplete;
  public businessRepresentativesComplete: IsStepComplete;
  public businessOwnersComplete: IsStepComplete;
  public productsAndServicesComplete: IsStepComplete;
  public paymentAcceptanceComplete: IsStepComplete;
  public processingVolumeComplete: IsStepComplete;
  public bankAccountComplete: IsStepComplete;
  public businessDocumentsComplete: IsStepComplete;
  public pricingAndTermsComplete: IsStepComplete;
  public canSubmitApplication: boolean;
  public termsAgreed: boolean;

  public merchantTermsLink: string;
  public portalTermsLink: string;
  public privacyPolicyLink: string;
  public achDebitTermsLink: string;

  private submittedApplicationErrors$: Observable<any>;
  private submittedApplicationResponse$: Observable<SigningUrl>;
  private _displayAlert$ = new Subject<boolean>();
  public displayAlert$ = this._displayAlert$.asObservable();
  public alertMessage: string;
  private accountId: string;
  private _submittingApp$ = new Subject<boolean>();
  public submittingApp$ = this._submittingApp$.asObservable();
  private subscriptions: Subscription[] = [];

  private supportEmail: string;

  public businessTypeSubStep = MerchantApplicationStepType.BUSINESS_TYPE_SUB_STEP;
  public businessDetailsSubStep = MerchantApplicationStepType.BUSINESS_DETAILS_SUB_STEP;
  public businessContactSubStep = MerchantApplicationStepType.CONTACT_INFORMATION_SUB_STEP;
  public representativesSubStep = MerchantApplicationStepType.BUSINESS_REPRESENTATIVE_SUB_STEP;
  public businessOwnersSubStep = MerchantApplicationStepType.BUSINESS_OWNERS_SUB_STEP;
  public productsAndServicesSubStep = MerchantApplicationStepType.PRODUCTS_AND_SERVICES_SUB_STEP;
  public paymentAcceptanceSubStep = MerchantApplicationStepType.PAYMENT_ACCEPTANCE_SUB_STEP;
  public processingVolumesSubStep = MerchantApplicationStepType.PROCESSING_VOLUMES_SUB_STEP;
  public bankAccountStep = MerchantApplicationStepType.BANK_ACCOUNT;
  public businessDocumentsStep = MerchantApplicationStepType.BUSINESS_DOCUMENTS;

  public hasTsysProvider = false;
  public applicant: PrincipalCreateParams;
  public isPrimaryApplicant = false;
  public applicantExists = false;
  public users$ = this._usersAppService.users$;
  public users: User[] = [];
  public invitations$ = this._usersAppService.userInvitations$;
  public invitations: UserInvitation[] = [];
  public loading$ = new BehaviorSubject<boolean>(true);
  public merchantUsersAndInvitations: any = [];

  constructor(
    private _formBuilder: FormBuilder,
    private _merchantAppService: MerchantAppService,
    private _router: Router,
    private _fuseAlertService: FuseAlertService,
    private _alertService: AlertService,
    private _accountAppService: AccountAppService,
    private _changeDetectorRef: ChangeDetectorRef,
    private _authService: AuthService,
    private _usersAppService: UsersAppService,
    private _internalService: InternalService,
  ) {
    super();
  }

  ngOnInit(): void {
    this._merchantAppService.merchantApplicationResponse$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((application) => {
        this.merchantApp = cloneDeep(application);
        this.handleUpdatedApplication();
      });

    if (this.disabled$) {
      this.disabled$.subscribe((isDisabled) => {
        if (isDisabled) {
          this.submitApplicationForm.disable();
          this.disableButton = true;
        } else {
          this.submitApplicationForm.enable();
          this.disableButton = false;
        }
      });
    }

    this._merchantAppService.hasTsysProvider$
      .pipe(takeUntil(this._unsubscribeAll), distinctUntilChanged())
      .subscribe((hasTsys) => {
        this.hasTsysProvider = hasTsys;
        if (this.hasTsysProvider) {
          this.getPrimaryApplicant();
        }
      });

    this._merchantAppService.updateProviderData();

    this.submittedApplicationResponse$ = this._merchantAppService.submittedApplicationResponse$;
    this.submittedApplicationResponse$.subscribe({
      next: (docusignUrl) => {
        this._submittingApp$.next(false);
        if (this.forConsole) {
          const message: TilledAlert = {
            message: `Application for ${this.merchantApp.legal_entity?.legal_name} was submitted successfully`,
            title: 'Application submitted',
            type: 'success',
            timer: 8000,
          };
          this._alertService.showAlert(message);
          this.goToStep(this.businessDetailsSubStep);
          this._accountAppService.getConnectedAccountById(this.accountId);
        } else {
          if (docusignUrl?.signingUrl) {
            window.location.href = docusignUrl.signingUrl;
          } else {
            this._router.navigate(['/onboarding/submitted']);
          }
        }
      },
    });

    this.submittedApplicationErrors$ = this._merchantAppService.submittedApplicationErrors$;
    this.submittedApplicationErrors$.subscribe({
      next: (errResponse) => {
        this._submittingApp$.next(false);
        if (errResponse?.error?.message) {
          const text = errResponse.error.message.split('\n');
          this.alertMessage = '\u2022' + text.splice(0, text.length - 1).join('\n\u2022');

          if (this.alertMessage != '\u2022') {
            this._displayAlert$.next(true);
            this._fuseAlertService.show('merchantAppAlertBox');
          }

          if (this.alertMessage === '\u2022') {
            const message: TilledAlert = {
              message: `There was an error submitting your merchant application, please contact ${this.supportEmail} for next steps`,
              title: 'Submission error',
              type: 'error',
            };
            this._alertService.showAlert(message);
          }
        }
      },
    });

    this._authService.account$.pipe(takeUntil(this._unsubscribeAll)).subscribe({
      next: async (account) => {
        this._internalService.internalGetAccountSettingsBranding({ tilledAccount: account.id }).subscribe((asb) => {
          // defaults
          this.supportEmail = 'support@tilled.com';

          // customize for white labeled merchant
          if (
            asb &&
            asb.is_white_label &&
            (account?.type === InternalAccount.TypeEnum.MERCHANT || account?.type === InternalAccount.TypeEnum.PARTNER)
          ) {
            if (asb.support_email) {
              this.supportEmail = asb.support_email;
            }
          }
        });
      },
    });

    if (this.merchantAccountId$) {
      this.merchantAccountId$.subscribe({
        next: (accountId) => {
          this.accountId = accountId;
        },
      });
    }
  }

  ngAfterViewInit(): void {
    this.scrollToTop();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  onAgreeTermsClicked(checkbox: MatCheckboxChange): void {
    this.termsAgreed = checkbox.checked;
  }

  onContinueClicked(event: string): void {
    if (this.submitApplicationForm.invalid || !this.canSubmitApplication) {
      return;
    }

    // default values for paysafe apps (data not required through UI)
    this._merchantAppService.hasTsysProvider$.subscribe((hasTsysProvider) => {
      if (!hasTsysProvider) {
        this.merchantApp.legal_entity.patriot_act_details = {} as PatriotActDetails;
        this.merchantApp.legal_entity.patriot_act_details.articles_of_incorporation = {
          state: PAArticlesOfIncorporation.StateEnum.AK,
          issued_at: moment('01-01-2000', 'MM-DD-YYYY').toISOString(),
          //document_id: 'not_required',
        };
        this.merchantApp.legal_entity.receive_1099k_via_email = false;
      }
    });
    this._merchantAppService.updateProviderData();
    this.merchantApp.legal_entity.previous_bankruptcy = false;

    this.merchantApp.tos_acceptance = this.submitApplicationForm.value.acceptedTerms;

    this._submittingApp$.next(true);

    this._merchantAppService.updateAndSubmitMerchantApplication(this.merchantApp, this.accountId);
  }

  goToStep(stepType: MerchantApplicationStepType): void {
    let newStep = this.allSteps.filter((step) => step.type === stepType)[0];
    this._merchantAppService.updateCurrentStep(newStep.order);
  }

  getTermsLinks(): void {
    this.merchantTermsLink = this.merchantApp.terms_and_conditions_links.find(
      (l) => l.name === CARD_TERMS_LINK_NAME,
    )?.link;
    this.portalTermsLink = this.merchantApp.terms_and_conditions_links.find(
      (l) => l.name === PORTAL_TERMS_LINK_NAME,
    )?.link;
    this.privacyPolicyLink = this.merchantApp.terms_and_conditions_links.find(
      (l) => l.name === PRIVACY_POLICY_LINK_NAME,
    )?.link;
    this.achDebitTermsLink = this.merchantApp.terms_and_conditions_links.find(
      (l) => l.name === ACH_DEBIT_TERMS_LINK_NAME,
    )?.link;
  }

  private getUsersAndInvites(): void {
    const merchantAcctId = AuthService.getCurrentAccountId();
    const params = { tilledAccount: merchantAcctId };
    this._usersAppService.getAllUsers(params);
    if (this._authService.isScopeAble('user_invitations:read')) {
      this._usersAppService.getAllUserInvitations(params);
    }

    this.users$.subscribe({
      next: (usersData) => {
        this.users = usersData;
        this.invitations$.subscribe({
          next: (invitationsData) => {
            this.invitations = invitationsData;
            this.merchantUsersAndInvitations = [...(this.users || []), ...(this.invitations || [])];
            // check if applicant email exists in users or invitations
            this.merchantUsersAndInvitations.forEach((user) => {
              if (user.email === this.applicant?.email) {
                this.applicantExists = true;
              }
            });
            this.loading$.next(false);
          },
          error: (err) => {
            const message: TilledAlert = {
              message: 'Could not load user invitations',
              title: 'Server error',
              type: 'error',
            };
            this._alertService.showAlert(message);
            this.loading$.next(false);
            throw new Error('Error loading user invitations ' + JSON.stringify(err));
          },
        });
      },
      error: (err) => {
        const message: TilledAlert = {
          message: 'Could not load users',
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
        this.loading$.next(false);
        throw new Error('Error loading users ' + JSON.stringify(err));
      },
    });
  }

  getPrimaryApplicant(): void {
    this.applicant = this.merchantApp?.legal_entity?.principals?.find((rep) => rep.is_applicant);
    // check if primary applicant is the current user
    if (this.applicant?.email === this._authService.user.email) {
      this.isPrimaryApplicant = true;
    }

    if (!this.isPrimaryApplicant) {
      this.getUsersAndInvites();
    }
  }

  invitePrimaryApplicant(): void {
    this.inviteApplicant.emit({
      applicantInvite: true,
      emailExists: this.applicantExists,
      applicant: this.applicant,
    });
  }

  openDocuSignModal(): void {
    // TODO: open DocuSign modal logic
    console.log('openDocuSignModal');
  }

  private handleUpdatedApplication(): void {
    this.getTermsLinks();

    this.submitApplicationForm = this._formBuilder.group({
      acceptedTerms: new FormControl<boolean>(this.merchantApp?.tos_acceptance || false),
    });
    this.termsAgreed = this.merchantApp?.tos_acceptance;
    this.verifyYourBusinessComplete = this._merchantAppService.isVerifyYourBusinessStepComplete(this.merchantApp);
    this.businessTypeComplete = this._merchantAppService.isBusinessTypeSubStepComplete(this.merchantApp);
    this.businessDetailsComplete = this._merchantAppService.isBusinessDetailsSubStepComplete(this.merchantApp);
    this.businessContactComplete = this._merchantAppService.isBusinessContactInformationComplete(this.merchantApp);
    this.businessRepresentativesComplete = this._merchantAppService.isBusinessRepresentativesComplete(this.merchantApp);
    this.businessOwnersComplete = this._merchantAppService.isBusinessOwnersComplete(this.merchantApp);
    this.productsAndServicesComplete = this._merchantAppService.isProductsAndServicesComplete(this.merchantApp);
    this.paymentAcceptanceComplete = this._merchantAppService.isPaymentAcceptanceComplete(this.merchantApp);
    this.processingVolumeComplete = this._merchantAppService.isPaymentProcessingVolumeComplete(this.merchantApp);
    this.bankAccountComplete = this._merchantAppService.isBankingInformationComplete(this.merchantApp);
    this.businessDocumentsComplete = this._merchantAppService.isBusinessDocumentsComplete(this.merchantApp);
    this.pricingAndTermsComplete = this._merchantAppService.isReviewPricingAndTermsComplete(this.merchantApp);

    this.canSubmitApplication =
      this.businessTypeComplete.complete &&
      this.businessDetailsComplete.complete &&
      this.businessContactComplete.complete &&
      this.businessRepresentativesComplete.complete &&
      this.businessOwnersComplete.complete &&
      this.productsAndServicesComplete.complete &&
      this.paymentAcceptanceComplete.complete &&
      this.processingVolumeComplete.complete &&
      this.bankAccountComplete.complete &&
      this.businessDocumentsComplete.complete;

    let pricingCount = 0;
    const cardPricing = this.merchantApp.pricing_templates.find(
      (p) => p.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.CARD,
    );
    if (cardPricing) {
      this.cardPricingTemplate = new CardPricingTemplateViewModel(cardPricing);
    }
    if (this.cardPricingTemplate) {
      pricingCount += 1;
      this._cardPricingTemplate$.next(this.cardPricingTemplate);
    }

    const cardPresentPricing = this.merchantApp.pricing_templates.find(
      (p) => p.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.CARD_PRESENT,
    );
    if (cardPresentPricing) {
      this.cardPresentPricingTemplate = new CardPresentPricingTemplateViewModel(cardPresentPricing);
    }
    if (this.cardPresentPricingTemplate) {
      pricingCount += 1;
      this._cardPresentPricingTemplate$.next(this.cardPresentPricingTemplate);
    }

    const debitPricing = this.merchantApp.pricing_templates.find(
      (p) =>
        p.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.ACH_DEBIT ||
        p.payment_method_type === PricingTemplate.PaymentMethodTypeEnum.EFT_DEBIT,
    );
    if (debitPricing) {
      this.debitPricingTemplate = new DebitPricingTemplateViewModel(debitPricing);
    }
    if (this.debitPricingTemplate) {
      pricingCount += 1;
      this._debitPricingTemplate$.next(this.debitPricingTemplate);
    }

    this.hasMultiple = pricingCount > 1;

    this._changeDetectorRef.markForCheck();
  }

  scrollToTop(): void {
    const element = document.querySelector('.top-of-form');
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'end' });
    }
  }
}
