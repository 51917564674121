import { Injectable } from '@angular/core';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { AlertService } from 'app/core/services/alert.service';
import { BehaviorSubject, EMPTY, Observable, expand, map, shareReplay, tap } from 'rxjs';
import {
  BulkDocumentSubmitResponse,
  BulkSubmitDocumentsRequestParams,
  DocumentDto,
  DocumentsService,
  InternalCreateDocumentParams,
  InternalDocument,
  InternalService,
  InternalUpdateDocumentParams,
  ListDocumentsRequestParams,
  SubmitDocumentRequestParams,
} from '../../../../projects/tilled-api-client/src';

@Injectable({
  providedIn: 'root',
})
export class DocumentsAppService {
  private documentListBuilder: DocumentDto[] = [];
  private _documentsAll$ = new BehaviorSubject<DocumentDto[]>(null);
  private _documentsAllTotal$ = new BehaviorSubject<number>(0);
  private _documentsList$ = new BehaviorSubject<DocumentDto[]>(null);
  private _documentsListCount$ = new BehaviorSubject<number>(0);

  public documentsAll$: Observable<DocumentDto[]> = this._documentsAll$.asObservable();
  public documentsAllTotal$: Observable<number> = this._documentsAllTotal$.asObservable();
  public documentsList$: Observable<DocumentDto[]> = this._documentsList$.asObservable();
  public documentsListCount$: Observable<number> = this._documentsListCount$.asObservable();

  constructor(
    private _documentsService: DocumentsService,
    private _alertService: AlertService,
    private _internalService: InternalService,
  ) {}

  public submitDocument(params: SubmitDocumentRequestParams): Observable<DocumentDto> {
    return this._documentsService.submitDocument(params);
  }

  public bulkSubmitDocuments(params: BulkSubmitDocumentsRequestParams): Observable<BulkDocumentSubmitResponse> {
    return this._documentsService.bulkSubmitDocuments(params);
  }

  // This will recursively call the list documents endpoint until we have them all.
  public getAllDocuments(accountId: string, status: DocumentDto.StatusEnum = DocumentDto.StatusEnum.REQUESTED): void {
    this.documentListBuilder = [];
    const requestParams: ListDocumentsRequestParams = {
      tilledAccount: accountId,
      status: status,
      offset: 0,
      limit: 100,
    };

    const listDocuments$ = this._documentsService.listDocuments(requestParams);
    listDocuments$
      .pipe(
        expand((result) => {
          const hasMore = result.has_more;
          requestParams.offset = result.offset + result.limit;
          if (hasMore) {
            this.documentListBuilder.push(...result.items);
            return this._documentsService.listDocuments(requestParams);
          }
          this.documentListBuilder.push(...result.items);
          this._documentsAll$.next(this.documentListBuilder);
          this._documentsAllTotal$.next(result.total);
          return EMPTY;
        }),
      )
      .subscribe();
  }

  public getDocumentsList(params: ListDocumentsRequestParams): void {
    this._documentsService
      .listDocuments(params)
      .pipe(
        tap((result) => this._documentsListCount$.next(result.total)),
        map((result) => result.items),
        shareReplay(1),
      )
      .subscribe({
        next: (documents) => {
          this._documentsList$.next(documents);
        },
        error: (err) => {
          // generic catch all for error responses
          const message: TilledAlert = {
            message: 'Could not load all documents',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
        },
      });
  }

  public createDocument(accountId: string, params: InternalCreateDocumentParams): Observable<InternalDocument> {
    return this._internalService.internalCreateDocument({
      tilledAccount: accountId,
      internalCreateDocumentParams: params,
    });
  }

  public updateDocument(
    accountId: string,
    documentId: string,
    params: InternalUpdateDocumentParams,
  ): Observable<InternalDocument> {
    return this._internalService.internalUpdateDocument({
      tilledAccount: accountId,
      id: documentId,
      internalUpdateDocumentParams: params,
    });
  }

  public getDocumentById(accountId: string, documentId: string): Observable<DocumentDto> {
    return this._documentsService.getDocument({
      tilledAccount: accountId,
      id: documentId,
    });
  }
}
