<div>
  <fuse-alert
    [ngClass]="{ 'pr-20': !forConsole }"
    class="w-full pb-8"
    [type]="'warning'"
    [appearance]="'outline'"
    [dismissible]="false"
    [dismissed]="false"
    [name]="'merchantDocumentRequestAlertBox'"
    *ngIf="requestNotes?.length > 0"
  >
    <div class="icon-size-5" fuseAlertIcon>👋</div>
    <tilled-heading-h6 fuseAlertTitle>Message from the underwriting team</tilled-heading-h6>
    <div class="flex flex-row" *ngFor="let request of requestNotes">
      <tilled-paragraph-p2>
        <span class="font-semibold">{{ request.description }}</span>
        - {{ request.note }}
      </tilled-paragraph-p2>
    </div>
  </fuse-alert>
  <form [formGroup]="writtenFormArray" class="w-full">
    <div *ngFor="let written of writtenRequestsViewArray" [ngClass]="{ 'pr-20': !forConsole }" class="w-full pb-4">
      <tilled-paragraph-p2 [bold]="true">{{
        mapSubtypeToTitle(written.document.subtype, written.document.request_notes, true)
      }}</tilled-paragraph-p2>
      <tilled-paragraph-p3>{{ mapSubtypeToDescription(written.document.subtype) }}</tilled-paragraph-p3>
      <mat-form-field appearance="outline" class="w-full pt-1">
        <textarea
          class="text-field"
          [formControl]="written.responseForm"
          matInput
          maxLength="1023"
          cdkTextareaAutosize
          cdkAutosizeMinRows="3"
          cdkAutosizeMaxRows="7"
        ></textarea>
      </mat-form-field>
      <mat-divider class="w-full"></mat-divider>
    </div>
  </form>

  <div class="pb-2"></div>

  <div *ngFor="let file of fileRequests" class="pb-4">
    <div class="-mb-4 flex flex-row items-center space-x-2">
      <div class="flex flex-col pb-2">
        <tilled-paragraph-p2 bold="true">{{
          mapSubtypeToTitle(file.subtype, file.request_notes, true)
        }}</tilled-paragraph-p2>
        <tilled-paragraph-p3
          class="documents-description"
          *ngIf="file.subtype == 'dl_passport' || file.subtype == 'dl_back' || file.subtype == 'invoice'"
          >{{ mapSubtypeToDescription(file.subtype) }}</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 class="documents-secondary-description" *ngIf="file.subtype == 'invoice'">{{
          invoiceSecondaryDescription
        }}</tilled-paragraph-p3>
      </div>
      <mat-icon
        *ngIf="file.subtype == 'bank_letter_voided_check'"
        class="pb-2 text-black icon-size-5"
        [svgIcon]="'mat_outline:help_outline'"
        matTooltipClass="documents-tooltip"
        [matTooltip]="bankLetterTooltipText"
      ></mat-icon>
    </div>
    <app-file-upload
      class="-ml-1 pt-8"
      [fileCountLimit]="limit"
      [purpose]="filePurpose"
      [isMerchantApp]="!forConsole"
      [allowedFileTypes]="fileTypes"
      [fileDescriptions]="[mapSubtypeToTitle(file.subtype)]"
      (fileUploaded)="fileUploaded($event, file.id)"
      (fileDeleted)="fileDeleted($event, file.id)"
      (pendingFilesChange)="pendingFilesChange($event)"
      [fileAccountId]="accountId"
      [allowMultipleFiles]="limit === 1 ? false : true"
    ></app-file-upload>
    <mat-divider class="mt-3 w-full"></mat-divider>
  </div>

  <mat-checkbox
    data-test-id="document-upload-checkbox"
    class="pt-1 text-p3"
    [color]="'primary'"
    [(ngModel)]="understandChecked"
    *ngIf="(writtenRequests?.length > 0 || fileRequests?.length > 0) && !overrideCheckbox"
  >
    <tilled-paragraph-p3
      >I understand that underwriting evidence cannot be edited after submission
    </tilled-paragraph-p3>
  </mat-checkbox>

  <div class="flex justify-end pb-4 pt-8" *ngIf="writtenRequests?.length > 0 || fileRequests?.length > 0">
    <tilled-button
      bgColor="tilled-primary"
      [rounded]="true"
      [buttonText]="submitText"
      (buttonClick)="submitDocuments()"
      [disabled]="!('onboarding:write' | scopeAble) || (!understandChecked && !overrideCheckbox) || updatingDocuments"
    ></tilled-button>
  </div>
</div>
