<div class="max-h-[100vh] w-fit overflow-y-auto sm:min-w-120">
  <mat-stepper #stepper cdk-scrollable labelPosition="bottom" [animationDuration]="700">
    <mat-step label="New Outbound Transfer" color="primary">
      <form-card
        (exitClick)="closeDialog()"
        (nextClick)="nextPage()"
        [backButton]="false"
        [nextButton]="true"
        [nextDisabled]="newOutboundTransferForm.invalid"
        [titleText]="'Create New Outbound Transfer'"
        [secondaryText]="
          enablePaymentMethod
            ? 'Enter details about the transfer you wish to make to the customer’s bank account.'
            : 'Set the amount you wish to transfer to the customer’s bank account.'
        "
        [testIds]="{ headerTestId: 'modal-header' }"
      >
        <form [formGroup]="newOutboundTransferForm">
          <div>
            <div [ngClass]="!enablePaymentMethod ? 'hidden' : ''">
              <mat-form-field floatLabel="always" appearance="fill" class="-mt-4 w-full">
                <tilled-label-l1 secondary="true">Payment Method</tilled-label-l1>
                <mat-select
                  class="text-secondary pt-4 text-p3"
                  formControlName="paymentMethod"
                  [(value)]="selectedPaymentMethod"
                  [disabled]="!enablePaymentMethod"
                  placeholder="Select a payment method"
                  data-test-id="payment-method"
                >
                  <mat-option
                    *ngFor="let paymentMethod of availablePaymentMethods | keyvalue"
                    [value]="paymentMethod.key"
                  >
                    {{ paymentMethod.value }}
                  </mat-option>
                </mat-select>
                <mat-error
                  class="text-sm"
                  color="warn"
                  *ngIf="newOutboundTransferForm.get('paymentMethod').hasError('required')"
                  >A payment method is required</mat-error
                >
              </mat-form-field>
            </div>

            <div [ngClass]="!enableCurrency ? 'hidden' : ''">
              <mat-form-field floatLabel="always" appearance="fill" class="-mt-4 w-full pb-4">
                <tilled-label-l1 secondary="true" class="">Currency</tilled-label-l1>
                <mat-select
                  class="text-secondary pt-4 text-p3"
                  formControlName="currency"
                  [(value)]="selectedCurrency"
                  [disabled]="!enableCurrency"
                  placeholder="Select a currency"
                  data-test-id="currency"
                >
                  <mat-option *ngFor="let currency of availableCurrencies | keyvalue" [value]="currency.key">
                    {{ currency.value }}
                  </mat-option>
                </mat-select>
                <mat-error
                  class="text-sm"
                  color="warn"
                  *ngIf="newOutboundTransferForm.get('currency').hasError('required')"
                  >A currency is required</mat-error
                >
              </mat-form-field>
            </div>
            <tilled-input
              label="Transfer amount"
              placeholder="Enter the amount"
              name="amount"
              [errors]="
                newOutboundTransferForm.get('amount').hasError('required') &&
                newOutboundTransferForm.get('amount').touched
              "
              tilledLabel="true"
              showCurrency="true"
              mask="separator.2"
              data-test-id="transfer-amount"
            >
            </tilled-input>
          </div>
        </form>
      </form-card>
    </mat-step>

    <mat-step label="Payment Method Details">
      <!-- ACH Debit -->
      <div
        [ngClass]="
          selectedPaymentType == 'ach_debit' || selectedPaymentType == 'eft_debit'
            ? 'visible grid min-w-100 sm:min-w-[700px]'
            : 'hidden'
        "
      >
        <form-card
          (exitClick)="closeDialog()"
          (backClick)="prevPage()"
          [backButton]="true"
          [submitButton]="true"
          (submitClick)="sentOutboundTransfer()"
          [submitDisabled]="bankTransferForm.invalid || tilledBankForm?.invalid || (sendingTransfer$ | async)"
          [submitText]="'Send Outbound Transfer'"
          [displayAlert]="alertDialogError$ | async"
          [showAlert$]="alertDialogError$"
          [alertTitle]="errorTitle"
          [alertMessage]="errorMessage"
          [maxWidth]="'max-w-180'"
          [titleText]="pageTitle + (newOutboundTransferForm.get('amount').value * 100 | minorUnitsToCurrency)"
          [secondaryText]="'Enter the customer’s bank account information where funds should be deposited.'"
          [testIds]="{ headerTestId: 'ach-header' }"
        >
          <form [formGroup]="bankTransferForm">
            <div class="flex flex-col">
              <div class="grid w-[full] grid-cols-1 gap-x-6 sm:grid-cols-4">
                <div class="col-span-2 flex flex-col pt-4">
                  <div class="flex w-full flex-col">
                    <tilled-label-l1 secondary="true" class="pb-1.5">Account Number</tilled-label-l1>
                    <div class="inputField" id="bank-account-number-element"></div>
                    <div
                      class="pt-1 text-sm text-warn"
                      *ngIf="showAccountNumberError; else noAchAccountNumberError"
                      @fadeIn
                    >
                      A valid account number is required
                    </div>
                    <ng-template #noAchAccountNumberError>
                      <div class="pt-1 text-sm">&nbsp;</div>
                    </ng-template>
                  </div>
                </div>
                <div
                  [ngClass]="
                    selectedPaymentType == 'ach_debit'
                      ? 'col-span-1 flex w-[170px] flex-col pt-4'
                      : 'col-span-2 flex flex-col pt-4'
                  "
                >
                  <div class="flex w-full flex-col">
                    <ng-container *ngIf="selectedPaymentType == 'ach_debit'; else eftLabel">
                      <tilled-label-l1 secondary="true" class="pb-1.5">Routing Number</tilled-label-l1>
                    </ng-container>
                    <ng-template #eftLabel>
                      <tilled-label-l1 secondary="true" class="pb-1.5"
                        >Transit Number and Institution Id</tilled-label-l1
                      >
                    </ng-template>
                    <div class="inputField" id="bank-routing-number-element"></div>
                    <div
                      class="pt-1 text-sm text-warn"
                      *ngIf="showRoutingNumberError; else noRoutingNumberError"
                      @fadeIn
                    >
                      A valid
                      {{ selectedPaymentType == 'ach_debit' ? 'routing number' : 'transit number and institution ID' }}
                      is required
                    </div>
                    <ng-template #noRoutingNumberError>
                      <div class="pt-1 text-sm">&nbsp;</div>
                    </ng-template>
                  </div>
                </div>
                <div *ngIf="selectedPaymentType == 'ach_debit'" class="col-span-1 flex flex-col">
                  <div class="w-full pb-2.5 pt-2.5">
                    <tilled-label-l1 secondary="true">Account type</tilled-label-l1>
                  </div>
                  <tilled-select
                    [multiple]="false"
                    [options]="[
                      { value: 'checking', label: 'Checking' },
                      { value: 'savings', label: 'Savings' }
                    ]"
                    [controlName]="bankTransferForm.get('accountType')"
                    placeholder="Select an account type"
                    classOverride="fuse-mat-dense fuse-mat-rounded flex w-full flex-col"
                    [errors]="
                      bankTransferForm.get('accountType').hasError('required') &&
                      bankTransferForm.get('accountType').touched
                    "
                    errorMessage="An account type is required"
                    data-test-id="ach-account-type"
                  ></tilled-select>
                </div>
                <!-- new row -->
                <div class="col-span-4 -mt-2 flex flex-col">
                  <tilled-input
                    label="Account holder name"
                    placeholder="Legal Business Name"
                    name="accountholderName"
                    [errors]="
                      bankTransferForm.get('accountholderName').hasError('required') &&
                      bankTransferForm.get('accountholderName').touched
                    "
                    errorMessage="An account holder name is required"
                    tilledLabel="true"
                    data-test-id="ach-account-holder"
                  ></tilled-input>
                </div>

                <!-- billing details -->
                <div class="col-span-2 flex flex-col">
                  <tilled-input
                    label="Address line 1"
                    placeholder="Enter street address"
                    name="street1"
                    [errors]="
                      bankTransferForm.get('street1').hasError('required') && bankTransferForm.get('street1').touched
                    "
                    errorMessage="An address line 1 is required"
                    tilledLabel="true"
                    data-test-id="ach-address-1"
                  ></tilled-input>
                </div>
                <div class="col-span-2 flex flex-col">
                  <tilled-input
                    label="Address line 2"
                    placeholder="Suite / Apt / Unit (Optional)"
                    name="street2"
                    tilledLabel="true"
                    data-test-id="ach-address-2"
                  ></tilled-input>
                </div>
                <!-- new row -->
                <div class="col-span-1 flex flex-col">
                  <div class="mt-2 w-full pb-2.5">
                    <tilled-label-l1 secondary="true">Country</tilled-label-l1>
                  </div>
                  <tilled-select
                    [multiple]="false"
                    [options]="countryCodeMap"
                    [controlName]="bankTransferForm.get('country')"
                    placeholder="Select a country"
                    classOverride="fuse-mat-dense fuse-mat-rounded w-full"
                    [errors]="
                      bankTransferForm.get('country').hasError('required') && bankTransferForm.get('country').touched
                    "
                    errorMessage="A country is required"
                    data-test-id="ach-country"
                  ></tilled-select>
                </div>
                <div class="col-span-1 flex flex-col">
                  <div class="mt-2 w-full pb-2.5">
                    <tilled-label-l1 secondary="true">State</tilled-label-l1>
                  </div>
                  <tilled-select
                    [multiple]="false"
                    [options]="stateCodeMap"
                    [controlName]="bankTransferForm.get('state')"
                    placeholder="Select a state"
                    classOverride="fuse-mat-dense fuse-mat-rounded w-full"
                    [errors]="
                      bankTransferForm.get('state').hasError('required') && bankTransferForm.get('state').touched
                    "
                    errorMessage="A state is required"
                    data-test-id="ach-state"
                  ></tilled-select>
                </div>
                <div class="col-span-1 flex flex-col">
                  <tilled-input
                    label="City"
                    placeholder="Enter city"
                    name="city"
                    [errors]="bankTransferForm.get('city').hasError('required') && bankTransferForm.get('city').touched"
                    errorMessage="A city is required"
                    tilledLabel="true"
                    data-test-id="ach-city"
                  ></tilled-input>
                </div>
                <div class="col-span-1 flex flex-col">
                  <tilled-input
                    label="Zip"
                    placeholder="Enter zip"
                    name="postalCode"
                    [errors]="
                      bankTransferForm.get('postalCode').hasError('required') &&
                      bankTransferForm.get('postalCode').touched
                    "
                    errorMessage="A zip is required"
                    tilledLabel="true"
                    data-test-id="ach-zip"
                  ></tilled-input>
                </div>
              </div>
              <!-- end of ACH Info 4 col grid-->
            </div>
          </form>
        </form-card>
      </div>
    </mat-step>
  </mat-stepper>
</div>
