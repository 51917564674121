<div class="flex min-h-10 flex-col justify-center">
  <div *ngIf="!editStatus && !loading" class="flex">
    <div class="mr-3">
      <tilled-heading-h4>
        {{ nickname }}
      </tilled-heading-h4>
    </div>
    <mat-icon
      class="text-primary icon-size-5"
      [svgIcon]="'heroicons_solid:pencil'"
      (click)="onStatusToggle()"
    ></mat-icon>
  </div>
  <div *ngIf="editStatus && !loading" class="my-[-10px] flex min-w-60">
    <mat-form-field [ngStyle]="{ 'width.px': width }">
      <input
        matInput
        #elasticInput
        class="h-full font-openSans text-h4 font-semibold"
        [ngModel]="nameInput"
        (ngModelChange)="onNameInputChange($event)"
      />
    </mat-form-field>
    <div class="flex flex-col justify-center">
      <mat-icon class="text-primary" [svgIcon]="'heroicons_solid:check'" (click)="onNameEdit()"> </mat-icon>
    </div>
    <div class="flex flex-col justify-center">
      <mat-icon class="text-primary" [svgIcon]="'mat_outline:close'" (click)="onEditCancel()"> </mat-icon>
    </div>
  </div>
  <div *ngIf="loading">
    <mat-progress-bar mode="buffer"></mat-progress-bar>
  </div>
</div>
