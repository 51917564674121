<!-- Loading bar -->
<fuse-loading-bar></fuse-loading-bar>

<div
  class="fixed left-0 right-0 z-[99999] m-auto w-full max-w-full px-6 sm:w-fit sm:max-w-[50%] sm:px-0"
  [ngClass]="{
    'top-20': !(alert$ | async)?.bottom,
    'bottom-20': (alert$ | async)?.bottom
  }"
>
  <tilled-alert></tilled-alert>
</div>
<div>
  <file-download></file-download>
</div>

<!-- Navigation -->
<fuse-vertical-navigation
  class="dark bg-accent-500 print:hidden"
  [ngClass]="{
    'text-white': whiteText,
    'text-black': !whiteText
  }"
  [mode]="isScreenSmall ? 'over' : 'side'"
  [name]="'mainNavigation'"
  [navigation]="navItems"
  [opened]="!isScreenSmall"
>
  <!-- Navigation header hook -->
  <ng-container fuseVerticalNavigationHeader>
    <!-- Logo -->
    <div
      class="mb-6 flex h-20 items-center pl-7 pr-6"
      [ngClass]="{
        'bg-white': (isWhiteLabel$ | async) && !(useDarkLogo$ | async),
        'p-6 pb-0': !(isWhiteLabel$ | async)
      }"
    >
      <img
        [ngClass]="{ 'w-30': !(isWhiteLabel$ | async), 'max-h-[75px] w-fit': (isWhiteLabel$ | async) }"
        [src]="companyLogo"
      />
    </div>
  </ng-container>
</fuse-vertical-navigation>

<!-- Wrapper -->
<div class="flex w-full min-w-0 flex-auto flex-col bg-tilled-neutral-200">
  <!-- Header -->
  <div
    class="relative z-49 flex h-16 w-full flex-0 items-center bg-transparent px-4 shadow dark:border-b dark:bg-transparent dark:shadow-none md:px-6 print:hidden"
  >
    <!-- Navigation toggle button -->
    <button data-test-id="logout-nav" class="mr-auto" mat-icon-button (click)="toggleNavigation('mainNavigation')">
      <mat-icon class="text-primary" [svgIcon]="'heroicons_outline:bars-3'"></mat-icon>
    </button>

    <!-- Multi-account Selector -->
    <app-multi-account-selector
      *ngIf="this.user?.account_roles?.length > 1"
      class="border-r border-tilled-neutral-300 pr-4"
    ></app-multi-account-selector>

    <!-- Components -->
    <notifications *ngIf="this.account?.type === 'merchant'"></notifications>

    <div class="flex items-center">
      <div>
        <button mat-icon-button (click)="helpCenter.toggle()">
          <mat-icon class="text-primary" [svgIcon]="'heroicons_outline:question-mark-circle'"></mat-icon>
        </button>
      </div>
      <user *ngIf="this.user"></user>
    </div>
  </div>

  <!-- Content -->
  <div class="flex flex-auto flex-col">
    <!-- *ngIf="true" hack is required here for router-outlet to work correctly.
    Otherwise, layout changes won't be registered and the view won't be updated! -->
    <router-outlet *ngIf="true"></router-outlet>
  </div>

  <!-- Footer -->
  <div
    class="bg-card relative z-49 flex h-14 w-full flex-0 items-center justify-start border-t px-4 dark:bg-transparent md:px-6 print:hidden"
  >
    <span class="text-secondary font-medium"
      >{{ (isWhiteLabel$ | async) ? (copyrightName$ | async) : 'Tilled' }} &copy; {{ currentYear }}</span
    >
  </div>
</div>

<!-- Help center -->
<help-center #helpCenter="helpCenter"></help-center>
