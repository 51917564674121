<div class="top-of-form"></div>
<merchant-app-card
  [title]="'Business contact information'"
  [description]="
    'This section should include the contact details you share with your customers to get in touch with your business.'
  "
  [backButton]="false"
  (continueClick)="onContinueClicked($event)"
  [continueButton]="!forConsole && !(disabled$ | async)"
>
  <form data-test-id="contact-step" class="w-full" [formGroup]="businessContactForm">
    <tilled-input
      [merchantApp]="true"
      data-test-id="contact-phone-number"
      class="w-full"
      name="phone"
      [prefix]="businessContactForm.get('phone')?.value?.includes('+') ? '' : selectedPhoneCode"
      placeholder="(555) 000-0000"
      [mask]="businessContactForm.get('phone')?.value?.includes('+') ? '+0 (000) 000-0000' : '(000) 000-0000'"
      label="Customer support phone number"
      tilledLabel="true"
      [errors]="businessContactForm.get('phone').hasError('invalidPhone') && businessContactForm.get('phone').touched"
      errorMessage="Must be a valid phone number."
      [prefixMultiple]="false"
      [prefixOptions]="phoneCodeMap"
      [prefixControlName]="businessContactForm.get('phoneCode')"
      [prefixPlaceholder]="'US'"
    ></tilled-input>
    <tilled-input
      [merchantApp]="true"
      data-test-id="contact-email"
      class="w-full"
      name="email"
      placeholder="support@company.com"
      [sentenceCasePlaceholder]="false"
      label="Customer support email"
      tilledLabel="true"
      [errors]="businessContactForm.get('email').hasError('invalidEmail') && businessContactForm.get('email').touched"
      errorMessage="Email Address must be valid."
    ></tilled-input>

    <tilled-input
      [merchantApp]="true"
      data-test-id="contact-website"
      class="flex w-full flex-col {{ !forConsole ? ' pb-2.5' : ' pb-3' }}"
      name="website"
      [prefix]="
        businessContactForm.get('website').value !== ('' || null) &&
        businessContactForm.get('website')?.value?.includes('https://')
          ? ''
          : 'https://'
      "
      prefixSuffixBorder="prefix"
      placeholder="www.yourcompanyname.xyz"
      [sentenceCasePlaceholder]="false"
      label="Company website"
      tilledLabel="true"
      [errors]="businessContactForm.get('website').errors && businessContactForm.get('website').touched"
      errorMessage="Website must be a valid URL. `https://` is not required."
      [hint]="
        'If you sell online, please provide the website where your customers go to purchase your products or services. Social media pages are not accepted.'
      "
    ></tilled-input>
  </form>
</merchant-app-card>
