import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import {
  CardChargeFeeTemplate,
  CardPresentChargeFeeTemplate,
  InternalAccount,
  InternalAccountCapability,
  Markup,
} from '@tilled-api-client';
import { MinorUnitsToCurrencyPipe } from 'app/core/pipes/minor-units-to-currency.pipe';
import { SnakeCaseSplitPipe } from 'app/core/pipes/snake-case-split.pipe';
import { TransactionFeeTypePipe } from 'app/core/pipes/transaction-fee-type-pipe';
import { AuthService } from 'app/core/services/auth.service';
import { FuseCardComponent } from '../../../@fuse/components/card/card.component';
import { TilledCardRowComponent } from '../tilled-card-row/tilled-card-row.component';
import { TilledHeadingH4Component } from '../tilled-text/tilled-heading/tilled-heading-h4.component';
import {
  BillingPayoutScheduleDialogComponent,
  BillingPayoutScheduleDialogData,
  monthlyAnchorDateTypeToText,
} from './billing-payout-schedule-dialog/billing-payout-schedule-dialog.component';

@Component({
  selector: 'processing-details',
  templateUrl: './processing-details.component.html',
  styleUrls: ['./processing-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatIconModule,
    TilledHeadingH4Component,
    MatDividerModule,
    FuseCardComponent,
    TilledCardRowComponent,
    MinorUnitsToCurrencyPipe,
    TransactionFeeTypePipe,
    SnakeCaseSplitPipe,
    CommonModule,
  ],
})
export class ProcessingDetailsComponent implements OnInit {
  @Input() account: InternalAccount;
  @Input() isMerchant: boolean;

  public routing_number?: string;
  public institution_id?: string;
  public transit_number?: string;

  private isUserAllowedToEdit = false;

  private _transactionFeeType = new TransactionFeeTypePipe();

  constructor(
    private _matDialog: MatDialog,
    private _authService: AuthService,
  ) {}

  ngOnInit(): void {
    this.isUserAllowedToEdit = this._authService.isScopeAble('accounts:write');
  }

  nullOrEmptyText(val: any): string {
    if (val == null || val === '') {
      return '-';
    } else {
      return val;
    }
  }

  nullOrEmptyBooleanText(val: boolean): string {
    if (val == null) {
      return '-';
    } else if (val) {
      return 'Yes';
    } else {
      return 'No';
    }
  }

  getVisaDiscountFee(markups: Markup[]): number {
    return markups?.find((markup) => markup.card_type === 'visa')?.rate || 0;
  }

  getAmexDiscountFee(markups: Markup[]): number {
    return markups?.find((markup) => markup.card_type === 'amex')?.rate || 0;
  }

  getFeeTypeDescription(feeDetails: CardChargeFeeTemplate | CardPresentChargeFeeTemplate) {
    let feeTypeDescription = this._transactionFeeType.transform(feeDetails.transaction_fee_type);

    // pass_through_fees is undocumented but will be present when applicable
    /* @ts-ignore */
    if (feeDetails.pass_through_fees) {
      feeTypeDescription += ' w/ pass-through fees';
    }

    return feeTypeDescription;
  }

  editBillingPayoutSchedules(capability: InternalAccountCapability): void {
    const data: BillingPayoutScheduleDialogData = {
      action: 'edit',
      accountId: this.account.id,
      capability: capability,
    };

    const dialogRef = this._matDialog.open(BillingPayoutScheduleDialogComponent, {
      data: data,
    });

    dialogRef.afterClosed().subscribe((account?: InternalAccount) => {
      if (!account) {
        return;
      }
      if (account.id === this.account.id) {
        this.account = account;
      }
    });
  }

  isCapabilityEditable(capability: InternalAccountCapability): boolean {
    if (!this.isUserAllowedToEdit) {
      return false;
    }

    const isTilledBilling = capability.billing_owner === InternalAccountCapability.BillingOwnerEnum.TILLED;
    const editable = isTilledBilling && capability.status === 'active';
    return editable;
  }

  public textForMonthlyAnchorDate(anchor: Date): string {
    const anchorType = BillingPayoutScheduleDialogComponent.computeMonthlyAnchorDateType(anchor);

    if (anchorType >= 0) {
      return monthlyAnchorDateTypeToText[anchorType];
    }

    return undefined;
  }
}
