<button data-test-id="open-user-menu" mat-icon-button [matMenuTriggerFor]="userActions">
  <mat-icon
    *ngIf="!showAvatar || !user.avatar"
    [ngClass]="{
      'text-tilled-primary': onboarding && whiteLabel,
      'text-black': onboarding && !whiteLabel,
      'text-primary': !onboarding
    }"
    [svgIcon]="'heroicons_outline:user-circle'"
  ></mat-icon>
</button>

<mat-menu [xPosition]="'before'" #userActions="matMenu">
  <button mat-menu-item data-test-id="user-menu" class="min-h-7">
    <div class="flex flex-wrap whitespace-pre-wrap">
      <tilled-paragraph-p3 class="text-tilled-primary">Signed in as </tilled-paragraph-p3>
      <tilled-paragraph-p3 [bold]="true" class="mr-1.5 text-tilled-primary">{{ user.email }}</tilled-paragraph-p3>
    </div>
  </button>
  <mat-divider class="mx-auto my-2 h-0.5 w-10/12 border-0 bg-tilled-neutral-400"></mat-divider>
  <button data-test-id="reset-password-button" mat-menu-item class="-mt-1 min-h-7 py-2" (click)="resetPassword()">
    <mat-icon
      class="icon-size-5"
      [ngClass]="{
        'text-tilled-primary': (onboarding && whiteLabel) || (!onboarding && !whiteLabel),
        'text-black': onboarding && !whiteLabel
      }"
      [svgIcon]="'heroicons_outline:arrow-path'"
    ></mat-icon>
    <tilled-paragraph-p3 [bold]="true" class="text-tilled-primary">Reset password</tilled-paragraph-p3>
  </button>
  <button data-test-id="sign-out-button" mat-menu-item class="min-h-7 py-2" (click)="signOut()">
    <mat-icon
      class="icon-size-5"
      [ngClass]="{
        'text-tilled-primary': (onboarding && whiteLabel) || (!onboarding && !whiteLabel),
        'text-black': onboarding && !whiteLabel
      }"
      [svgIcon]="'mat_outline:logout'"
    >
    </mat-icon>
    <tilled-paragraph-p3 [bold]="true" class="text-tilled-primary">Sign out</tilled-paragraph-p3>
  </button>
</mat-menu>

<mat-menu class="user-status-menu" #userStatus="matMenu">
  <button mat-menu-item (click)="updateUserStatus('online')">
    <span class="mr-3 h-4 w-4 rounded-full bg-green-500"></span>
    <span>Online</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('away')">
    <span class="mr-3 h-4 w-4 rounded-full bg-amber-500"></span>
    <span>Away</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('busy')">
    <span class="mr-3 h-4 w-4 rounded-full bg-red-500"></span>
    <span>Busy</span>
  </button>
  <button mat-menu-item (click)="updateUserStatus('not-visible')">
    <span class="mr-3 h-4 w-4 rounded-full bg-gray-400"></span>
    <span>Invisible</span>
  </button>
</mat-menu>
