<div>
  <div class="download-icon-container absolute top-[118px] ml-auto sm:right-6 sm:pl-0">
    <button
      mat-icon-button
      [disabled]="(payoutsCount$ | async) === 0 || !reportDownloadEnabled"
      (click)="downloadPayoutReport()"
      [ngClass]="
        (payoutsCount$ | async) === 0 || !reportDownloadEnabled
          ? '[&>mat-icon>svg]:text-hint'
          : '[&>mat-icon>svg]:text-tilled-primary'
      "
    >
      <mat-icon
        id="downloadReport"
        data-test-id="payouts-overview-download-report"
        [matTooltip]="reportDownloadTooltip"
        [svgIcon]="'heroicons_outline:arrow-down-tray'"
      ></mat-icon>
    </button>
  </div>

  <div class="flex-auto p-6">
    <fuse-card class="tilled-card-styling flex flex-col py-2 pb-4" #fuseCard>
      <div class="overflow scroll-auto">
        <tilled-table
          [data]="(payoutsViewModel$ | async)!"
          [getPageCallback]="getPayouts"
          [getClickCallback]="rowClickedCallback"
          [dataLength]="noData ? 0 : (payoutsCount$ | async)"
          data-test-id="payouts-table"
          [noDataMainText]="'No payout data available'"
          [noDataSecondaryText]="''"
          [hideColumnKeys]="hideColumnKeys"
          [startingPageIndex]="pageIndex"
          [startingPageLimit]="pageSize"
          [scrollable]="true"
        ></tilled-table>
      </div>
    </fuse-card>
  </div>
</div>
