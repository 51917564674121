import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentBase } from 'app/core/componentBase';
import { DEFAULT_PAGE_LIMIT } from 'app/core/constants';
import { AccountStatusPipe } from 'app/core/pipes/account-status.pipe';
import { DateFormatPipe } from 'app/core/pipes/date-format.pipe';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import {
  AccountCapability,
  InternalAccount,
  InternalListConnectedAccountsRequestParams,
} from '../../../../projects/tilled-api-client/src';
import { AccountAppService } from '../../core/services/account.app.service';
import { ChipColorClass, TilledChipConfig } from '../tilled-chip/tilled-chip.component';
import { Column } from '../tilled-table/decorators/column';
import { TilledTableComponent } from '../tilled-table/tilled-table.component';

@Component({
  selector: 'app-connected-account-list',
  templateUrl: './connected-account-list.component.html',
  styleUrls: ['./connected-account-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [TilledTableComponent, AsyncPipe],
})
export class ConnectedAccountListComponent extends ComponentBase implements OnInit {
  @Input() accountId: string;
  @Input() query: string = null;
  @Input() statuses$: Observable<any>;

  public connectedAccounts$: Observable<InternalAccount[]>;
  public connectedAccountsCount$: Observable<number>;
  public viewModels$: Observable<ConnectedAccountViewModel[]>;
  public isLoading = true;
  public pageIndex = 0;
  public pageSize = DEFAULT_PAGE_LIMIT;
  public secondaryReasonText = 'Add a new merchant to see merchant data.';
  public capabilityStatuses: AccountCapability.StatusEnum[] | null;

  constructor(
    private _accountService: AccountAppService,
    private _dateFormatPipe: DateFormatPipe,
    private _router: Router,
    private _statusPipe: AccountStatusPipe,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.connectedAccounts$ = this._accountService.connectedAccountsPaginated$;
    this.connectedAccountsCount$ = this._accountService.connectedAccountsCount$;
    this.statuses$.pipe(takeUntil(this._unsubscribeAll)).subscribe((statuses) => {
      this.capabilityStatuses = statuses;
    });

    this.viewModels$ = this.connectedAccounts$.pipe(
      map((accounts) => this.getViewModelsFromConnectedAccounts(accounts)),
    );
    this.connectedAccountsCount$.pipe(takeUntil(this._unsubscribeAll)).subscribe((res) => {
      if (res === 0) {
        if (this.query) {
          this.secondaryReasonText = 'Update the merchant filter to see results.';
        } else {
          this.secondaryReasonText = 'Add a new merchant to see merchant data.';
        }
      }
    });

    this.getConnectedAccounts(this.pageIndex, this.pageSize);
  }

  getConnectedAccounts = (size: number, index: number, sort?: string): void => {
    const params: InternalListConnectedAccountsRequestParams = {
      tilledAccount: this.accountId,
      offset: index * size,
      limit: size,
      sort: sort,
      q: this.query,
      capabilityStatus: this.capabilityStatuses,
    };
    this._accountService.loadConnectedAccounts(params);
  };

  getViewModelsFromConnectedAccounts(accounts: InternalAccount[]): ConnectedAccountViewModel[] {
    const viewModels: ConnectedAccountViewModel[] = [];
    if (!accounts || accounts.length === 0) {
      const temp: ConnectedAccountViewModel = new ConnectedAccountViewModel();
      viewModels.push(temp);
      return viewModels;
    }
    for (const account of accounts) {
      const temp: ConnectedAccountViewModel = new ConnectedAccountViewModel();
      temp.id = account.id;
      temp.name = account.name;

      if (account.capabilities?.length > 0) {
        temp.status = this._statusPipe.getUnifiedStatus(account.capabilities);
        temp.status_chip_config = this._statusPipe.transform(account, false);
        temp.progress =
          temp.status === AccountCapability.StatusEnum.ACTIVE
            ? 100
            : account.capabilities[0].onboarding_application_progress;
        if (
          temp.status === AccountCapability.StatusEnum.CREATED ||
          temp.status === AccountCapability.StatusEnum.STARTED
        ) {
          temp.accountIdForInvitation = account.id;
        }
      } else {
        temp.status_chip_config = {
          color: ChipColorClass.OPAQUE_RED,
          text: 'REQUIRES PRICING',
          toolTip: '',
          icon: 'heroicons_outline:no-symbol',
        };
        temp.progress = 0;
        temp.accountIdForInvitation = '';
      }
      temp.created_at = this._dateFormatPipe.transform(account.created_at);

      viewModels.push(temp);
    }
    this.isLoading = false;
    return viewModels;
  }

  rowClickedCallback = (data: ConnectedAccountViewModel): void => {
    if (data && data.id) {
      let path = '';
      path += `/merchants/${data.id}`;
      this._router.navigate([path]);
    }
  };
}

export class ConnectedAccountViewModel {
  id: string;

  @Column({
    order: 0,
    name: 'Created Date',
    canSort: true,
    dateTooltip: true,
  })
  created_at: string;

  @Column({
    order: 1,
    name: 'Name',
    canSort: true,
    styling: 'padding-right: 2ch; width: 20ch; max-width: 20ch;',
  })
  name: string;

  @Column({
    order: 2,
    name: 'Application Status',
    isChip: true,
    chipConfig: 'status_chip_config',
  })
  status: string;
  status_chip_config: TilledChipConfig;

  @Column({
    order: 3,
    name: 'Application Progress',
    isProgress: true,
  })
  progress: number;

  @Column({
    order: 4,
    //name: '',
    isShareLink: true,
  })
  accountIdForInvitation: string;

  @Column({
    order: 5,
    isAction: true,
  })
  action: string;
}
