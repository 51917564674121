import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule, CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

import { AccountLocalePipe } from 'app/core/pipes/account-locale.pipe';
import { AccountStatusPipe } from 'app/core/pipes/account-status.pipe';
import { CapabilityStatusPipe } from 'app/core/pipes/capability-status.pipe';
import { CapitalizeTitlePipe } from 'app/core/pipes/capitalize-title.pipe';
import { ChargeStatusPipe } from 'app/core/pipes/charge-status.pipe';
import { DateFormatPipe } from 'app/core/pipes/date-format.pipe';
import { DisputeStatusPipe } from 'app/core/pipes/dispute-status.pipe';
import { DocumentsStatusPipe } from 'app/core/pipes/documents-status.pipe';
import { ReportRunStatusPipe } from 'app/core/pipes/download-status.pipe';
import { MatchingStringToBoldPipe } from 'app/core/pipes/matching-string-to-bold.pipe';
import { MinorUnitsToCurrencyPipe } from 'app/core/pipes/minor-units-to-currency.pipe';
import { NumericToMinorUnitsPipe } from 'app/core/pipes/numeric-to-minor-units.pipe';
import { OutboundTransferStatusPipe } from 'app/core/pipes/outbound-transfer-status.pipe';
import { PaymentMethodPipe } from 'app/core/pipes/payment-method.pipe';
import { PaymentStatusPipe } from 'app/core/pipes/payment-status.pipe';
import { PayoutStatusPipe } from 'app/core/pipes/payout-status.pipe';
import { PhoneNumberPipe } from 'app/core/pipes/phone-number.pipe';
import { ReplaceNullWithStringPipe } from 'app/core/pipes/replace-null-with-string.pipe';
import { ScopeAblePipe, ScopeAbleTooltipPipe } from 'app/core/pipes/scope-able.pipe';
import { SnakeCaseSplitPipe } from 'app/core/pipes/snake-case-split.pipe';
import { StringToSentencePipe } from 'app/core/pipes/string-to-sentence.pipe';
import { TemplateStatusChipConfigPipe } from 'app/core/pipes/template-status-chip-config.pipe';
import { TerminalReaderStatusPipe } from 'app/core/pipes/terminal-reader-status-pipe';
import { TooltipListPipe } from 'app/core/pipes/tooltip-list.pipe';
import { TransactionFeeTypePipe } from 'app/core/pipes/transaction-fee-type-pipe';
import { TruncateStringPipe } from 'app/core/pipes/truncate-string.pipe';
import { AccountDocumentsComponent } from 'app/shared/account-documents/account-documents.component';
import { AccountDocumentsListComponent } from 'app/shared/account-documents/list/account-documents-list.component';
import { AccountDocumentsUploadComponent } from 'app/shared/account-documents/upload/account-documents-upload.component';
import { AllowOnlyNumbersDirective } from 'app/shared/allow-only-numbers.directive';
import { CardPresentPricingCardComponent } from 'app/shared/cards/card-present-pricing-card/card-present-pricing-card.component';
import { DashedEmptyCellDirective } from 'app/shared/dashed-empty-cell.directive';
import { DisputeListComponent } from 'app/shared/disputes/dispute-list.component';
import { FileDownloadComponent } from 'app/shared/file-download/file-download.component';
import { IsOverflowingDirective } from 'app/shared/is-overflowing.directive';
import { BusinessDocumentsStepComponent } from 'app/shared/merchant-app-steps/business-documents/business-documents-step.component';
import { DocusignLoadingComponent } from 'app/shared/merchant-app-steps/docusign-loading/docusign-loading.component';
import { MultiAccountSelectorComponent } from 'app/shared/multi-account-selector/multi-account-selector.component';
import { MultiSelectSearchComponent } from 'app/shared/multi-select-search/multi-select-search.component';
import { CancelPaymentDialogComponent } from 'app/shared/payments/cancel-payment-dialog/cancel-payment-dialog.component';
import { CapturePaymentDialogComponent } from 'app/shared/payments/capture-payment-dialog/capture-payment-dialog.component';
import { CardPresentPricingTemplateListItemComponent } from 'app/shared/pricing-templates/card-present-pricing-template-list-item/card-present-pricing-template-list-item.component';
import { TerminalReaderListComponent } from 'app/shared/terminals/terminal-list/terminal-reader-list.component';
import { TerminalReaderUpdateDialogComponent } from 'app/shared/terminals/update-terminal-dialog/terminal-reader-update-dialog.component';
import { TilledChipListComponent } from 'app/shared/tilled-chip-list/tilled-chip-list.component';
import { TilledFormHeadingComponent } from 'app/shared/tilled-text/tilled-heading/tilled-form-heading';
import { RevokeAccessDialogComponent } from 'app/shared/user/revoke-access-dialog/revoke-access-dialog.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { MarkdownModule } from 'ngx-markdown';
import { NgxMaskModule } from 'ngx-mask';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { SentenceCasePipe } from './../core/pipes/sentence-case.pipe';
import { ActionListComponent } from './action-list/action-list.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { TilledButtonComponent } from './buttons/tilled-button.component';
import { CalendarPickerComponent } from './calendar-picker/calendar-picker.component';
import { CardPricingCardComponent } from './cards/card-pricing-card/card-pricing-card.component';
import { ColorTextCardComponent } from './cards/color-text-card/color-text-card.component';
import { DebitPricingCardComponent } from './cards/debit-pricing-card/debit-pricing-card.component';
import { FormCardComponent } from './cards/form-cards/form-card.component';
import { MerchantAppAlertComponent } from './cards/merchant-application/merchant-app-alert/merchant-app-alert.component';
import { MerchantAppCardComponent } from './cards/merchant-application/merchant-app-card/merchant-app-card.component';
import { PartnerApplicationSummaryCardComponent } from './cards/partner-application/app-summary-card.component';
import { PaymentCardComponent } from './cards/payment-cards/payment-cards.component';
import { TilledCardUnderlineComponent } from './cards/tilled-card-underline/tilled-card-underline.component';
import { TimelineCardComponent } from './cards/timeline-card/timeline-card.component';
import { CompanyProfileComponent } from './company-profile/company-profile.component';
import { ConnectedAccountDialogComponent } from './connected-account/connected-account-dialog/connected-account-dialog.component';
import { ConnectedAccountListComponent } from './connected-account/connected-account-list.component';
import { DashedLineContainerComponent } from './containers/dashed-line-container/dashed-line-container.component';
import { CustomerDialogComponent } from './customers/customer-dialog/customer-dialog.component';
import { DateRangeHeaderComponent } from './date-range-picker/date-range-header/date-range-header.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { TimePeriodPanelComponent } from './date-range-picker/time-period-panel/time-period-panel.component';
import { DateTooltipComponent } from './date-tooltip/date-tooltip.component';
import { DragAndDropDirective } from './file-upload/drag-and-drop.directive';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { TilledInputComponent } from './form-fields/tilled-input/tilled-input.component';
import { LineItemsComponent } from './line-items-component.ts/line-items.component';
import { LogoLoadedDirective } from './logo-loaded.directive';
import { MerchantAppInvitationDialogComponent } from './merchant-app-invitation/merchant-app-invitation-dialog.component';
import { BankAccountStepComponent } from './merchant-app-steps/bank-account/bank-account-step.component';
import { BusinessContactStepComponent } from './merchant-app-steps/business-contact-information/business-contact-step.component';
import { BusinessDetailsStepComponent } from './merchant-app-steps/business-details/business-details-step.component';
import { ProcessingVolumesMerchantStepComponent } from './merchant-app-steps/processing-volumes/processing-volumes-step.component';
import { RepresentativesStepComponent } from './merchant-app-steps/representatives/representatives-step.component';
import { SubmitApplicationStepComponent } from './merchant-app-steps/submit-application/submit-application-step.component';
import { MerchantAppUnsavedDialogComponent } from './merchant-app-unsaved/merchant-app-unsaved-dialog.component';
import { MerchantSelectorComponent } from './merchant-selector/merchant-selector.component';
import { MetadataDialogComponent } from './metadata-dialog/metadata-dialog.component';
import { MetadataCardComponent } from './metadata/metadata-card.component';
import { OutboundTransferListComponent } from './outbound-transfers/outbound-transfer-list.component';
import { SendOutboundTransferDialogComponent } from './outbound-transfers/send-outbound-transfer-dialog/send-outbound-transfer-dialog.component';
import { OutlineInputComponent } from './outline-input/outline-input.component';
import { CollectPaymentDialogComponent } from './payments/collect-payment-dialog/collect-payment-dialog.component';
import { CopyPaymentLinkDialogComponent } from './payments/copy-payment-link-dialog/copy-payment-link-dialog.component';
import { CreatePaymentLinkDialogComponent } from './payments/create-payment-link-dialog/create-payment-link-dialog.component';
import { PaymentListComponent } from './payments/payment-list.component';
import { PayoutListComponent } from './payouts/payout-list.component';
import { CardPricingTemplateListItemComponent } from './pricing-templates/card-pricing-template-list-item/card-pricing-template-list-item.component';
import { DebitPricingTemplateListItemComponent } from './pricing-templates/debit-pricing-template-list-item/debit-pricing-template-list-item.component';
import { EditablePricingTemplateNicknameComponent } from './pricing-templates/editable-pricing-template-nickname/editable-pricing-template-nickname.component';
import { BillingPayoutScheduleDialogComponent } from './processing-details/billing-payout-schedule-dialog/billing-payout-schedule-dialog.component';
import { ProcessingDetailsComponent } from './processing-details/processing-details.component';
import { ReportRunListComponent } from './report-run/report-run-list.component';
import { StepperHeaderComponent } from './stepper-components/stepper-header/stepper-header.component';
import { TilledAlertComponent } from './tilled-alert/tilled-alert.component';
import { TilledCardRowComponent } from './tilled-card-row/tilled-card-row.component';
import { TilledChipComponent } from './tilled-chip/tilled-chip.component';
import { TilledProgressBarComponent } from './tilled-progress-bar/tilled-progress-bar.component';
import { TilledSearchComponent } from './tilled-search/tilled-search.component';
import { TilledSelectComponent } from './tilled-select/tilled-select.component';
import { TilledSwitchComponent } from './tilled-switch/tilled-switch.component';
import { TilledTableComponent } from './tilled-table/tilled-table.component';
import { TilledButtonB1Component } from './tilled-text/tilled-button/tilled-button-b1.component';
import { TilledButtonB2Component } from './tilled-text/tilled-button/tilled-button-b2.component';
import { TilledHeadingH1Component } from './tilled-text/tilled-heading/tilled-heading-h1.component';
import { TilledHeadingH2Component } from './tilled-text/tilled-heading/tilled-heading-h2.component';
import { TilledHeadingH3Component } from './tilled-text/tilled-heading/tilled-heading-h3.component';
import { TilledHeadingH4Component } from './tilled-text/tilled-heading/tilled-heading-h4.component';
import { TilledHeadingH5Component } from './tilled-text/tilled-heading/tilled-heading-h5.component';
import { TilledHeadingH6Component } from './tilled-text/tilled-heading/tilled-heading-h6.component';
import { TilledLabelL1Component } from './tilled-text/tilled-label/tilled-label-l1.component';
import { TilledLabelL2Component } from './tilled-text/tilled-label/tilled-label-l2.component';
import { TilledParagraphP1Component } from './tilled-text/tilled-paragraph/tilled-paragraph-p1.component';
import { TilledParagraphP2Component } from './tilled-text/tilled-paragraph/tilled-paragraph-p2.component';
import { TilledParagraphP3Component } from './tilled-text/tilled-paragraph/tilled-paragraph-p3.component';
import { TilledParagraphP4Component } from './tilled-text/tilled-paragraph/tilled-paragraph-p4.component';
import { UserInviteDialogComponent } from './user/user-dialog/user-invite-dialog.component';
import { UserInvitationListComponent } from './user/user-invitation-list/user-invitation-list.component';
import { UserListComponent } from './user/user-list.component';

const classesToInclude = [
  TruncateStringPipe,
  MatchingStringToBoldPipe,
  MinorUnitsToCurrencyPipe,
  DateFormatPipe,
  PayoutStatusPipe,
  SnakeCaseSplitPipe,
  CapitalizeTitlePipe,
  SentenceCasePipe,
  ReplaceNullWithStringPipe,
  ScopeAblePipe,
  ScopeAbleTooltipPipe,
  TransactionFeeTypePipe,
  ActionListComponent,
  MerchantSelectorComponent,
  MultiAccountSelectorComponent,
  PartnerApplicationSummaryCardComponent,
  TilledButtonComponent,
  TilledChipListComponent,
  TilledInputComponent,
  TimePeriodPanelComponent,
  DateRangeHeaderComponent,
  DateRangePickerComponent,
  DateTooltipComponent,
  CalendarPickerComponent,
  TilledChipComponent,
  TilledCardRowComponent,
  ReportRunListComponent,
  TilledProgressBarComponent,
  ConnectedAccountListComponent,
  TilledTableComponent,
  TilledSearchComponent,
  TilledSelectComponent,
  MetadataDialogComponent,
  TilledHeadingH1Component,
  TilledHeadingH2Component,
  TilledHeadingH3Component,
  TilledHeadingH4Component,
  TilledHeadingH5Component,
  TilledHeadingH6Component,
  TilledFormHeadingComponent,
  TilledParagraphP1Component,
  TilledParagraphP2Component,
  TilledParagraphP3Component,
  TilledParagraphP4Component,
  TilledLabelL1Component,
  TilledLabelL2Component,
  TilledButtonB1Component,
  TilledButtonB2Component,
  PhoneNumberPipe,
  AccountLocalePipe,
  AccountStatusPipe,
  TooltipListPipe,
  CapabilityStatusPipe,
  DashedLineContainerComponent,
  FormCardComponent,
  PaymentCardComponent,
  TilledSwitchComponent,
  OutlineInputComponent,
  StepperHeaderComponent,
  UserListComponent,
  PayoutListComponent,
  MetadataCardComponent,
  CardPricingTemplateListItemComponent,
  CardPresentPricingTemplateListItemComponent,
  DebitPricingTemplateListItemComponent,
  EditablePricingTemplateNicknameComponent,
  UserInviteDialogComponent,
  UserInvitationListComponent,
  RevokeAccessDialogComponent,
  ConnectedAccountDialogComponent,
  ColorTextCardComponent,
  PaymentListComponent,
  PaymentStatusPipe,
  TerminalReaderStatusPipe,
  ChargeStatusPipe,
  PaymentMethodPipe,
  ReportRunStatusPipe,
  TemplateStatusChipConfigPipe,
  CollectPaymentDialogComponent,
  CapturePaymentDialogComponent,
  CancelPaymentDialogComponent,
  LineItemsComponent,
  LogoLoadedDirective,
  CustomerDialogComponent,
  MerchantAppCardComponent,
  MerchantAppAlertComponent,
  CardPricingCardComponent,
  CardPresentPricingCardComponent,
  DebitPricingCardComponent,
  MerchantAppInvitationDialogComponent,
  MerchantAppUnsavedDialogComponent,
  BusinessDetailsStepComponent,
  BusinessContactStepComponent,
  ProcessingVolumesMerchantStepComponent,
  RepresentativesStepComponent,
  BankAccountStepComponent,
  SubmitApplicationStepComponent,
  TilledAlertComponent,
  FileDownloadComponent,
  CreatePaymentLinkDialogComponent,
  CopyPaymentLinkDialogComponent,
  FileUploadComponent,
  CompanyProfileComponent,
  ProcessingDetailsComponent,
  BillingPayoutScheduleDialogComponent,
  OutboundTransferStatusPipe,
  TilledCardUnderlineComponent,
  TimelineCardComponent,
  OutboundTransferListComponent,
  SendOutboundTransferDialogComponent,
  DisputeListComponent,
  MultiSelectSearchComponent,
  AccountDocumentsComponent,
  AccountDocumentsListComponent,
  AccountDocumentsUploadComponent,
  BusinessDocumentsStepComponent,
  TerminalReaderListComponent,
  TerminalReaderUpdateDialogComponent,
  DocusignLoadingComponent,
];

const materialModules = [
  MatChipsModule,
  MatAutocompleteModule,
  MatButtonModule,
  MatDialogModule,
  MatIconModule,
  MatSidenavModule,
  MatProgressBarModule,
  MatCheckboxModule,
  MatSelectModule,
  MatFormFieldModule,
  MatInputModule,
  MatTabsModule,
  MatRadioModule,
  MatExpansionModule,
  MatPaginatorModule,
  MatTableModule,
  MatFormFieldModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatInputModule,
  MatTooltipModule,
  MatCardModule,
  MatDividerModule,
  MatSortModule,
  MatMenuModule,
  MatSlideToggleModule,
  MatStepperModule,
  MatProgressSpinnerModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatDialogModule,
];

@NgModule({
  imports: [
    MarkdownModule.forChild(),
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    MatSelectModule,
    ClipboardModule,
    NgxTippyModule,
    NgxMaskModule.forRoot(),
    ...materialModules,
    classesToInclude,
    AutocompleteComponent,
    DragAndDropDirective,
    AllowOnlyNumbersDirective,
    DashedEmptyCellDirective,
    IsOverflowingDirective,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AutocompleteComponent,
    ...classesToInclude,
    ...materialModules,
  ],
  providers: [
    CurrencyPipe,
    DatePipe,
    DateFormatPipe,
    PayoutStatusPipe,
    PercentPipe,
    PhoneNumberPipe,
    AccountLocalePipe,
    AccountStatusPipe,
    CapabilityStatusPipe,
    TerminalReaderStatusPipe,
    PaymentStatusPipe,
    ChargeStatusPipe,
    PaymentMethodPipe,
    ReportRunStatusPipe,
    TemplateStatusChipConfigPipe,
    NumericToMinorUnitsPipe,
    StringToSentencePipe,
    SentenceCasePipe,
    MatchingStringToBoldPipe,
    TruncateStringPipe,
    TooltipListPipe,
    OutboundTransferStatusPipe,
    DisputeStatusPipe,
    DocumentsStatusPipe,
  ],
})
export class SharedModule {}
