import { Pipe, PipeTransform } from '@angular/core';

/**
 * Takes string and capitalizes words according to title case rules.
 */
@Pipe({
  name: 'capitalizeTitle',
  standalone: true,
})
export class CapitalizeTitlePipe implements PipeTransform {
  /**
   *
   * @param value string
   * @returns
   */
  transform(value: string): string {
    const dontCapitalize = [
      'of',
      'the',
      'and',
      'in',
      'on',
      'at',
      'to',
      'for',
      'with',
      'as',
      'by',
      'or',
      'but',
      'nor',
      'a',
      'an',
    ];
    const toUpperCase = ['API', 'URL', 'ID'];

    if (value) {
      const words = value.toLowerCase().split(' ');

      const transformed = words.map((word, index) => {
        if (toUpperCase.includes(word.toUpperCase())) {
          return word.toUpperCase();
        }
        if (index === 0 || !dontCapitalize.includes(word)) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }
        return word;
      });

      return transformed.join(' ');
    }

    return value;
  }
}
