import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { DocumentDto, ListDocumentsRequestParams } from '@tilled-api-client';
import { ComponentBase } from 'app/core/componentBase';
import { DateFormatPipe } from 'app/core/pipes/date-format.pipe';
import { DocumentsStatusPipe } from 'app/core/pipes/documents-status.pipe';
import { AuthService } from 'app/core/services/auth.service';
import { DocumentsAppService } from 'app/core/services/documents.app.service';
import { mapSubtypeToTitle } from 'app/shared/utils/onboarding-utils';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { TilledChipConfig } from '../../tilled-chip/tilled-chip.component';
import { Column } from '../../tilled-table/decorators/column';
import { TilledTableComponent } from '../../tilled-table/tilled-table.component';

@Component({
  selector: 'app-account-documents-list',
  templateUrl: './account-documents-list.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [TilledTableComponent, AsyncPipe],
})
export class AccountDocumentsListComponent extends ComponentBase implements OnInit, OnChanges {
  @Input() accountId: string = null;
  @Input() pageIndex: number = 0;
  @Input() pageSize: number = 10;

  public documents$: Observable<DocumentDto[]>;
  public documentsViewModel$: Observable<DocumentsViewModel[]>;
  public documentsListCount$: Observable<number>;

  constructor(
    private _authService: AuthService,
    private _documentsService: DocumentsAppService,
    private _dateFormatPipe: DateFormatPipe,
    private _documentsStatusPipe: DocumentsStatusPipe,
  ) {
    super();
  }

  async ngOnInit(): Promise<void> {
    this.documents$ = this._documentsService.documentsList$;
    this.documentsListCount$ = this._documentsService.documentsListCount$;
    this.documentsViewModel$ = this.documents$.pipe(map((documents) => this.getViewModelsFromDocuments(documents)));

    this._authService.account$.pipe(takeUntil(this._unsubscribeAll)).subscribe((account) => {
      this.getDocuments(this.pageSize, this.pageIndex);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    let firstChange = true;

    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        firstChange = firstChange && changes[propName].isFirstChange();
      }
    }

    if (!firstChange) {
      this.getDocuments(this.pageSize, this.pageIndex);
    }
  }

  getDocuments = (size: number, index: number): void => {
    const params: ListDocumentsRequestParams = {
      tilledAccount: this.accountId,
      offset: size * index,
      limit: size,
    };
    this._documentsService.getDocumentsList(params);
  };

  getViewModelsFromDocuments(documents: DocumentDto[]): DocumentsViewModel[] {
    const viewModels: DocumentsViewModel[] = [];
    if (!documents || documents.length === 0) {
      const temp: DocumentsViewModel = new DocumentsViewModel();
      viewModels.push(temp);
      return viewModels;
    }

    for (const document of documents) {
      const temp: DocumentsViewModel = new DocumentsViewModel();
      temp.id = document.id;
      temp.account_id = document.account_id;
      temp.documentDescription = mapSubtypeToTitle(document.subtype);
      temp.documentRequestNotes = document.request_notes;
      temp.status = document.status;
      temp.status_chip_config = this._documentsStatusPipe.transform(document);
      temp.created_at = this._dateFormatPipe.transform(document.created_at);
      temp.submitted_at =
        document.status === DocumentDto.StatusEnum.REQUESTED
          ? 'Awaiting document'
          : document.submitted_at
            ? this._dateFormatPipe.transform(document.submitted_at)
            : 'No upload';

      viewModels.push(temp);
    }
    return viewModels;
  }
}

export class DocumentsViewModel {
  // non display
  id: string;
  account_id: string;
  chipConfig: TilledChipConfig;
  documentRequestNotes: string;

  @Column({
    order: 0,
    name: 'Document',
    styling: 'min-width:250px',
    cellTooltip: (documentDescription: string, rowVM: DocumentsViewModel) => {
      if (rowVM?.documentRequestNotes) {
        return rowVM.documentRequestNotes;
      }
      return null;
    },
    showCellTooltipIcon: true,
  })
  documentDescription: string;

  @Column({
    order: 1,
    name: 'Status',
    isChip: true,
    chipConfig: 'status_chip_config',
    styling: 'min-width:210px;',
  })
  status: string;
  status_chip_config: TilledChipConfig;

  @Column({
    order: 2,
    name: 'Date Requested',
    styling: 'min-width:180px;',
    dateTooltip: true,
  })
  created_at: string;

  @Column({
    order: 3,
    name: 'Date Uploaded',
    styling: 'min-width:180px',
    dateTooltip: true,
  })
  submitted_at: string;
}
