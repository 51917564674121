<ng-container *ngIf="showCard">
  <div class="w-full pb-2">
    <tilled-heading-h4>{{ label }}</tilled-heading-h4>
  </div>
  <!-- Card Present Template Details -->
  <ng-container>
    <div class="w-full">
      <div class="my-[5px]">
        <tilled-paragraph-p3
          [secondary]="true"
          data-test-id="currency-and-feetype"
          class="justify-self-center sm:justify-self-end"
        >
          {{ currency | uppercase }} / {{ feeType | transactionFeeType }}
          {{ passThroughFees === true ? ' w/ pass-through fees' : '' }}
        </tilled-paragraph-p3>
      </div>
      <mat-divider class="mb-4 mt-3 w-full"></mat-divider>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true">
          Visa Discount Rate</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 data-test-id="visa-rate" class="justify-self-center sm:justify-self-end">
          {{ visaRate | percent: '0.2-2' }}
        </tilled-paragraph-p3>
      </div>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true">
          Amex Discount Rate</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 data-test-id="amex-rate" class="justify-self-center sm:justify-self-end">
          {{ amexRate | percent: '0.2-2' }}
        </tilled-paragraph-p3>
      </div>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true"
          >Transaction Fee</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 data-test-id="transaction-fee" class="justify-self-center sm:justify-self-end">
          {{ transactionFee | minorUnitsToCurrency: currency }}
        </tilled-paragraph-p3>
      </div>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true"
          >Chargeback Fee</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 data-test-id="chargeback-fee" class="justify-self-center sm:justify-self-end">
          {{ chargebackFee | minorUnitsToCurrency: currency }}
        </tilled-paragraph-p3>
      </div>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true"
          >Retrieval Fee</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 data-test-id="retrieval-fee" class="justify-self-center sm:justify-self-end">
          {{ retrievalFee | minorUnitsToCurrency: currency }}
        </tilled-paragraph-p3>
      </div>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true"
          >Reversal Fee</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 data-test-id="reversal-fee" class="justify-self-center sm:justify-self-end">
          {{ reversalFee | minorUnitsToCurrency: currency }}
        </tilled-paragraph-p3>
      </div>
      <mat-divider class="mb-4 mt-3 w-full"></mat-divider>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true"
          >Bank Account Change Fee</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 data-test-id="bank-account-change-fee" class="justify-self-center sm:justify-self-end">
          {{ bankAccountChangeFee | minorUnitsToCurrency: currency }}
        </tilled-paragraph-p3>
      </div>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true"
          >Monthly Account Fee</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 data-test-id="account-monthly-fee" class="justify-self-center sm:justify-self-end">
          {{ accountMonthlyFee | minorUnitsToCurrency: currency }}
        </tilled-paragraph-p3>
      </div>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true"
          >Monthly Minimum Fee</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 data-test-id="account-monthly-minimum-fee" class="justify-self-center sm:justify-self-end">
          {{ accountMonthlyMinimumFee | minorUnitsToCurrency: currency }}
        </tilled-paragraph-p3>
      </div>
      <div class="my-[5px] grid w-full grid-cols-1 sm:grid-cols-2">
        <tilled-paragraph-p3 class="justify-self-center sm:justify-self-start" [secondary]="true"
          >Monthly Terminal Fee</tilled-paragraph-p3
        >
        <tilled-paragraph-p3 data-test-id="monthly-terminal-fee" class="justify-self-center sm:justify-self-end">
          {{ monthlyTerminalFee | minorUnitsToCurrency: currency }}
        </tilled-paragraph-p3>
      </div>
    </div>
  </ng-container>
</ng-container>
