import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatProgressBarModule, ProgressBarMode } from '@angular/material/progress-bar';

@Component({
  selector: 'tilled-progress-bar',
  templateUrl: './tilled-progress-bar.component.html',
  styleUrls: ['./tilled-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatProgressBarModule],
})
export class TilledProgressBarComponent implements OnInit {
  @Input() value: number;
  @Input() mode?: ProgressBarMode = 'determinate';
  public colorClass: string;

  constructor() {}

  ngOnInit(): void {
    this.colorClass = this.color(this.value);
  }

  color(percentage: number): string {
    if (percentage > 50 && percentage < 100) {
      return 'yellow';
    } else if (percentage <= 50) {
      return 'orange';
    } else if (percentage === 100) {
      return 'green';
    }
  }
}
