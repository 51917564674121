import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AUTH_LOGIN_ROUTE, HOMEPAGE_ROUTE } from 'app/core/constants';
import { StatusCodes } from 'http-status-codes';
import { Observable } from 'rxjs';
import { AuthLinkLoginDto, AuthLinksService } from '../../../../../projects/tilled-api-client/src';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeLinkGuard {
  //, CanActivateChild {
  private authLink$: Observable<AuthLinkLoginDto>;

  constructor(
    private authService: AuthService,
    private authLinkService: AuthLinksService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!route.queryParams['key']) {
      this.router.navigate(['/' + AUTH_LOGIN_ROUTE]);
      return false;
    }

    this.authLink$ = this.authService.redeemAuthLink(route.queryParams['key']);

    this.authLink$.subscribe({
      next: (result) => {
        // if user is 'logged in' through magic link, redirect to url
        if (this.authService.user || !AuthService.isTokenExpired() || !AuthService.isRefreshTokenExpired()) {
          if (result.redirect_url) {
            this.router.navigate([result.redirect_url]);
          } else {
            this.router.navigate(['/' + HOMEPAGE_ROUTE]);
          }
          // if user is not 'logged in' but we have a redirect url,
          // their tokens probably got cleared, need new ones
        } else {
          this.router.navigate(['/enroll/expired']);
        }
      },
      error: (err) => {
        if (err.status === StatusCodes.UNAUTHORIZED) {
          this.router.navigate(['/enroll/expired']);
        } else {
          this.router.navigate(['/' + AUTH_LOGIN_ROUTE]);
        }
      },
    });
  }

  // only executed if child route is called. right now there are no child routes for auth/magic-link
  // if we were to add them, then we would want to implement this very similar/same as canActivate
  /*canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {

    }*/
}
