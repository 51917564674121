import { CommonModule, TitleCasePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { FuseAlertService } from '@fuse/components/alert';
import { CapitalizeTitlePipe } from 'app/core/pipes/capitalize-title.pipe';
import { TilledFormHeadingComponent } from 'app/shared/tilled-text/tilled-heading/tilled-form-heading';
import { Observable, Subscription } from 'rxjs';
import { FuseAlertComponent } from '../../../../@fuse/components/alert/alert.component';
import { TilledButtonComponent } from '../../buttons/tilled-button.component';
import { StepperHeaderComponent } from '../../stepper-components/stepper-header/stepper-header.component';

@Component({
  selector: 'form-card',
  templateUrl: './form-card.component.html',
  styleUrls: ['./form-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatIconModule,
    StepperHeaderComponent,
    TilledFormHeadingComponent,
    FuseAlertComponent,
    TilledButtonComponent,
    CommonModule,
  ],
})
export class FormCardComponent implements OnInit, OnChanges, OnDestroy {
  @Output() exitClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() nextClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() backClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() submitClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() centerClick: EventEmitter<string> = new EventEmitter<string>();
  @Output() leftClick: EventEmitter<string> = new EventEmitter<string>();

  @Input() nextButton?: boolean = false;
  @Input() backButton?: boolean = false;
  @Input() submitButton?: boolean = false;
  @Input() centerButton?: boolean = false;
  @Input() leftButton?: boolean = false;
  @Input() exitButton?: boolean = true;
  @Input() nextDisabled?: boolean = false;
  @Input() submitDisabled?: boolean = false;
  @Input() submitText?: string = 'Submit';
  @Input() leftButtonText?: string = 'Back';
  @Input() backButtonText?: string = 'Back';
  @Input() nextButtonText?: string = 'Next';
  @Input() submitButtonBg?: string = 'tilled-primary';
  @Input() backButtonBorder?: string = 'tilled-primary';
  @Input() buttonPosition: ButtonPositionEnum;
  @Input() displayAlert?: boolean = false;
  @Input() showAlert$?: Observable<boolean>;
  @Input() alertTitle?: string;
  @Input() alertMessage?: string;
  @Input() titleText: string;
  @Input() capitalizeTitle: boolean = true;
  @Input() titleCaseTitle: boolean = false;
  @Input() capitalizeSubmitText: boolean = true;
  @Input() secondaryText: string;
  @Input() stepTitlesArray?: string[];
  @Input() stepNumber?: number = 1;
  @Input() styles: string = '';
  @Input() outterStyles: string = '';
  @Input() minWidth?: WidthEnum | string = WidthEnum.small; // small (24rem), medium(42rem), large(60rem), or custom
  @Input() maxWidth?: WidthEnum | string = 'max-w-120'; // small, medium, large, or custom
  @Input() testIds?: TestIdsObject;
  @Input() logoUrl$: Observable<string>;
  @Input() merchantApp: boolean = false;

  private _titleCase = new TitleCasePipe();
  private _capitalizeTitle = new CapitalizeTitlePipe();
  private showAlertSub: Subscription;

  constructor(private _fuseAlertService: FuseAlertService) {}

  ngOnInit() {
    if (this.showAlert$) {
      this.showAlertSub = this.showAlert$.subscribe((res) => {
        if (res) {
          this._fuseAlertService.show('formCardAlertBox');
        }
      });
    }
    if (this.titleText && this.capitalizeTitle) {
      this.titleText = this._capitalizeTitle.transform(this.titleText);
    }
    if (this.titleText && this.titleCaseTitle) {
      this.titleText = this._titleCase.transform(this.titleText);
    }
    if (this.alertTitle) {
      this.alertTitle = this._capitalizeTitle.transform(this.alertTitle);
    }
    if (this.submitText && this.capitalizeSubmitText) {
      this.submitText = this._capitalizeTitle.transform(this.submitText);
    }
    if (this.stepTitlesArray) {
      this.stepTitlesArray = this.stepTitlesArray.map((step) => {
        return this._capitalizeTitle.transform(step);
      });
    }
    switch (this.minWidth) {
      case WidthEnum.small:
        this.styles += ' min-w-[24rem]';
        break;
      case WidthEnum.medium:
        this.styles += ' min-w-[42rem]';
        break;
      case WidthEnum.large:
        this.styles += ' min-w-[60rem]';
        break;
      default:
        this.styles += ' ' + this.minWidth;
        break;
    }
    switch (this.maxWidth) {
      case WidthEnum.small:
        this.styles += ' max-w-[24rem]';
        break;
      case WidthEnum.medium:
        this.styles += ' max-w-[42rem]';
        break;
      case WidthEnum.large:
        this.styles += ' max-w-[60rem]';
        break;
      default:
        this.styles += ' ' + this.maxWidth;
        break;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.alertMessage) {
      this._fuseAlertService.show('formCardAlertBox');
    }
  }

  ngOnDestroy(): void {
    if (this.showAlertSub) {
      this.showAlertSub.unsubscribe();
    }
  }

  showAlert() {
    this._fuseAlertService.show('formCardAlertBox');
  }

  onButtonClick(text) {
    switch (text) {
      case 'exit':
        this.exitClick.emit();
        break;
      case 'next':
        this.nextClick.emit();
        break;
      case 'submit':
        this.submitClick.emit();
        break;
      case 'back':
        this.backClick.emit();
        break;
      case 'center':
        this.centerClick.emit();
        break;
      case 'left':
        this.leftClick.emit();
        break;
    }
  }
}

export enum ButtonPositionEnum {
  left = 'left',
  center = 'center',
}

export enum WidthEnum {
  small = 'small',
  medium = 'medium',
  large = 'large',
}

export interface TestIdsObject {
  backButton: string;
  nextButton: string;
  submitButton: string;
  centerButton: string;
  leftButton: string;
  titleText: string;
  secondaryText: string;
  headerTestId: string;
}
