<div>
  <tilled-table
    [data]="paymentsViewModel$ | async"
    [getPageCallback]="getPaymentIntents"
    [dataLength]="noData ? 0 : (paymentsCount$ | async)"
    data-test-id="payments-table"
    [noDataMainText]="'No payments found'"
    [noDataSecondaryText]="secondaryReasonText"
    [getClickCallback]="rowClickedCallback"
    [hideColumnKeys]="hideColumnKeys"
    [scrollable]="true"
    [showDisplayedColumnsDropdown]="false"
    [startingPageIndex]="pageIndex"
    [startingPageLimit]="pageSize"
  ></tilled-table>
</div>
