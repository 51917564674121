import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { InternalAccount, ListUsersRequestParams, UpdateUserRequestParams, User } from '@tilled-api-client';
import { DEFAULT_PAGE_LIMIT } from 'app/core/constants';
import { DateFormatPipe } from 'app/core/pipes/date-format.pipe';
import { AuthService } from 'app/core/services/auth.service';
import { UsersAppService } from 'app/core/services/users.app.service';
import {
  IRevokeAccessDialogComponentData,
  IRevokeAccessDialogComponentResponse,
  RevokeAccessDialogComponent,
} from 'app/shared/user/revoke-access-dialog/revoke-access-dialog.component';
import { Observable, Subject, concat } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { UserRoles } from '../../core/data/user-roles';
import { ActionListItem } from '../action-list/action-list.model';
import { Column } from '../tilled-table/decorators/column';
import { TilledTableComponent } from '../tilled-table/tilled-table.component';
import { UserInviteDialogComponent } from './user-dialog/user-invite-dialog.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [TilledTableComponent, AsyncPipe],
})
export class UserListComponent implements OnInit, OnChanges, OnDestroy {
  @Input() account: InternalAccount;

  public users$: Observable<User[]>;
  public usersViewModel$: Observable<UserViewModel[]>;
  public usersCount$: Observable<number>;

  public isLoading = true;
  public pageIndex = 0;
  public pageSize = DEFAULT_PAGE_LIMIT;
  public sortInfo = null;
  public isMerchantUser = false;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private _usersAppService: UsersAppService,
    private _matDialog: MatDialog,
    private _dateFormatPipe: DateFormatPipe,
    private _authService: AuthService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.users$ = this._usersAppService.users$;
    this.usersCount$ = this._usersAppService.usersCount$;

    this.usersViewModel$ = this.users$.pipe(map((users) => this.getViewModelsFromUsers(users)));

    this.getUsers(this.pageSize, this.pageIndex);

    this.isMerchantUser = this._authService.isMerchantUser();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.account && !changes.account.firstChange) {
      this.getUsers(this.pageSize, this.pageIndex);
    }
  }

  ngOnDestroy(): void {
    this._usersAppService.unsubscribeFromUserService();
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  getUsers = (size: number, index: number): void => {
    const usersParams: ListUsersRequestParams = {
      tilledAccount: this.account.id,
      offset: size * index,
      limit: size,
      includeConnectedAccounts: false,
    };
    this._usersAppService.getAllUsers(usersParams);
  };

  getViewModelsFromUsers(users: User[]): UserViewModel[] {
    const viewModels: UserViewModel[] = [];
    if (!users || users.length === 0) {
      const temp: UserViewModel = new UserViewModel();
      viewModels.push(temp);
      return viewModels;
    }
    for (const user of users) {
      let lastLogin = 'Never';
      if (user.last_login_at) {
        lastLogin = this._dateFormatPipe.transform(user.last_login_at);
      }

      const temp: UserViewModel = new UserViewModel();
      temp.user_name = [];
      temp.user_name.push(user.name);
      temp.user_name.push(user.email);
      temp.last_login = lastLogin;
      const role = user.account_roles?.find((au) => au.account_id === this.account.id)?.role || user.role;
      temp.role = UserRoles.DisplayText.get(role);
      temp.verification_code = user.verification_code;
      temp.actionList = [];
      let editDisabled = false;
      if (
        role === User.RoleEnum.OWNER ||
        role === User.RoleEnum.MERCHANT_OWNER ||
        !this._authService.isScopeAble('users:write')
      ) {
        editDisabled = true;
      }

      let deleteDisabled = false;
      if (role === User.RoleEnum.OWNER || !this._authService.isScopeAble('users:write')) {
        deleteDisabled = true;
      }

      // we are no longer showing roles for merchant users, so there is nothing to edit
      if (!this.isMerchantUser)
        temp.actionList.push(
          new ActionListItem({
            name: 'Edit role',
            callback: (): void => {
              this.editUser(user);
            },
            disabled: editDisabled,
          }),
        );
      temp.actionList.push(
        new ActionListItem({
          name: 'Revoke access',
          callback: (): void => {
            this.deleteUser(user);
          },
          disabled: deleteDisabled,
        }),
      );

      viewModels.push(temp);
    }
    return viewModels;
  }

  deleteUser = (user: User): void => {
    const data: IRevokeAccessDialogComponentData = {
      user: user,
      accountId: this.account.id,
      accountName: this.account.name,
    };

    const dialogRef = this._matDialog.open(RevokeAccessDialogComponent, {
      data,
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((response: IRevokeAccessDialogComponentResponse) => {
        if (response?.revokeAccountIds.length > 0) {
          const observables: Observable<void>[] = [];
          for (const accountId of response.revokeAccountIds) {
            // TODO MCD-504 Maybe send one alert instead of multiple alerts.
            observables.push(
              this._usersAppService.deleteUser({
                tilledAccount: accountId,
                id: user.id,
              }),
            );
          }
          console.log(`Trying to execute ${observables.length} delete commands.`);
          // Ensure that they run sequentially for now.
          // And not all at once - since we don't have locking on the user at this time.
          concat(...observables).subscribe();
        }
      });
  };

  editUser = (userData: User): void => {
    const dialogRef = this._matDialog.open(UserInviteDialogComponent, {
      panelClass: 'user-invite-dialog',
      data: {
        action: 'edit',
        user: this.getViewModelsFromUsers([userData])[0],
        isMerchantUser: this.isMerchantUser,
      },
    });

    dialogRef.afterClosed().subscribe((response: FormGroup) => {
      if (!response) {
        return;
      }

      const params: UpdateUserRequestParams = {
        tilledAccount: this.account.id,
        id: userData.id,
        userUpdateParams: {
          role: response.getRawValue().role,
        },
      };

      this._usersAppService.updateUser(params);
    });
  };
}

export class UserViewModel {
  @Column({
    order: 0,
    name: 'User Name',
    isMultiline: true,
  })
  user_name: string[];

  @Column({
    order: 1,
    name: 'Role',
  })
  role: string;

  @Column({
    order: 2,
    name: 'Last Login',
    dateTooltip: true,
  })
  last_login: string;

  @Column({
    order: 3,
    name: 'Verification Code',
  })
  verification_code: string;

  @Column({
    order: 4,
    isActionList: true,
  })
  actionList: ActionListItem[];
}
