import { EventEmitter, Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class FilterService {
  public applyFilterEmitter: EventEmitter<string>;
  public clearFilterButtonEmitter: EventEmitter<string>;
  public clearSelectedDateRangeEmitter: EventEmitter<string>;
  public clearSelectedSelectValuesEmitter: EventEmitter<string>;
  public clearSelectedSearchValueEmitter: EventEmitter<string>;
  constructor() {
    this.applyFilterEmitter = new EventEmitter<string>();
    this.clearFilterButtonEmitter = new EventEmitter<string>();
    this.clearSelectedDateRangeEmitter = new EventEmitter<string>();
    this.clearSelectedSelectValuesEmitter = new EventEmitter<string>();
    this.clearSelectedSearchValueEmitter = new EventEmitter<string>();
  }
  applyFilter(filterName: string): void {
    this.applyFilterEmitter.emit(filterName);
  }
  clearFilterButton(name: string): void {
    this.clearFilterButtonEmitter.emit(name);
  }
  clearSelectedDateRange(filterName: string): void {
    this.clearSelectedDateRangeEmitter.emit(filterName);
  }
  clearSelectedSelectValues(filterName: string): void {
    this.clearSelectedSelectValuesEmitter.emit(filterName);
  }
  clearSearchValue(filterName: string): void {
    this.clearSelectedSearchValueEmitter.emit(filterName);
  }
}
