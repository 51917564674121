import { Injectable } from '@angular/core';
import { map, Observable, ReplaySubject, shareReplay, Subject, tap } from 'rxjs';
import {
  CancelOutboundTransferRequestParams,
  CreateOutboundTransferRequestParams,
  GetOutboundTransferRequestParams,
  InternalOutboundTransfer,
  InternalService,
  ListOutboundTransfersRequestParams,
  OutboundTransfersService,
} from '../../../../projects/tilled-api-client/src';
import { TilledAlert } from '../models/tilled-alert';
import { MinorUnitsToCurrencyPipe } from '../pipes/minor-units-to-currency.pipe';
import { AlertService } from './alert.service';

@Injectable({
  providedIn: 'root',
})
export class OutboundTransfersAppService {
  private _outboundTransferSubject$ = new Subject<InternalOutboundTransfer>();
  private _outboundTransfers$ = new ReplaySubject<InternalOutboundTransfer[]>();
  private _outboundTransfersCount$ = new ReplaySubject<number>();
  private _outboundTransfersHideOT$ = new ReplaySubject<InternalOutboundTransfer[]>();
  //TODO: should everything be Subject vs ReplaySubject/Behavior?
  private _outboundTransferCanceled$ = new Subject<boolean>();
  private _outboundTransferCreated$ = new Subject<boolean>();
  private _cancelError$ = new Subject<string>();
  private _createError$ = new Subject<string>();

  public outboundTransferCanceled$ = this._outboundTransferCanceled$.asObservable();
  public cancelError$ = this._cancelError$.asObservable();
  public createError$ = this._createError$.asObservable();
  public outboundTransferCreated$ = this._outboundTransferCreated$.asObservable();

  public outboundTransfer$: Observable<InternalOutboundTransfer> = this._outboundTransferSubject$.asObservable();
  public outboundTransfers$: Observable<InternalOutboundTransfer[]> = this._outboundTransfers$.asObservable();
  public outboundTransfersCount$: Observable<number> = this._outboundTransfersCount$.asObservable();
  public outboundTransfersHideOT$: Observable<InternalOutboundTransfer[]> =
    this._outboundTransfersHideOT$.asObservable();

  constructor(
    private _outboundTransfersService: OutboundTransfersService,
    private _internalService: InternalService,
    private _alertService: AlertService,
  ) {}

  public getOutboundTransfer(params: GetOutboundTransferRequestParams): void {
    this._internalService.internalGetOutboundTransfer(params).subscribe({
      next: (outboundTransfer) => {
        this._outboundTransferSubject$.next(outboundTransfer);
      },
      error: (err) => {
        // generic catch all for error responses
        const message: TilledAlert = {
          message: "Could not load outbound transfer '" + params.id + "'",
          title: 'Server error',
          type: 'error',
        };
        this._alertService.showAlert(message);
      },
    });
  }

  public getAllOutboundTransfers(params: ListOutboundTransfersRequestParams): void {
    this._internalService
      .internalListOutboundTransfers(params)
      .pipe(
        tap((result) => this._outboundTransfersCount$.next(result.total)),
        map((result) => result.items),
        shareReplay(1),
      )
      .subscribe({
        next: (outboundTransfers) => {
          this._outboundTransfers$.next(outboundTransfers);
        },
        error: (err) => {
          // generic catch all for error responses
          const message: TilledAlert = {
            message: err.error?.message ? err.error.message : 'Could not load all outbound transfers',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
        },
      });
  }

  public getAllOutboundTransfersHideOT(params: ListOutboundTransfersRequestParams): void {
    this._internalService
      .internalListOutboundTransfers(params)
      .pipe(
        map((result) => result.items),
        shareReplay(1),
      )
      .subscribe({
        next: (outboundTransfers) => {
          this._outboundTransfersHideOT$.next(outboundTransfers);
        },
        error: (err) => {
          // generic catch all for error responses
          const message: TilledAlert = {
            message: err.error?.message ? err.error.message : 'Could not load all outbound transfers',
            title: 'Server error',
            type: 'error',
          };
          this._alertService.showAlert(message);
        },
      });
  }

  public cancelOutboundTransfer(params: CancelOutboundTransferRequestParams): void {
    this._internalService.internalCancelOutboundTransfer(params).subscribe({
      next: (canceled) => {
        if (canceled.status !== InternalOutboundTransfer.StatusEnum.CANCELED) {
          this._cancelError$.next(
            'The outbound transfer could not be canceled. Probably because it is has already been batched or paid out.',
          );
        } else {
          const currencyPipe = new MinorUnitsToCurrencyPipe();
          const message: TilledAlert = {
            message: `Outbound transfer '${canceled.id}' for ${currencyPipe.transform(
              canceled.amount,
            )} was canceled successfully`,
            title: 'Cancellation successful',
            type: 'success',
            timer: 8000,
          };
          this._alertService.showAlert(message);

          this._outboundTransferCanceled$.next(true);
          this._outboundTransferSubject$.next(canceled);
        }
      },
      error: (err) => {
        this._cancelError$.next(err.error.message);
      },
    });
  }

  public createOutboundTransfer(params: CreateOutboundTransferRequestParams): void {
    this._internalService.internalCreateOutboundTransfer(params).subscribe({
      next: (created) => {
        if (created.status === InternalOutboundTransfer.StatusEnum.FAILED) {
          this._createError$.next(created.failure_message);
        } else {
          const currencyPipe = new MinorUnitsToCurrencyPipe();
          const message: TilledAlert = {
            message: `Outbound transfer '${created.id}' for ${currencyPipe.transform(
              created.amount,
            )} was sent successfully`,
            title: 'Outbound transfer successful',
            type: 'success',
            timer: 8000,
          };
          this._alertService.showAlert(message);

          this._outboundTransferCreated$.next(true);
        }
      },
      error: (err) => {
        this._createError$.next(err.error.message);
      },
    });
  }
}
