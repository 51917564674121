import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AUTH_LOGIN_ROUTE } from 'app/core/constants';
import { AuthService } from '../../services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizeGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // TODO: figure out how to handle this in routing
    if (state.url.includes('enroll/expired')) {
      return true;
    }

    if (this.authService.user || !AuthService.isTokenExpired() || !AuthService.isRefreshTokenExpired()) {
      return true;
    } else {
      // No one logged in or token is expired
      // Redirect to login
      // this.router.createUrlTree???
      this.router.navigate(['/' + AUTH_LOGIN_ROUTE], {
        queryParams: {
          redirectTo: state.url,
        },
      });
      return false;
    }
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    // TODO: figure out how to handle this in routing
    if (state.url.includes('enroll/expired')) {
      return true;
    }
    if (this.authService.user || !AuthService.isTokenExpired() || !AuthService.isRefreshTokenExpired()) {
      return true;
    } else {
      // No one logged in or token is expired
      // Redirect to login
      // this.router.createUrlTree???
      this.router.navigate(['/' + AUTH_LOGIN_ROUTE], {
        queryParams: {
          redirectTo: state.url,
        },
      });
      return false;
    }
  }
}
