import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { AsyncPipe, CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { ActivatedRoute } from '@angular/router';
import { FuseConfirmationDialogComponent } from '@fuse/services/confirmation/dialog/dialog.component';
import { ComponentBase } from 'app/core/componentBase';
import { TilledAlert } from 'app/core/models/tilled-alert';
import { TooltipListPipe } from 'app/core/pipes/tooltip-list.pipe';
import { AlertService } from 'app/core/services/alert.service';
import { AuthService } from 'app/core/services/auth.service';
import { BrandingService } from 'app/core/services/branding.service';
import { DocumentsAppService } from 'app/core/services/documents.app.service';
import { FilesAppService } from 'app/core/services/files.app.service';
import { MerchantAppService } from 'app/core/services/merchant-app.service';
import { PlaidEventsConfig } from 'app/core/services/plaid-link.app.service';
import { _compareTwoStrings } from 'app/shared/utils/compare-two-strings';
import { isRoutingNumber } from 'app/shared/validators/routing-number.validator';
import { cloneDeep } from 'lodash';
import { Observable, Subject, Subscription, combineLatest, distinctUntilChanged, takeUntil } from 'rxjs';
import {
  BLEBankAccount,
  BankVerification,
  DocumentDto,
  InternalAccount,
  ModelFile,
  OnboardingApplication,
  PricingTemplate,
} from '../../../../../projects/tilled-api-client/src';
import { FuseAlertComponent } from '../../../../@fuse/components/alert/alert.component';
import { MerchantAppCardComponent } from '../../cards/merchant-application/merchant-app-card/merchant-app-card.component';
import { FileUploadComponent } from '../../file-upload/file-upload.component';
import { TilledInputComponent } from '../../form-fields/tilled-input/tilled-input.component';
import { LogoLoadedDirective } from '../../logo-loaded.directive';
import { TilledLabelL1Component } from '../../tilled-text/tilled-label/tilled-label-l1.component';
import { TilledParagraphP2Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p2.component';
import { TilledParagraphP3Component } from '../../tilled-text/tilled-paragraph/tilled-paragraph-p3.component';

@Component({
  selector: 'bank-account-step',
  templateUrl: './bank-account-step.component.html',
  styleUrls: ['./bank-account-step.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MerchantAppCardComponent,
    FormsModule,
    ReactiveFormsModule,
    FuseAlertComponent,
    MatIconModule,
    TilledParagraphP3Component,
    MatProgressSpinnerModule,
    MatButtonModule,
    LogoLoadedDirective,
    TilledParagraphP2Component,
    TilledLabelL1Component,
    TilledInputComponent,
    FileUploadComponent,
    CommonModule,
    AsyncPipe,
    MatRadioModule,
  ],
})
export class BankAccountStepComponent extends ComponentBase implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @Input() forConsole: boolean = false;
  @Input() canSubmitApp: boolean = false;
  @Input() disabled$: Observable<boolean> = null;
  @Input() saveApp$: Observable<string> = null;
  @Input() checkUnsavedApp$: Observable<string> = null;
  @Input() resetApp$: Observable<boolean> = null;
  @Input() stepNumber: number;
  @Input() merchantAccount: InternalAccount = null;
  @Output() markAppUnsaved: EventEmitter<boolean> = new EventEmitter<boolean>();

  public bankAccountForm: FormGroup;
  public hiddenForm: FormGroup;
  public merchantApp: OnboardingApplication;
  public routingNumberLabel: string = 'Routing Number';
  public showBankAccountForm = false;
  public bankAccountFormDisabled = false;
  public isLoading = false;
  public falconProcessing = false;
  public showManualUpload = false;
  public verifiedByPlaid = false;

  public fileTypes = [ModelFile.TypeEnum.PDF, ModelFile.TypeEnum.PNG, ModelFile.TypeEnum.JPG, ModelFile.TypeEnum.TIFF];
  public fileDescriptions = ['Voided Check', 'Bank Letter'];
  public filePurpose = ModelFile.PurposeEnum.ONBOARDING_DOCUMENTATION;
  private subscriptions: Subscription[] = [];
  private pendingFiles: number = 0;

  private allFiles$: Observable<ModelFile[]>;
  private _existingFiles$ = new Subject<ModelFile[]>();
  public existingFiles$ = this._existingFiles$.asObservable();
  private allDocuments$: Observable<DocumentDto[]>;
  public bankLetterTooltipText = '';
  private subscription: Subscription;

  public hasBankVerificationEnabled: boolean = false;
  private bankVerificationMethods: BankVerification.VerificationMethodEnum[] = [];
  public hasPlaid: boolean = false;
  public fileAccountId: string = null;

  public isWhiteLabel$: Observable<boolean>;
  public windowWidth: any;
  public tilledInputWidth: any;

  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _formBuilder: FormBuilder,
    private _merchantAppService: MerchantAppService,
    private _authService: AuthService,
    private _filesAppService: FilesAppService,
    private _alertService: AlertService,
    private _tooltipListPipe: TooltipListPipe,
    private _matDialog: MatDialog,
    private _brandingService: BrandingService,
    private _route: ActivatedRoute,
    private _documentsService: DocumentsAppService,
  ) {
    super();
    // used for hidden element to define button width
    this.hiddenForm = this._formBuilder.group({
      hidden: new FormControl<boolean>(true),
    });
    this.bankAccountForm = this._formBuilder.group({
      accountType: new FormControl<string | null>(null),
      accountHolder: new FormControl<string | null>(null),
      bankName: new FormControl<string | null>(null),
      accountNumber: new FormControl<string | null>(null),
      confirmAccountNumber: new FormControl<string | null>(null, [confirmAccountNumberValidator]),
      routingNumber: new FormControl<string | null>(null, [isRoutingNumber()]),
    });
    this.isWhiteLabel$ = this._brandingService.isWhiteLabel$;
  }

  @HostListener('document:mousemove', ['$event'])
  @HostListener('window:resize', ['$event'])
  public onResize(event: any): void {
    this.windowWidth = window.innerWidth;
    this.tilledInputWidth = this.getElementWidth('tilled-input mat-form-field');
  }

  // If saas account show plaid
  // If partner show manual upload only.

  ngOnInit(): void {
    this.windowWidth = window.innerWidth;
    this.tilledInputWidth = this.getElementWidth('tilled-input mat-form-field');
    this._authService.account$.pipe(takeUntil(this._unsubscribeAll)).subscribe((account: InternalAccount) => {
      this.fileAccountId = this.merchantAccount?.id ?? account.id;
      this._filesAppService.listAllFiles(this.fileAccountId, [ModelFile.PurposeEnum.ONBOARDING_DOCUMENTATION]);
      this._documentsService.getAllDocuments(this.fileAccountId, null);

      this.bankVerificationMethods = this.merchantAccount
        ? this.merchantAccount?.internal_metadata?.bank_verification_methods
        : account?.internal_metadata?.bank_verification_methods;

      this.hasBankVerificationEnabled =
        this.bankVerificationMethods?.includes(BankVerification.VerificationMethodEnum.PLAID) ||
        this.bankVerificationMethods?.includes(BankVerification.VerificationMethodEnum.TILLED_MANUAL)
          ? true
          : false;

      this.hasPlaid = this.bankVerificationMethods?.includes(BankVerification.VerificationMethodEnum.PLAID);
    });

    const voidedCheckList = this._tooltipListPipe.transform([
      ' Be a pre-printed voided check (not a temporary check)',
      ' Include the merchant legal name',
      ' Include the merchant business address',
    ]);

    const bankLetterList = this._tooltipListPipe.transform([
      ' Be on a bank letterhead',
      ' Include the registered name of your business',
      ' Include an ACH routing number',
      ' Include an ACH account number',
      ' Be signed and dated by a bank representative within the last 6 months',
    ]);

    this.bankLetterTooltipText =
      'Voided Check Guidelines \n The voided check should:\n' +
      voidedCheckList +
      '\n Bank Letter Guidelines \n The bank letter should:\n' +
      bankLetterList;

    this.allFiles$ = this._filesAppService.filesAll$;
    this.allDocuments$ = this._documentsService.documentsAll$;

    this.subscription = combineLatest([
      this.allDocuments$.pipe(distinctUntilChanged()),
      this.allFiles$.pipe(distinctUntilChanged()),
    ]).subscribe({
      next: ([docs, files]) => {
        let documentFileIds = [];
        if (docs && files) {
          documentFileIds = docs?.flatMap((d) => (d.file_ids ? d?.file_ids : []));
          this._existingFiles$.next(
            documentFileIds?.length > 0 ? files.filter((f) => !documentFileIds.includes(f.id)) : files,
          );
        }
      },
      error: (err) => {
        const message: TilledAlert = {
          message: 'There was an error loading existing uploaded files. You can still try to upload more',
          title: 'Error loading uploaded files',
          type: 'error',
        };
        this._alertService.showAlert(message);
      },
    });

    this._merchantAppService.merchantApplicationResponse$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((application) => {
        this.merchantApp = cloneDeep(application);
        this.resetApplication();
        this.bankAccountForm.enable();
        const accNum = application.legal_entity?.bank_account?.account_number;

        if (this.hasBankVerificationEnabled) {
          if ((accNum == null || accNum.trim().length === 0) && this.hasPlaid) {
            this.stateManager('plaid_enabled');
          } else {
            if (
              application.bank_verification?.verification_status === BankVerification.VerificationStatusEnum.VERIFIED
            ) {
              this.stateManager('verified');
            } else {
              // manual upload verification route.
              this.stateManager('manual_started');
            }
          }
        } else if (!this.forConsole && !this.hasBankVerificationEnabled) {
          this.stateManager('legacy');
        }

        if (this.merchantApp.pricing_templates[0]?.currency === PricingTemplate.CurrencyEnum.CAD) {
          this.routingNumberLabel = 'Transit Number and Institution ID';
        }

        if (!this.merchantApp.legal_entity.bank_account) {
          this.merchantApp.legal_entity.bank_account = {
            account_number: '',
            routing_number: '',
          } as BLEBankAccount;
        }

        this.isLoading = false;
      });

    if (this.disabled$) {
      this.subscriptions.push(
        this.disabled$.subscribe((isDisabled) => {
          if (isDisabled) {
            this.bankAccountForm.disable();
          } else {
            this.bankAccountForm.enable();
          }
        }),
      );
    }

    if (this.forConsole) {
      if (!this.hasBankVerificationEnabled) {
        this.showBankAccountForm = true;
      }
      if (this.saveApp$) {
        this.subscriptions.push(
          this.saveApp$.subscribe((save) => {
            if (save) {
              this.onContinueClicked(save);
            }
          }),
        );
      }
      if (this.checkUnsavedApp$) {
        this.subscriptions.push(
          this.checkUnsavedApp$.subscribe((check) => {
            if (check) {
              this.markAppUnsaved.emit(this.isAppUnsaved());
            }
          }),
        );
      }
      if (this.resetApp$) {
        this.subscriptions.push(
          this.resetApp$.subscribe((reset) => {
            if (reset) {
              this.resetApplication();
            }
          }),
        );
      }
    }
  }

  ngAfterViewInit(): void {
    this.scrollToTop();
    document.dispatchEvent(new MouseEvent('mousemove'));
  }

  public deletePlaidVerifiedAccount(): void {
    const dialogRef = this._matDialog.open(FuseConfirmationDialogComponent, {
      data: {
        title: 'Disconnect Plaid Verified Account?',
        message:
          // eslint-disable-next-line max-len
          'Your privacy is important to us! Rest assured that this account will be promptly and permanently deleted from our systems. Proceeding with this could result in a longer application process.',
        icon: {
          show: false,
          name: 'heroicons_outline:exclamation',
          color: 'warn',
        },
        actions: {
          confirm: {
            show: true,
            label: 'Disconnect',
            color: 'warn',
          },
          cancel: {
            show: true,
            label: 'Cancel',
          },
        },
        dismissible: false,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((result) => {
        if (result && result === 'confirmed') {
          this.merchantApp.bank_verification = {};
          this.merchantApp.legal_entity.bank_account = {} as BLEBankAccount;
          this.merchantApp.legal_entity.bank_account.account_holder_name = null;
          this.merchantApp.legal_entity.bank_account.account_number = null;
          this.merchantApp.legal_entity.bank_account.type = null;
          this.merchantApp.legal_entity.bank_account.routing_number = null;
          this.merchantApp.legal_entity.bank_account.bank_name = null;
          this._merchantAppService.updateMerchantApplication(
            this.merchantApp,
            this.stepNumber,
            this.forConsole
              ? this.merchantAccount?.id || this._route.snapshot.params.id
              : AuthService.getCurrentAccountId(),
          );
          this.showManualVerification();
        } else {
          return;
        }
      });
  }

  public fileDeleted(event: string): void {}

  public pendingFilesChange(numOfFiles: number): void {
    this.pendingFiles = numOfFiles;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  public onContinueClicked(accountId?: string): void {
    this.bankAccountForm.markAllAsTouched();
    if (this.bankAccountForm.invalid) {
      setTimeout(() => {
        this.scrollToError();
      }, 0);
      return;
    }
    // ngx-mask sets certain empty values (phone numbers at least) to empty string, where api expects null
    // eslint-disable-next-line guard-for-in
    for (const field in this.bankAccountForm.controls) {
      const control = this.bankAccountForm.get(field);
      if (control.value === '') {
        control.setValue(null);
      }
    }

    this.merchantApp.legal_entity.bank_account.account_number = this.bankAccountForm.value.accountNumber?.trim() ?? '';
    this.merchantApp.legal_entity.bank_account.routing_number = this.bankAccountForm.value.routingNumber ?? '';
    this.merchantApp.legal_entity.bank_account.type = this.bankAccountForm.value.accountType as BLEBankAccount.TypeEnum;
    this.merchantApp.legal_entity.bank_account.account_holder_name = this.bankAccountForm.value.accountHolder;
    this.merchantApp.legal_entity.bank_account.bank_name = this.bankAccountForm.value.bankName;

    if (this.pendingFiles > 0) {
      const message: TilledAlert = {
        message: `You have ${this.pendingFiles} files that have not been uploaded. Either upload or remove them from the list`,
        title: 'Pending files not uploaded',
        type: 'warning',
        timer: 5000,
      };
      this._alertService.showAlert(message);
    }

    this._merchantAppService.updateMerchantApplication(
      this.merchantApp,
      !this.forConsole || this.canSubmitApp ? this.stepNumber + 1 : this.stepNumber,
      accountId,
    );
  }

  public showManualVerification(): void {
    this.showBankAccountForm = true;
    this.showManualUpload = true;
    this._changeDetectorRef.markForCheck();
  }

  public connectWithPlaidInstead(): void {
    this.showManualUpload = false;
    this.getLinkToken();
  }

  public getLinkToken(): void {
    // Prevents 'No application found' error from `plaidUpdateMerchantApplication` if application has never been updated before.
    const accountId = this.forConsole ? this._route.snapshot.params.id : AuthService.getCurrentAccountId();
    this._merchantAppService.applicationUpdatedAt$.subscribe((updated) => {
      if (!updated) {
        this._merchantAppService.updateMerchantApplication(this.merchantApp, this.stepNumber, accountId);
      }
    });

    this.isLoading = true;
    const eventListeners: PlaidEventsConfig = {
      onSuccess: (token, metadata) => this.plaidOnSuccess(token, metadata),
      onExit: (error, metadata) => this.plaidOnExit(error, metadata),
    };
    this._merchantAppService.getLinkToken(eventListeners, window.location.href);
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.mat-form-field-invalid');
    this.scrollTo(firstElementWithError);
  }

  private plaidOnSuccess(token, metadata): void {
    const accountId = this.forConsole
      ? this.merchantAccount?.id || this._route.snapshot.params.id
      : AuthService.getCurrentAccountId();

    try {
      this._merchantAppService.updatePlaidAccessToken(token, accountId);
      this.falconProcessing = true;
      this._changeDetectorRef.markForCheck();
    } catch (err) {
      this.isLoading = false;
      this.showBankAccountForm = true;
    }
  }

  private plaidOnExit(error, metadata): void {
    // Fires on premature exit from Plaid
    this.isLoading = false;
    this.showManualUpload = true;
    this.showBankAccountForm = true;
    this._changeDetectorRef.markForCheck();
  }

  private isAppUnsaved(): boolean {
    // eslint-disable-next-line guard-for-in
    for (const field in this.bankAccountForm.controls) {
      const control = this.bankAccountForm.get(field);
      if (control.value === '') {
        control.setValue(null);
      }
    }
    if (this.bankAccountForm.value.routingNumber === null) {
      this.bankAccountForm.controls['routingNumber'].setValue('');
    }
    // ignore account number, as it is masked
    return !(
      _compareTwoStrings(
        this.merchantApp.legal_entity?.bank_account?.routing_number,
        this.bankAccountForm.value.routingNumber,
      ) &&
      _compareTwoStrings(this.merchantApp.legal_entity?.bank_account?.type, this.bankAccountForm.value.accountType) &&
      _compareTwoStrings(
        this.merchantApp.legal_entity?.bank_account?.account_holder_name,
        this.bankAccountForm.value.accountHolder,
      ) &&
      _compareTwoStrings(this.merchantApp.legal_entity?.bank_account?.bank_name, this.bankAccountForm.value.bankName)
    );
  }

  private resetApplication(): void {
    const ble = this.merchantApp?.legal_entity;

    this.bankAccountForm.controls['accountNumber'].setValue(ble?.bank_account?.account_number?.trim());
    this.bankAccountForm.controls['routingNumber'].setValue(ble?.bank_account?.routing_number);
    this.bankAccountForm.controls['accountType'].setValue(ble?.bank_account?.type);
    this.bankAccountForm.controls['accountHolder'].setValue(ble?.bank_account?.account_holder_name);
    this.bankAccountForm.controls['bankName'].setValue(ble?.bank_account?.bank_name);
  }

  private stateManager(state: string): void {
    switch (state) {
      case 'legacy': {
        this.showBankAccountForm = true;
        this.verifiedByPlaid = false;
        this.showManualUpload = true;
        break;
      }
      case 'verified': {
        this.showBankAccountForm = true;
        this.verifiedByPlaid = true;
        this.showManualUpload = false;
        this.bankAccountForm.disable();
        break;
      }
      case 'plaid_enabled': {
        this.showBankAccountForm = false;
        this.showManualUpload = false;
        this.verifiedByPlaid = false;
        break;
      }
      case 'manual_started': {
        this.showBankAccountForm = true;
        this.verifiedByPlaid = false;
        this.showManualUpload = true;
        break;
      }
      default:
        break;
    }
    this._changeDetectorRef.markForCheck();
  }

  public requiredFilesUploaded(event: string[]): void {
    this._merchantAppService.updateBankDocsRequiredFromComponent(this.showManualUpload, event);
  }

  public getElementWidth(selector: string): string {
    let element = document?.querySelector(selector) || document?.querySelector('tilled-select mat-form-field');
    if (!element) {
      return '100%';
    }
    return element.clientWidth + 'px';
  }

  scrollToTop(): void {
    const element = document.querySelector('.top-of-form');
    if (element) {
      element.scrollIntoView({ behavior: 'auto', block: 'end' });
    }
  }
}

// Validator for confirm account number
export const confirmAccountNumberValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const accountNumber = control.parent?.get('accountNumber');
  const confirmAccountNumber = control.parent?.get('confirmAccountNumber');

  if (!accountNumber || !confirmAccountNumber || !confirmAccountNumber.value) {
    return null;
  }

  return accountNumber.value === confirmAccountNumber.value ? null : { accountNumberMismatch: true };
};
