import { OnboardingLegalEntity } from '../../../../../projects/tilled-api-client/src/model/onboardingLegalEntity';

export class RepresentativeRequirements {
  public requirements = [
    {
      type: OnboardingLegalEntity.StructureEnum.CHARITY,
      desc: 'Charity',
      usReq: [
        'Control Prong must apply and agree to terms.',
        'This Control Prong must provide their personal details as a business owner.',
      ],
      caReq: [
        'A Director or Trustee must apply and agree to terms.',
        'This Director/Trustee and any additional Director/Trustee must provide their personal details as a business owner.',
      ],
    },
    {
      type: OnboardingLegalEntity.StructureEnum.NON_PROFIT,
      desc: 'Non-Profit',
      usReq: [
        'Control Prong must apply and agree to terms.',
        'This Control Prong must provide their personal details as a business owner.',
      ],
      caReq: [
        'A Director or Trustee must apply and agree to terms.',
        'This Director/Trustee and any additional Director/Trustee must provide their personal details as a business owner.',
      ],
    },
    {
      type: OnboardingLegalEntity.StructureEnum.TRUST,
      desc: 'Trust',
      usReq: [
        'Control Prong must apply and agree to terms.',
        'This Control Prong must provide their personal details as a business owner.',
      ],
      caReq: [
        'A Director or Trustee must apply and agree to terms.',
        'This Director/Trustee and any additional Director/Trustee must provide their personal details as a business owner.',
      ],
    },
    {
      type: OnboardingLegalEntity.StructureEnum.COMMUNITY_INTEREST_COMPANY,
      desc: 'Community Interest Company (CIC)',
      usReq: ['Control Prong must apply and agree to terms.', 'This Control Prong must provide personal details.'],
      caReq: [
        'A Director must apply and agree to terms.',
        'This Director and any additional Director must provide personal details.',
      ],
    },
    {
      type: OnboardingLegalEntity.StructureEnum.CORPORATION,
      desc: 'Corporation',
      usReq: [
        'A Control Prong or one of the owners must apply and agree to terms.',
        'This Control Prong and each shareholder owning 25% or more shares must provide personal details.',
      ],
      caReq: [
        'A Director must apply and agree to terms.',
        'This Director, any additional Director, and each shareholder owning 25% or more shares (to a maximum of 4) must provide personal details.',
      ],
    },
    {
      type: OnboardingLegalEntity.StructureEnum.LIMITED,
      desc: 'Limited (LTD)',
      usReq: [
        'A Control Prong or one of the owners must apply and agree to terms.',
        'This Control Prong and each shareholder owning 25% or more shares must provide personal details.',
      ],
      caReq: [
        'A Director must apply and agree to terms.',
        'This Director, any additional Director, and each shareholder owning 25% or more shares (to a maximum of 4) must provide personal details.',
      ],
    },
    {
      type: OnboardingLegalEntity.StructureEnum.LIMITED_LIABILITY_COMPANY,
      desc: 'Limited Liability Company (LLC)',
      usReq: [
        'A Control Prong or one of the owners must apply and agree to terms.',
        'This Control Prong and each shareholder owning 25% or more shares must provide personal details.',
      ],
      caReq: [
        'A Director must apply and agree to terms.',
        'This Director, any additional Director, and each shareholder owning 25% or more shares (to a maximum of 4) must provide personal details.',
      ],
    },
    {
      type: OnboardingLegalEntity.StructureEnum.LIMITED_LIABILITY_PARTNERSHIP,
      desc: 'Limited Liability Partnership (LLP)',
      usReq: [
        'A Control Prong or one of the owners must apply and agree to terms.',
        'This Control Prong and each partner owning 25% or more shares must provide personal details.',
      ],
      caReq: [
        'A Director must apply and agree to terms.',
        'This Director and each partner owning 25% or more shares must provide personal details.',
      ],
    },
    {
      type: OnboardingLegalEntity.StructureEnum.LIMITED_LIABILITY_COMPANY,
      desc: 'Limited Liability Partnership (LLP)',
      usReq: [
        'A Partner/Control Prong must apply and agree to terms.',
        'This Control Prong and all partners owning 25% or more shares must provide personal details.',
      ],
      caReq: [
        'A Partner must apply and agree to terms.',
        'This Partner and any additional Partners must provide personal details.',
      ],
    },
    {
      type: OnboardingLegalEntity.StructureEnum.PARTNERSHIP,
      desc: 'Partnership',
      usReq: [
        'A Partner/Control Prong must apply and agree to terms.',
        'This Control Prong and all partners owning 25% or more shares must provide personal details.',
      ],
      caReq: [
        'A Partner must apply and agree to terms.',
        'This Partner and any additional Partners must provide personal details.',
      ],
    },
    {
      type: OnboardingLegalEntity.StructureEnum.PUBLIC_LIMITED_COMPANY,
      desc: 'Public Limited Company (PLC)',
      usReq: ['A Control Prong must apply and agree to terms.', 'This Control Prong must provide personal details.'],
      caReq: ['A Director must apply and agree to terms.', 'This Director must provide personal details.'],
    },
    {
      type: OnboardingLegalEntity.StructureEnum.GOVERNMENT,
      desc: 'Public Sector/Governmental',
      usReq: ['A Control Prong must apply and agree to terms.', 'This Control Prong must provide personal details.'],
      caReq: ['A Director must apply and agree to terms.', 'This Director must provide personal details.'],
    },
    {
      type: OnboardingLegalEntity.StructureEnum.SOLE_PROPRIETORSHIP,
      desc: 'Sole Proprietorship/Sole Trader',
      usReq: ['The Sole Proprietorship/Sole Trader only must apply, agree to terms, and provide personal details.'],
      caReq: ['The Sole Proprietorship/Sole Trader only must apply, agree to terms, and provide personal details.'],
    },
  ];
}
