export const environment = {
  env: 'prod',
  api: 'https://api.tilled.com',
  appUrl: 'app.tilled.com',
  whiteLabelApi: 'https://api.paymentsonline.io',
  whiteLabelAppHost: 'paymentsonline.io',
  sentryDSN: 'https://41ee08ccbfb64c0386f90376b569ab93@o377738.ingest.sentry.io/6582075',
  production: true,
  hmr: false,
  whiteLabelDomain: '.paymentsonline.io',
  tracesSampleRate: 1.0,
  replaysSamplingRate: 0,
  replaysOnErrorSamplingRate: 1.0,
};
