import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ApplicationStep } from 'app/core/models/application-step';
import { BrandingService } from 'app/core/services/branding.service';
import { FuseCardComponent } from '../../../../@fuse/components/card/card.component';

@Component({
  selector: 'isv-app-summary-card',
  templateUrl: './app-summary-card.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [NgClass, MatIconModule, FuseCardComponent],
})
export class PartnerApplicationSummaryCardComponent implements OnInit {
  @Input() appStep?: ApplicationStep;
  @Output() cardClick: EventEmitter<number> = new EventEmitter<number>();

  public useDarkModeSecondaryColor: boolean = false;

  constructor(private _brandingService: BrandingService) {
    this._brandingService.secondaryDarkColor$.subscribe((color) => {
      if (color) {
        this.useDarkModeSecondaryColor = true;
      }
    });
  }

  ngOnInit() {}

  onButtonClick() {
    this.cardClick.emit(this.appStep.order);
  }
}
