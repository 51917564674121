/**
 * Tilled Internal API
 * Tilled Internal API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: integrations@tilled.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PAArticlesOfIncorporation { 
    /**
     * The Document ID of an uploaded [Document](#tag/Documents) of type `file` and subtype `articles_of_incorp`
     */
    document_id?: string;
    /**
     * The date on which the business Articles of Incorporation were issued.
     */
    issued_at: string;
    /**
     * The state where the business Articles of Incorporation were issued.
     */
    state: PAArticlesOfIncorporation.StateEnum;
}
export namespace PAArticlesOfIncorporation {
    export type StateEnum = 'AL' | 'AK' | 'AS' | 'AZ' | 'AR' | 'AA' | 'AE' | 'AP' | 'CA' | 'CO' | 'CT' | 'DE' | 'DC' | 'FL' | 'GA' | 'GU' | 'HI' | 'ID' | 'IL' | 'IN' | 'IT' | 'IA' | 'KS' | 'KY' | 'LA' | 'ME' | 'MD' | 'MA' | 'MI' | 'MN' | 'MS' | 'MO' | 'MT' | 'NE' | 'NV' | 'NH' | 'NJ' | 'NM' | 'NY' | 'NC' | 'ND' | 'MP' | 'OH' | 'OK' | 'OR' | 'PW' | 'PA' | 'PR' | 'RI' | 'SC' | 'SD' | 'TN' | 'TX' | 'VI' | 'US' | 'UT' | 'VT' | 'VA' | 'WA' | 'WV' | 'WI' | 'WY';
    export const StateEnum = {
        AL: 'AL' as StateEnum,
        AK: 'AK' as StateEnum,
        AS: 'AS' as StateEnum,
        AZ: 'AZ' as StateEnum,
        AR: 'AR' as StateEnum,
        AA: 'AA' as StateEnum,
        AE: 'AE' as StateEnum,
        AP: 'AP' as StateEnum,
        CA: 'CA' as StateEnum,
        CO: 'CO' as StateEnum,
        CT: 'CT' as StateEnum,
        DE: 'DE' as StateEnum,
        DC: 'DC' as StateEnum,
        FL: 'FL' as StateEnum,
        GA: 'GA' as StateEnum,
        GU: 'GU' as StateEnum,
        HI: 'HI' as StateEnum,
        ID: 'ID' as StateEnum,
        IL: 'IL' as StateEnum,
        IN: 'IN' as StateEnum,
        IT: 'IT' as StateEnum,
        IA: 'IA' as StateEnum,
        KS: 'KS' as StateEnum,
        KY: 'KY' as StateEnum,
        LA: 'LA' as StateEnum,
        ME: 'ME' as StateEnum,
        MD: 'MD' as StateEnum,
        MA: 'MA' as StateEnum,
        MI: 'MI' as StateEnum,
        MN: 'MN' as StateEnum,
        MS: 'MS' as StateEnum,
        MO: 'MO' as StateEnum,
        MT: 'MT' as StateEnum,
        NE: 'NE' as StateEnum,
        NV: 'NV' as StateEnum,
        NH: 'NH' as StateEnum,
        NJ: 'NJ' as StateEnum,
        NM: 'NM' as StateEnum,
        NY: 'NY' as StateEnum,
        NC: 'NC' as StateEnum,
        ND: 'ND' as StateEnum,
        MP: 'MP' as StateEnum,
        OH: 'OH' as StateEnum,
        OK: 'OK' as StateEnum,
        OR: 'OR' as StateEnum,
        PW: 'PW' as StateEnum,
        PA: 'PA' as StateEnum,
        PR: 'PR' as StateEnum,
        RI: 'RI' as StateEnum,
        SC: 'SC' as StateEnum,
        SD: 'SD' as StateEnum,
        TN: 'TN' as StateEnum,
        TX: 'TX' as StateEnum,
        VI: 'VI' as StateEnum,
        US: 'US' as StateEnum,
        UT: 'UT' as StateEnum,
        VT: 'VT' as StateEnum,
        VA: 'VA' as StateEnum,
        WA: 'WA' as StateEnum,
        WV: 'WV' as StateEnum,
        WI: 'WI' as StateEnum,
        WY: 'WY' as StateEnum
    };
}


